import {useState, useEffect} from 'react';
import Sidebar from '../../components/AdminDashboard/Sidebar';
import TopBar from '../../components/AdminDashboard/TopBar';
import MetaData from '../../layouts/MetaData';
import Loader from '../../layouts/Loader';
import { useDispatch, useSelector} from 'react-redux';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { 
    getInactivePatients,
    patientActivate 
} from '../../actions/adminActions';
import { Link, useHistory } from 'react-router-dom';
import { useAlert } from 'react-alert';
import {Badge, Table, Modal } from 'react-bootstrap';
import moment from 'moment';
import {UPDATE_PATIENT_RESET} from '../../constants/adminConstants';



const InactivePatients = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const [smShow, setSmShow] = useState(false); //small confirm modal
    const [patientModel, setPatientModel] = useState(null);
    const [patientToDelete, setPatientToDelete] = useState(null);
    const alert = useAlert();
    const { loading, error, inactivePts} = useSelector(state => state.inactivePatients);
    const { isUpdated} = useSelector(state => state.admin);

    const [sort, setSort] = useState('ascending');
    const [query, setQuery] = useState("");
    const keys = ["firstname", "lastname", "email", "npinumber", "phone1"];



    useEffect(() => {
        if(error){
            return alert.error(error);
        }  
        
        if(isUpdated){
            alert.success('Status Changed');
            dispatch({type: UPDATE_PATIENT_RESET});
            dispatch(getInactivePatients());
            setSmShow(false);
        }

        dispatch(getInactivePatients());    
    
    }, [dispatch, alert, error, isUpdated]);
    

    const refreshHandler = () => {
        dispatch(getInactivePatients());
    }

    const activatePatient = () => {
        dispatch(patientActivate(patientModel));
    }

    
  return (
    <>
                <MetaData title="Deactivated Patients"/>
                <Sidebar />    

                <section className="home-section">
                {/* TopBar */}
                <TopBar />

                {loading ? <Loader /> : (
                <>   
                {/*  patients List Filter Section */}
                <div className="shadow-lg p-3 mb-5 mr-4 ml-4 rounded-card" style={{backgroundColor: '#FAFAFA'}}>
                    <div className="home-content">
                        
                        <div className="row-display">
                            <div className="col-md-7">
                                <h5 className='title' style={{color: 'red'}}>
                                    <i className="bx bx-user"></i> <strong>&nbsp;Deactivated Patients </strong>
                                </h5>
                            </div>

                           <div className="col-md-5 row-display"> 
                            <button className="edit-pill" onClick={() => history.goBack()}>
                                <i className='bx bxs-user'></i> &nbsp;Active Patients
                            </button>

                            
                            <div className="row-display">    
                                <input type="text" 
                                    placeholder="Search patient..." 
                                    className="form-control" 
                                    style={{width: '200px'}}
                                    onChange={e => setQuery(e.target.value)}
                                />
                                &nbsp;&nbsp;
                                <button className="btn btn-secondary" onClick={refreshHandler}><i className='bx bx-refresh'></i></button> &nbsp;
                            </div>
                                
                            </div>                
                        </div>
                    </div>  
                <br />


                    {/* Patient List Card */}
                        <div className="col-md-12">
                        {inactivePts && <>
                            <Table hover striped bordered>
                            <thead align="center">
                                <tr>
                                <th>Name</th>
                                <th>DOB </th>
                                <th>Acc Status</th>
                                <th>Consent Role (s)</th>
                                <th>ACTION</th> 
                                </tr>
                            </thead>
                            <tbody>
                            
                            {inactivePts && inactivePts.length > 0 ? <> 
                                {inactivePts && inactivePts.filter(item => keys.some(key => item[key]?.toLowerCase().includes(query))).map((patient, index) => (
                                <tr key={index}>  
                                <td>
                                    <Link style={{textDecoration: 'none', color: "dodgerblue", fontWeight: 'bold'}} 
                                        to={{ pathname: "/patientProfile", state: {patientid: patient?._id, batterySignalStatus: patient?.batterySignals?.sig}}}>
                                        {patient?.firstname} {patient?.lastname}
                                    </Link>
                                </td>
                                
                                <td> {moment(patient?.DOB).format("MM/DD/YYYY")} <p><Badge bg="dark text-white">{patient?.gender}</Badge></p></td> 
                                
                                {patient?.block === false ? <td>
                                    <Badge pill bg="success text-white" style={{fontSize: '12px', fontWeight: 300}}>Activated</Badge>
                                    </td> : <td><Badge pill bg="danger text-white" style={{fontSize: '12px'}}>Inactive</Badge>
                                </td>}
                                

                                <td>{patient?.patientType}</td>

                                <td>
                                <DropdownButton id="dropdown-basic-button">
                                    <Dropdown.Item>
                                        <Link className="link" to={{ pathname: "/patientProfile", state: {patientid: patient?._id, batterySignalStatus: patient?.batterySignals?.sig}}}>View Profile</Link>
                                    </Dropdown.Item>

                                    <Dropdown.Item>
                                        <Link className="link" to={{pathname: '/Patients/Edit', state: {patientDetails: patient}}}>Edit Details</Link>
                                    </Dropdown.Item>

                                    <Dropdown.Item> 
                                        <Link className="link" style={{color: 'green'}} to="/Patients/Inactive" onClick={() => {setSmShow(true); setPatientModel(patient?._id); setPatientToDelete(patient?.lastname)}}>Activate Account</Link>
                                    </Dropdown.Item>
                                </DropdownButton>
                                </td>
                            </tr>    
                            ))}
                            </> : <div>
                                <small className="not-found-text">Patients Not Found</small>
                            </div> }
                            
                            </tbody>
                            
                            </Table> 

                                <Modal
                                    size="sm"
                                    show={smShow}
                                    onHide={() => setSmShow(false)}
                                    aria-labelledby="example-modal-sizes-title-sm"
                                >
                                    <Modal.Body>
                                        <small style={{color: 'gray'}}>Are you sure you want to activate Pt.  
                                            <span style={{color: '#000'}}> {patientToDelete}</span> ?
                                        </small>
                                    </Modal.Body>

                                    <Modal.Footer>
                                        <button className="btn btn-sm btn-success" onClick={activatePatient}>Activate</button>
                                    </Modal.Footer>
                                </Modal>  
                        </>}                      
                        </div>
                    </div>
                </>
            )}
            </section>
        </>
    )
}

export default InactivePatients