import React, {useState, useEffect} from 'react';
import { ProgressBar } from 'react-bootstrap';
import { RESET_TIME_REPORT_DATA } from '../../constants/HRConstants';
import { useDispatch, useSelector } from 'react-redux';
import {getTimeReportCCMByPatient} from '../../actions/HRActions';

const CCMMinutesProgress = ({patientId}) => {

    const dispatch = useDispatch();

    
    const [month, setMonth] = useState('09');
    const [year, setYear] = useState('2023');

    const { totalTime } = useSelector(state => state.target);

    useEffect(() =>{
        dispatch({ type: RESET_TIME_REPORT_DATA });

        dispatch(getTimeReportCCMByPatient(patientId, month, year)); 

    }, [])

    const searchHandler = () => {
        dispatch(getTimeReportCCMByPatient(patientId, month, year)); 
    }    


  return (
    <>
    {/* Select box for selecting month  */}
    <div className="row-display">
        <select name="month" 
            className="form-control" 
            placeholder="Select Month"
            value={month}
            onChange={(e) => setMonth(e.target.value)}
            >
            <option value="01">Jan</option>
            <option value="02">Feb</option>
            <option value="03">March</option>
            <option value="04">April</option>
            <option value="05">May</option>
            <option value="06">June</option>
            <option value="07">July</option>
            <option value="08">Aug</option>
            <option value="09">Sept</option>
            <option value="10">Oct</option>
            <option value="11">Nov</option>
            <option value="12">Dec</option>
        </select>
        &nbsp;
        <select 
            name="month" 
            className="form-control" 
            placeholder="Select Year"
            value={year}
            onChange={(e) => setYear(e.target.value)}
            >
            <option value="2022">2022</option>
            <option value="2023">2023</option>
            <option value="2024">2024</option>
            <option value="2025">2025</option>
            <option value="2026">2026</option>
        </select>
        &nbsp;
        <button type="submit" name="submit" className="edit-pill" onClick={() => searchHandler()}>
            <i className='bx bx-search'></i>
        </button>
    </div>
    <br />
    
        {totalTime >= 20 && totalTime < 40 ? <>        
            <small className="row-display text-white" style={{letterSpacing: '2px'}}>
                <span>99490: </span> {totalTime} / 20 mins</small>
                <ProgressBar min="0" max="20" variant='success' label={((totalTime / 20) * 100).toFixed() + "%"} now='20' />
        </>  : null }  

        
        {totalTime >= 40 && totalTime < 60 ? <>
            <br />
            <small className="row-display text-white" style={{letterSpacing: '2px'}}>
                <span>99490 : </span> 20 / 20 mins</small>
                <ProgressBar min="0" max="20" variant='success' label={((totalTime / 20) * 100).toFixed() + "%"} now="20" />
         
        <br />
            <small className="row-display text-white" style={{letterSpacing: '2px'}}>
                <span>99439 : </span> 40 / 40 mins</small>
                <ProgressBar min="20" max="40" variant='success' label={((totalTime / 40) * 100).toFixed() + "%"} now="40" />
        </> : null
        }

        
        {totalTime >= 60 && totalTime < 80 ? <>
            <br />
            <small className="row-display text-white" style={{letterSpacing: '2px'}}>
                <span>99487 : </span> 60 / 60 mins</small>
                <ProgressBar min="40" max="60" variant='success' label={((totalTime / 60) * 100).toFixed() + "%"} now="60" />
        </> : null 
        }

        
        {totalTime >= 80 && totalTime < 90 ? <>
            <br />
            <small className="row-display text-white" style={{letterSpacing: '2px'}}>
                <span>99487 : </span> 80 / 80 mins</small>
                <ProgressBar min="60" max="80" variant='success' label={((80 / 80) * 100).toFixed() + "%"} now="80" />
            <br />
            <small className="row-display text-white" style={{letterSpacing: '2px'}}>
                <span>99439 : </span> {totalTime} / 90 mins</small>
                <ProgressBar min="80" max="90" variant='success' label={((totalTime / 80) * 100).toFixed() + "%"} now={totalTime} />
       
        </> : null 
        }

        
        {totalTime >= 90 && totalTime < 110 ? <>
            <br />
            <small className="row-display text-white" style={{letterSpacing: '2px'}}>
                <span>99489 : </span> 90 / 90 mins</small>
                <ProgressBar min="80" max="90" variant='success' label={((90 / 90) * 100).toFixed() + "%"} now="90" />
            <br />
            <small className="row-display text-white" style={{letterSpacing: '2px'}}>
                <span>99487 : </span> {totalTime} / 110 mins</small>
                <ProgressBar min="90" max="110" variant='success' label={((totalTime / 110) * 100).toFixed() + "%"} now={totalTime} />
       
        </> : null 
        }

        
        {totalTime >= 110 && totalTime < 120 ? <>
            <br />
            <small className="row-display text-white" style={{letterSpacing: '2px'}}>
                <span>99489 : </span> 110 / 110 mins</small>
                <ProgressBar min="90" max="110" variant='success' label={((110 / 110) * 100).toFixed() + "%"} now="110" />
            <br />
            <small className="row-display text-white" style={{letterSpacing: '2px'}}>
                <span>99487 : </span> 110 / 110 mins</small>
                <ProgressBar min="90" max="110" variant='success' label={((110 / 110) * 100).toFixed() + "%"} now="110" />
            <br/>
            <small className="row-display text-white" style={{letterSpacing: '2px'}}>
                <span>99439 : </span> {totalTime} / 120 mins</small>
                <ProgressBar min="110" max="120" variant='success' label={((totalTime / 120) * 100).toFixed() + "%"} now={totalTime} />
        </> : null 
        }

        
        {totalTime >= 120 ? <>
            <br />
            <small className="row-display text-white" style={{letterSpacing: '2px'}}>
                <span>99489 : </span> 120 / 120 mins</small>
                <ProgressBar min="110" max="120" variant='success' label={((120 / 120) * 100).toFixed() + "%"} now="120" />
            <br />
            <small className="row-display text-white" style={{letterSpacing: '2px'}}>
                <span>99487 : </span> 120 / 120 mins</small>
                <ProgressBar min="110" max="120" variant='success' label={((120 / 120) * 100).toFixed() + "%"} now="120" />
            <br/>
            <small className="row-display text-white" style={{letterSpacing: '2px'}}>
                <span>99487 : </span> {totalTime} / 120 mins</small>
                <ProgressBar min="110" max="120" variant='success' label={((totalTime / 120) * 100).toFixed() + "%"} now={totalTime} />
        </> : null 
        }

        <p style={{marginTop: "14px", fontSize:"12px", color: '#FFF'}}>Total {totalTime} Mins - {new Date().toLocaleString('en-us',{month:'short', year:'numeric'})} in CCM.</p>


    </>
  )
}

export default CCMMinutesProgress