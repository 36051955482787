import React from 'react'
import { CSVLink } from 'react-csv';
const moment = require('moment-timezone');

export const ExportReactCSV = ({csvData, fileName}) => {

    let result = csvData.map((patient, index) => {
        return {
            'Sr No':index + 1,
            'EHR-ID':patient?.emrId || 'N/A',
            'initial Month':patient?.initialsetup,
            'Name':patient?.firstname +" , "+ patient?.lastname,
            'DOB': moment(patient?.DOB).tz("America/New_York").format("MM/DD/YYYY"),
            'Gender': patient?.gender,
            'Email': patient?.email,
            'Phone 1':patient?.phone1,
            ' ':' ',
            'Organization': patient?.assigned_doctor_id?.firstname +" , "+ patient?.assigned_doctor_id?.lastname,
            ' ':' ',
            'Nurse':patient?.assigned_hr_id?.firstname +" , "+ patient?.assigned_hr_id?.lastname,
            ' ':' ',
            'DeviceId (01)': patient?.assigned_devices[0]?.deviceObjectId?._id,
            'DeviceType (01)': patient?.assigned_devices[0]?.deviceObjectId?.deviceType,
            'DeviceId (02)': patient?.assigned_devices[1]?.deviceObjectId?._id,
            'DeviceType (02)': patient?.assigned_devices[1]?.deviceObjectId?.deviceType,
        }
    })


    return (
        <button className="edit-pill">
           <i className='bx bxs-cloud-upload'></i> <CSVLink data={result} filename={fileName} className='link'> Download</CSVLink> 
        </button>
    )
}

export default ExportReactCSV;