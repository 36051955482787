import React, {useState, useEffect, useRef} from 'react';
import Sidebar from '../../components/AdminDashboard/Sidebar';
import TopBar from '../../components/AdminDashboard/TopBar';
import { Image, Badge, Form } from 'react-bootstrap';
import MetaData from '../../layouts/MetaData';
import { getPatients, getPatientTelemetaryReport  } from '../../actions/adminActions';
import Select from 'react-select';
import { useAlert } from 'react-alert';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../layouts/Loader';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import WeightScalePDF from '../../components/WeightScalePDF';
import { GET_DOCTOR_TELEMETARY_REPORT_RESET } from '../../constants/adminConstants';
import patientProfileImg from '../../assets/Images/doctorIcon.png';
import { Link, useHistory } from 'react-router-dom';
import weightImg from '../../assets/Images/scale.png';
import fatImg from '../../assets/Images/fat.png';
import bmiImg from '../../assets/Images/bmi.png';


const WeightScaleReport = () => {
    let color;
    const componentRef = useRef();
    const history = useHistory();

    const handlePrint = useReactToPrint({
        pageStyle:"A5",
        documentTitle:`Telemetary_Report ` + moment().tz("America/New_York").format("MM/DD/YYYY"),
        content: () => componentRef.current,
    });

    const alert = useAlert();
    const [patientId, setPatientId] = useState("");
    const [startDate, setStartDate] = useState(moment().clone().startOf('month').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().clone().endOf('month').format('YYYY-MM-DD'));
    const [withNotes, setWithNotes] = useState(true);

    const { error, patients} = useSelector(state => state.admin);
    const {loading, telemetaryReport} = useSelector(state => state.telemetaryReport);


    const dispatch = useDispatch();

    useEffect(() => {
        if(error){
            alert.error(error);
        }
        
        dispatch(getPatients());
        
    }, [dispatch]);

    const options = []
    patients && patients.map((patient) => (
        options.push({ value: patient?._id, label: [patient?.lastname +", ", patient?.firstname +" ", patient?.ssn].join(" ")})
    ))


    const getPatientProfile = (patient) => {    
        setPatientId(patient.value);
    }

    const resetReport = () => {
        dispatch({
            type: GET_DOCTOR_TELEMETARY_REPORT_RESET
        })
    } 

    const generateReportByPatient = () => {
        if(!patientId) {
            alert.error('Please select patient');
            return;
        } else if(!startDate) {
            alert.error('Please select start Date');
            return;
        } else if(!endDate) {
            alert.error('Please select end Date');
            return;
        } else {
            dispatch(getPatientTelemetaryReport(patientId, startDate, endDate));
        }
    }

    // let filteredReadings = calcTotalReadings();

    // function calcTotalReadings() {
    //    return telemetaryReport && telemetaryReport.filter(healthData => healthData?.deviceId?.deviceType === "weight").reduce((sum, a) =>  
    //     sum + 1, 0
    //   )
    // }

    

  return (
    <>
        <MetaData title="Telemetary Report"/>
        <Sidebar />    

        <section className="home-section">
        {/* TopBar */}
        <TopBar />

        <div className="shadow-lg p-3 mb-5 mr-4 ml-4 rounded-card" style={{backgroundColor: '#FAFAFA'}}>
            <div className="home-content">
                <div className="row-display">
                
                <h5 className='title'>
                    <i className="bx bx-user"></i> <strong>&nbsp;Weight Scale Report </strong>
                </h5>
                
                <div>
                    <button className="btn btn-secondary btn-sm" onClick={() => history.goBack()}>
                        <i className='bx bx-arrow-back' ></i>
                    </button> &nbsp;
                    <Link to="/adminDashboard" className="btn btn-secondary btn-sm"><i className='bx bx-home'></i></Link> &nbsp;
                    <Link className="link" to="/report/telemetary">Telemetry Data Report </Link>
                </div>
            </div>
            <br />
                {/* <h5 className="title pt-2 mt-2">Telemetary Data Report </h5>
                <hr /> */}

                <div>
                <span className="notes-header"><b>Note: </b> Please select patient, start date and end date to generate report.</span>

                <div className="row-display mt-4">    
                    <div className="col-md-3">
                        <b>Select Patient <span style={{color: 'red'}}> *</span> </b>
                        <Select
                            options={options}
                            onChange={getPatientProfile}
                            className="react-selectbox"
                        />
                    </div>

                    <div className="col-md-3">
                        <b>From  <span style={{color: 'red'}}> *</span>  </b>
                        <input type="date" value={startDate} onChange={e => setStartDate(e.target.value)}
                            max={moment().format("YYYY-MM-DD")}
                            className="form-control" placeholder="From"/>
                    </div>

                    <div className="col-md-3">
                        <b>To <span style={{color: 'red'}}> *</span></b>
                        <input type="date" value={endDate} onChange={e => setEndDate(e.target.value)}
                            max={moment().format("YYYY-MM-DD")} 
                            className="form-control" placeholder="To"/> 
                    </div>

                    <div className="col-md-3 mt-4">
                        <button className="edit-pill" onClick={generateReportByPatient}>Generate Report</button>
                    </div>
                </div>
                
                </div>

                <hr />
                {telemetaryReport && telemetaryReport?.length > 0 ? <>
                    <div className="row-display">
                        <div>
                            <b> Generated Report Result: </b> 
                        </div>

                        
                    <div className='row-display'>    
                        <button className="edit-pill" onClick={resetReport}>
                            <i className='bx bxs-file-pdf'></i> Reset
                        </button> 
                        &nbsp;    

                        &nbsp;&nbsp;
                        <div>
                            <Form.Check 
                              checked={withNotes ? true : false}
                              onChange={() => setWithNotes(!withNotes)}
                              type="checkbox"
                              id="filterPts"
                              label="With Notes ?"
                              style={{fontWeight: 'bold'}}
                            />
                        </div>

                        &nbsp;&nbsp;
                        <div>    
                            <div style={{ display: "none" }}> 
                                <WeightScalePDF healthData={telemetaryReport} startDate={startDate} endDate={endDate} ref={componentRef} withNotes={withNotes}/>
                            </div>

                            <button onClick={handlePrint} className="edit-pill"><i className='bx bxs-file-pdf ' style={{color:'#F40F02'}}></i> Download PDF {withNotes === true ? 'with notes' : 'without notes'}</button>
                        </div>
                    </div>
                </div>
                </> : null }
                

                {/* Report Result */}
                <br />
                {loading ? <Loader /> : <>
                   {telemetaryReport && telemetaryReport?.length > 0 ? <>
                    
                    {telemetaryReport.filter(healthData => healthData?.deviceId?.deviceType === "weight").map((healthData, index) => ( <>
                        <div className="telemetary-card" key={index}>
                        <br />
                        <p>
                            <b>
                                {healthData?.assigned_patient_id?.lastname}, {healthData?.assigned_patient_id?.firstname} 
                            </b>
                        </p>
                        
                        <div className="row">
                            <div className="col-md-1">
                                <Image src={weightImg} className="systolic-image" />    
                            </div>
 
                                
                            <div className="col-md-3">
                            <span className="profile-label">Weight : {(healthData?.telemetaryData?.wt / 454).toFixed()} lbs. mmHG</span>
                                <br/>
                                <p style={{color: '#004aa8'}}>
                                <small>
                                    Approx {((healthData?.telemetaryData?.wt / 454).toFixed() * 0.45359237).toFixed()} KG
                                </small>  
                                </p>
                            </div>

                            <div className="col-md-1">
                                <Image src={fatImg} className="systolic-image" />    
                            </div>


                            <div className="col-md-3">
                            <span className="profile-label">Fat : {healthData?.telemetaryData?.fat}</span>
                                <br/>
                            </div>

                            <div className="col-md-1">
                                <Image src={bmiImg} className="systolic-image" />    
                            </div>

                            <div className="col-md-2">
                                <span className="profile-label">BMI : {healthData?.telemetaryData?.bmi}</span>
                            </div>
                        </div> {/* First row ends here*/}    

                         {/* Device & Patient Info */}
                        <br />
                        <div className="row-display telemetary-patient-row pl-2 pr-2">
                                {/* <div className="col-md-7"> */}
                                    <span className="telemetary-patient-row">Device ID: </span>
                                    <span className="telemetary-patient-row"> {healthData?.deviceId?._id}</span>

                                    <span className="telemetary-patient-row ml-2">Device Type: </span>
                                    <span className="telemetary-patient-row"> <Badge bg="info text-white">{healthData?.deviceId?.deviceType}</Badge></span>

                                    <span className="telemetary-patient-row ml-2">Data Sent Date: </span>
                                    <span className="telemetary-patient-row"> {moment(healthData?.telemetaryData?.ts * 1000).tz("America/New_York").format("MM/DD/YYYY hh:mm z A")}</span>

                                    <span className="telemetary-patient-row ml-2">Data Rcvd Date: </span>
                                    {/* <span className="telemetary-patient-row"> {moment(healthData?.createdAt).tz("America/New_York").format("lll")}</span> */}
                                    <span className="telemetary-patient-row"> &nbsp;&nbsp; {moment(healthData?.createdAt).tz("America/New_York").format('MM/DD/YYYY')}</span>
                            </div>

                            <div>
                                {healthData?.notes.map((note, index) => ( <div key={index}>
                                    <div className="row-display-secondary">
                                        <div className="mt-3 mr-3">
                                            <img src={patientProfileImg} alt="hr/drImg" style={{width: '50px', height: '50px', borderRadius: '50%'}}/>
                                        </div>
                                        <div className="bubble bubble-alt">  
                                            
                                            <b className='text-white'>{note?.conclusion_hr_id ? (<span>{note?.conclusion_hr_id?.firstname}  {note?.conclusion_hr_id?.lastname}</span>) : (<span>
                                                {note?.conclusion_doctor_id?.firstname}  {note?.conclusion_doctor_id?.lastname}
                                            </span>)}</b><br/>
                                                                
                                            <p className="mt-1 mr-3">{note?.conclusion} <br/> 
                                            <p className="mt-1" style={{letterSpacing: '1px'}}>{moment(note?.dateTime).tz("America/New_York").format("MM/DD/YYYY hh:mm")}</p>
                                            </p>
                                        </div>
                                    </div>
                                </div> 
                                ))}                         
                            </div>
                        </div>
                    </>))} 
                   </> : <>
                   <div className="text-center">
                       <b>No Result Found.</b>
                   </div>
                   </>} 
                </>}
            </div>
        </div>
        </section>
    </>
  )
}

export default WeightScaleReport;