    import { useEffect, useState, Fragment, useRef } from 'react';
    import MetaData from '../../layouts/MetaData';
    import  HRSidebar from '../../components/HR/HRSidebar';
    import HRTopBar from '../../components/HR/HRTopbar';
    import { getHRPatients, getTimeReport, getTimeReportCCM } from '../../actions/HRActions';
    import { getHrProfile } from '../../actions/adminActions';    
    import {useSelector, useDispatch} from 'react-redux';
    import { RESET_TIME_REPORT_DATA } from '../../constants/HRConstants';
    import moment from 'moment';
    import { Link, useHistory } from 'react-router-dom';
    import { useAlert } from 'react-alert';
    import Loader from '../../layouts/Loader';
    import Select from 'react-select';
    import TimeReportPDF from '../../components/TimeReportPDF';
    import { useReactToPrint } from 'react-to-print';
    import patientProfileImg from '../../assets/Images/doctorIcon.png';

    const TimeReport = () => {
        const dispatch = useDispatch();
        const alert = useAlert();
        const history = useHistory();

        const componentRef = useRef();

        const handlePrint = useReactToPrint({
            pageStyle:"A5",
            documentTitle:  "Time Report",
            content: () => componentRef.current,
        });

        const [patientId, setPatientId] = useState('');
        const [month, setMonth] = useState("09");
        const [year, setYear] = useState("2023");

        const [reportCategory, setReportCategory] = useState('RPM');
        const { hrProfile } = useSelector(state => state.hrprofile);
        

        const { user} = useSelector(state => state.auth);
        const {  error, hrpatients} = useSelector(state => state.hrPatients);
        const {  loading, targets, totalTime, totalInteractiveMinutes, totalNonInteractiveMinutes} = useSelector(state => state.target);


        let id = user._id;
        let docId = hrProfile?.assigned_doctor_id?._id;

        useEffect(() => {
            if(error) {
                alert.error(error);
            }

            dispatch({ type: RESET_TIME_REPORT_DATA });

            dispatch(getHrProfile(id));

            if(docId){
            dispatch(getHRPatients(docId));
            }

        }, [dispatch])

        const submitHandler = () => {

            if(!patientId) {
                alert.error('Please select patient');
                return;
            } else {
                if(reportCategory === 'RPM'){
                    dispatch(getTimeReport(patientId, month, year));
                } else {
                    dispatch(getTimeReportCCM(patientId, month, year)); 
                }
            }    
        }

        const resetData = () => {
            dispatch({ type: RESET_TIME_REPORT_DATA });
        }

        const downloadFile = (fileName) => {
            window.open(`https://vitalsportal.com/v1/uploadFiles/${fileName}`);
        }

        const options = []
            hrpatients && hrpatients.map((patient) => (
            options.push({ value: patient?._id, label: [patient?.lastname +" , "+ patient?.firstname, moment(patient?.DOB).format("MM/DD/YYYY")].join(" ")})
        ))

        const getPatientProfile = (patient) => {    
            setPatientId(patient.value);
        }


    return <Fragment>
        <MetaData title="Time Report" />
                <HRSidebar />
                
                <section className="home-section">
                    {/* TopBar */}  
                    <HRTopBar />


                    <div className="shadow-lg p-3 mb-5 mr-4 ml-4 bg-white rounded">        
                            <div className="home-content">
                                <div className="container">
                                    <div className="row-display">
                                        
                                    <h5 style={{color: '#004aad'}}>
                                        <i className='bx bx-filter'></i> <strong>&nbsp;Time Report for RPM & CCM</strong>
                                    </h5>

                                        <div className="row-display">
                                            <button className="btn btn-secondary btn-sm" onClick={() => history.goBack()}>
                                                <i className='bx bx-arrow-back'></i>
                                            </button>

                                            &nbsp;&nbsp;
                                            <Link to="/Nurse/Dashboard">
                                                <button className="btn btn-secondary btn-sm">
                                                    <i className='bx bxs-home'></i>
                                                </button>
                                            </Link>
                                        </div>  
                                    </div>
                                    <hr /> 

                                    
                                    <div className="row-display">
                                    <div className="col-md-3">
                                    <label className="form-label" htmlFor="from">Select Patient </label>
                                    <Select
                                        options={options}
                                        onChange={getPatientProfile}
                                        className="react-selectbox"
                                    />
                                    </div>


                                <div className="col-md-3">
                                <label htmlFor="from" className="profile-label">Select Month </label>
                                    <select 
                                        label="From" 
                                        name="from" 
                                        className="form-control"
                                        value={month}
                                        onChange={(e) => setMonth(e.target.value)}
                                    >
                                        <option value="01">January</option>
                                        <option value="02">February</option>
                                        <option value="03">March</option>
                                        <option value="04">April</option>
                                        <option value="05">May</option>
                                        <option value="06">June</option>
                                        <option value="07">July</option>
                                        <option value="08">August</option>
                                        <option value="09">September</option>
                                        <option value="10">October</option>
                                        <option value="11">November</option>
                                        <option value="12">December</option>
                                    </select>
                                </div>

                                <div className="col-md-3">     
                                <label htmlFor="year" className="profile-label">Select Year</label>
                                    <select 
                                        label="From" 
                                        name="from" 
                                        className="form-control"
                                        value={year}
                                        onChange={(e) => setYear(e.target.value)}
                                    >
                                        <option value="SelectYear">Select Year</option>
                                        <option value="2022">2022</option>
                                        <option value="2023">2023</option>
                                        <option value="2024">2024</option>
                                        <option value="2025">2025</option>
                                    </select>
                                </div>

                                <div className="col-md-3">                 
                                <label className="form-label" htmlFor="to">Category </label>
                                    <select 
                                        className="form-control"
                                        value={reportCategory}
                                        onChange={e => setReportCategory(e.target.value)}
                                    >
                                        <option value="RPM">RPM</option>
                                        <option value="CCM">CCM</option>
                                    </select>
                                </div>

                                </div>  {/* First Row Ends Here */}

                                <div className="mt-1" style={{float: 'right'}}>     
                                    <button  
                                        name="submit" 
                                        type="submit" 
                                        className="edit-pill mt-3"
                                        onClick={submitHandler}
                                    >
                                    <i className='bx bx-paper-plane'></i> Submit
                                    </button>
                                </div>

                                <br/><br/>
                                

                                {/* Targets Display */}
                                {loading ? <Loader /> : <>
                                
                                {targets && targets.length > 0 ? <Fragment>
                                    <hr className="blue-hr" />
                                    
                                    <small className="colored-row">
                                        <span style={{color: 'red'}}>Note : </span> Following report is showing overall {reportCategory} minutes added by <i>You or other authorized nurses</i> in the selected start date and end date.   
                                        </small>
                                    <br /><br />

                                    <div className="container">
                                        <div className="row-display">
                                            <h5 style={{color: '#004aad'}}>
                                                <i className='bx bxs-watch'></i> <strong>&nbsp;Time Tracking </strong>
                                            </h5>

                                            <div>
                                            <div style={{float: 'right', marginRight: '40px'}}>
                                                <div className="row">
                                                <div
                                                    style={{ display: "none" }} // This make ComponentToPrint show   only while printing
                                                > 
                                                <TimeReportPDF ref={componentRef} 
                                                    targets={targets}
                                                    timeSpent={totalTime}
                                                    month= {month}
                                                    year= {year}
                                                    category={reportCategory}
                                                />
                                                </div>
                                                
                                                <button onClick={handlePrint} 
                                                    className="edit-pill">
                                                        <i className='bx bxs-file-pdf' style={{color:'#F40F02'}}></i> Download PDF
                                                </button> &nbsp;&nbsp;
                                                
                                                <button className="edit-pill" onClick={resetData}>Reset</button>
                                                </div>
                                            </div>
                                            </div>
                                        </div>

                                            <div className="row-display">
                                                <div className="col-md-4 card p-4 m-2 text-center">
                                                    <h2>{totalTime} mins</h2>
                                                    <p><b>Total Time Spent</b></p>
                                                </div>

                                                <div className="col-md-4 card p-4 m-2 text-center">
                                                    <h2>{totalInteractiveMinutes} mins</h2>
                                                    <p style={{color: 'green'}}><b>Interactive Minutes</b></p>
                                                </div>

                                                <div className="col-md-4 card p-4 m-2 text-center">
                                                    <h2>{totalNonInteractiveMinutes} mins</h2>
                                                    <p style={{color: 'red'}}><b>Non-Interactive Minutes</b></p>
                                                </div> 
                                            </div>   
                                    </div>   
                                    <br />
                    
                                    {targets && targets.reverse().map((trgt, index) => ( 
                                    <div className="m-2" key={index}>
                                        
                                        <div className="row-display">
                                        <div className="col-lg-3">
                                            <div className="row-display pl-4 pr-4">
                                                <label className="profile-label">Name: </label> 
                                                <label className="profile-label ml-2"> {trgt?.assigned_patient_id?.lastname +" , "+ trgt?.assigned_patient_id?.firstname}</label>
                                            </div>

                                            <div className="row-display pl-4 pr-4">
                                                <label className="profile-label">D.O.B: </label> 
                                                <label className="report-label ">{moment(trgt?.assigned_patient_id?.DOB).format("MM/DD/YYYY")}</label>
                                            </div>
                                        </div>

                                        {/* Time added details */}
                                        <div className="col-lg-3">
                                            <div className="row-display pl-4 pr-4">
                                                <label className="profile-label">S / D: </label> 
                                                <label className="report-label ml-2"> {moment(trgt?.startDate).format("MM/DD/YYYY") || 'N/A' }</label>
                                            </div>

                                            <div className="row-display pl-4 pr-4">
                                                <label className="profile-label">E / D: </label> 
                                                <label className="report-label ml-2">{moment(trgt?.endDate).format("MM/DD/YYYY") || 'N/A' }</label>
                                            </div> 
                                        </div>

                                        <div className='col-lg-2'>
                                            <div className="row-display pl-4 pr-4">
                                                    <label className="profile-label">S / T: </label> 
                                                    <label className="report-label ml-2">{moment(trgt?.startTime, ["HH.mm"]).format("hh:mm a") || 'N/A'}</label>
                                                </div>
        
                                                <div className="row-display pl-4 pr-4">
                                                    <label className="profile-label">E / T: </label> 
                                                    <label className="report-label ml-2">{moment(trgt?.endTime, ["HH.mm"]).format("hh:mm a") || 'N/A'}</label>
                                            </div>
                                        </div>

                                        <div className='col-lg-1'>
                                            <h5 className="display-4"> {trgt?.timeSpentInMinutes < 10 ? '0'+trgt?.timeSpentInMinutes : trgt?.timeSpentInMinutes}</h5>    
                                        </div>

                                        {/* Notes added */}
                                        <div className="col-lg-3">
                                            <div className="pl-4 pr-4">
                                              
                                            {trgt?.interactiveMinutes === true ?
                                                <b style={{color: 'green'}}>Interactive Minutes</b> 
                                                : 
                                                <b style={{color: 'red'}}>Non-Interactive Minutes</b>
                                            }
                                                
                                            <label className="report-label">added in <span style={{fontWeight: 'bold'}}>
                                                {reportCategory} category</span>
                                            </label>
                                                
                                            {trgt?.fileName ? <>
                                            <label className="profile-label">Attachment: </label> 
                                                <button className="btn btn-link" onClick={() => downloadFile(trgt?.fileName)}>{trgt?.fileName} </button>   
                                            </> : null}
                                            </div>
                                        </div>
                                        </div>
    
                                        <div className="row-display-secondary">
                                                <div className="mt-3 mr-3">
                                                    <img src={patientProfileImg} alt="hr/drImg" style={{width: '50px', height: '50px', borderRadius: '50%'}}/>
                                                </div>
                                                <div className="bubble bubble-alt">  
                                                    
                                                    <b className='text-white' 
                                                        style={{letterSpacing: '1px'}}> 
                                                        {trgt?.assigned_hr_id?.lastname +" , "+ trgt?.assigned_hr_id?.firstname}
                                                    </b>
                                                    <br/>
                                                                        
                                                    <p className="mt-1 mr-3">{trgt?.conclusion} <br/> 
                                                    <p className="mt-1">
                                                        {moment(trgt?.createdAt).tz("America/New_York").format("MM/DD/YYYY hh:mm A")}</p>
                                                    </p>
                                                </div>
                                            </div>
                                        <hr />
                                    </div>

                                ))}
                                </Fragment> : ''}
                                </>}
                                
                            </div>
                        </div>
                    </div>
                </section>
    </Fragment>
    };

    export default TimeReport;
