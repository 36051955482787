import { useEffect } from 'react';
import TopBar from '../AdminDashboard/TopBar';
import { useSelector, useDispatch } from 'react-redux';
import { getAllLogs, getAdminStats } from '../../actions/adminActions';
import { Link } from 'react-router-dom';
import {Image, Spinner} from 'react-bootstrap';
import hrIcon from '../../assets/Images/network.png';
import doctorIcon from '../../assets/Images/doctorIcon.png';
import patientIcon from '../../assets/Images/patientIcon.png';
import heartIcon from '../../assets/Images/heart.png';
import AdminPatientStats from '../../components/AdminDashboard/AdminPatientStats';
import PatientsWithCriticalReadingsAdmin from '../../components/AdminDashboard/PatientsWithCriticalReadingsAdmin';
import RecentReadings from '../../components/AdminDashboard/RecentReadings';
import AllTargetedMinutes from '../../components/AdminDashboard/AllTargetedMinutes';
import NotFound from '../../components/NotFound';



const moment = require('moment-timezone');

const Home = () => {
    const dispatch = useDispatch();
    const { loading, logs} = useSelector(state => state.log);
    const { totalPatients, totalHrs, totalDrs, totalDevices, activePts, blockPts, malePts, femalePts, rpmPts, ccmPts, CCM_Minutes, RPM_Minutes } = useSelector(state => state.adminStat)

    const todayDate = moment().format("MM/DD/YYYY");

    useEffect(() => {
          dispatch(getAllLogs(todayDate));
          dispatch(getAdminStats())
    }, [dispatch, todayDate]);


    return (
        <>

        <section className="home-section">
            <TopBar />

                        
            {/*  Home content  */}
            <div className="home-content">
                <div className="overview-boxes">
                        <div className="box analytics-card">
                                <div className="left-side" >
                                    <h5><b>Total Patients </b></h5>

                                    {/* <Link to="/patients"><h5>Total Patients </h5></Link> */}
                                    <div className="number">
                                        {totalPatients && totalPatients < 10 ? '0'+totalPatients : totalPatients }
                                    </div>
                                    <div className="indicator">
                                        <i className="bx bx-up-arrow-alt down"></i>
                                        <span className="text">Updated just now</span>
                                    </div>
                                </div>
                                <Image src={patientIcon} className="cart" />
                        </div>

                    <div className="box analytics-card">
                        <div className="left-side">
                            <h5><b>RPM Devices </b></h5>
                            <div className="number">{totalDevices && totalDevices < 10 ? '0'+totalDevices : totalDevices }</div>
                            <div className="indicator">
                                <i className="bx bx-up-arrow-alt down"></i>
                                <span className="text">Updated just now</span>
                            </div>
                        </div>
                        <Image src={heartIcon} className="cart"/>
                    </div>

                    <div className="box analytics-card">
                        <div className="left-side">
                            <h5><b>Total Clinicians </b></h5>
                            <div className="number">{totalDrs && totalDrs < 10 ? '0'+totalDrs : totalDrs}</div>
                            <div className="indicator">
                                <i className="bx bx-up-arrow-alt down"></i>
                                <span className="text">Updated just now</span>
                            </div>
                        </div>
                        <Image src={doctorIcon} className="cart three" />
                    </div>

                    <div className="box analytics-card">
                        <div className="left-side">
                            <h5><b>Total Nurses </b></h5>
                            <div className="number">{totalHrs && totalHrs < 10 ? '0'+totalHrs : totalHrs}</div>
                            <div className="indicator">
                                <i className="bx bx-down-arrow-alt down"></i>
                                <span className="text">Updated just now</span>
                            </div>
                        </div>
                        {/* <i className="bx bxs-cart-download cart four"></i> */}
                        <Image src={hrIcon} className="cart" />

                    </div>
                </div>
                <br />
        
                {/* Patient Stats */}
                <AdminPatientStats
                    totalPatients={totalPatients} 
                    activePatients={activePts} 
                    InactivePatients={blockPts} 
                    malePts={malePts}
                    femalePts={femalePts}
                    rpmPts={rpmPts}
                    ccmPts={ccmPts}
                    ccmMinutes={CCM_Minutes}
                    rpmMinutes={RPM_Minutes}
                    />

                
                <div className="col-md-12">
                <div className="row">
                    <div className="col-md-9">
                        <PatientsWithCriticalReadingsAdmin />
                    </div>

                    <div className="col-md-3">
                        <RecentReadings />
                    </div>    
                </div>    
                </div>

                <br /><br />
                {/* Sales Content */}
                        <div className="container-fluid row">
                            <div className="col-md-8">
                                <AllTargetedMinutes />    
                            </div>

                                <div className="col-md-4 col-lg-4 shadow-lg logs-card">
                                    <div className="row-display">
                                     <h5 className="title">Activity Logs <p><small>as of {todayDate} ( today )</small></p></h5>
                                     <Link to="/logs" className="link">
                                        <span className="edit-pill">
                                            <i className='bx bx-slider-alt'></i> Manage Logs</span>
                                     </Link>
                                    </div>
                                    {logs && logs?.length > 0 ? <>
                                        {logs.slice(0,50).map((log, index) => (     
                                            <ul key={index}>
                                                {loading ? <Spinner animation="border" style={{height: '20px', width: '20px'}}/> : <>
                                                    <ol className={ log?.type === 'logs' ? "pills" : 'blue-pills'}>
                                                     
                                                    {index + 1} &nbsp;&nbsp; {log?.text}.
                                                        &nbsp;&nbsp;&nbsp;<span><small>
                                                        <br/>{moment(log.createdAt).tz("America/New_York").format("MM/DD/YYYY hh:mm A")}
                                                    </small>
                                                    </span>
                                                    </ol>
                                                    </>}
                                                </ul>
                                        ))}
                                    </> : <NotFound title="No Activity today" /> }
                                </div>
                            </div>
                  </div>
                  <br/><br/><br/>
                </section>

        </>
    )
}

export default Home
