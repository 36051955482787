import React from 'react'
import NotFoundImg from '../assets/Images/NotFoundImg.png';


const NotFound = ({title}) => {
  return (
    <div className="text-center">
        <img src={NotFoundImg} width="40%" height="40%" />
        <p className="text-center text-muted" style={{letterSpacing:'1px'}}>{title}</p>
    </div>
  )
}

export default NotFound