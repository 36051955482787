import {useEffect, useState, useRef} from 'react';
import Sidebar from '../../components/AdminDashboard/Sidebar';
import TopBar from '../../components/AdminDashboard/TopBar';
import MetaData from '../../layouts/MetaData';
import patientProfileImg from '../../assets/Images/doctorIcon.png';
import { Link, useHistory } from 'react-router-dom';
import { getHrLists, getDoctors, deleteHRMinutes, getCCMPatients } from '../../actions/adminActions';
import { useSelector, useDispatch } from 'react-redux';
import { getTimeReportCCMByHR, getTimeReportCCMByDR, getTimeReportCCMByPatient } from '../../actions/HRActions';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import TimeReportPDF from '../../components/TimeReportPDF';
import Loader from '../../layouts/Loader';
import { RESET_TIME_REPORT_DATA } from '../../constants/HRConstants';
import { useAlert } from 'react-alert';

const TimeReportCCM = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const alert = useAlert();
  
  const [reportBy, setReportBy] = useState('patient');
  const [month, setMonth] = useState("09");
  const [year, setYear] = useState("2023");
  const [hrId, setHRId] = useState('');
  const [drId, setDRId] = useState('');
  const [patientId, setPatientId] = useState('');

  const {error, message} = useSelector(state => state.common);
  const { hrs } = useSelector(state => state.hrslist);
  const { doctors } = useSelector(state => state.doctor);
  const { patients } = useSelector(state => state.admin);
  const { loading, targets, totalTime, totalInteractiveMinutes, totalNonInteractiveMinutes} = useSelector(state => state.target);
  const [reportCategory, setReportCategory] = useState('CCM');
  

  useEffect(() =>{

    if(error){
        alert.error(error);
    }

    if(message){
        alert.success(message);
    }

    dispatch(getHrLists());
    dispatch(getDoctors(10, 1));
    dispatch(getCCMPatients());

    dispatch({ type: RESET_TIME_REPORT_DATA });

  }, [dispatch, error, message])

  const componentRef = useRef();
        const handlePrint = useReactToPrint({
            pageStyle:"A5",
            documentTitle:  "Time Report",
            content: () => componentRef.current,
    });

  const submitHandler = () => {
    if(reportBy === 'hr'){
        if(!hrId){
            alert.error('Please select nurse');
            return;
        } 
        else {
             dispatch(getTimeReportCCMByHR(hrId, month, year)); 
        }
    }
    else if (reportBy === 'patient'){
        if(!patientId){
            alert.error('Please select patient');
            return
        }
        else {
            dispatch(getTimeReportCCMByPatient(patientId, month, year));
        }
    }
    else {
        if(reportBy === 'doctor'){
        if(!drId){
            alert.error('Please select Doctor');
            return;
        } else {
        dispatch(getTimeReportCCMByDR(drId, month, year)); 
        return;
        }
       }
     }
    }


    const resetData = () => {
        dispatch({ type: RESET_TIME_REPORT_DATA });
    }

    const downloadFile = (fileName) => {
        window.open(`https://vitalsportal.com/v1/uploadFiles/${fileName}`);
    }

    const deleteHandler = (Id) => {
        var confirmation = window.confirm(`Are you sure you want to delete minutes ?`);
            if(confirmation){
                dispatch(deleteHRMinutes(Id));
            }
        }


  return (
    <>
        <MetaData title="Time Report | CCM"/>
            <Sidebar />    

            <section className="home-section">
            {/* TopBar */}
            <TopBar />

            <div className="shadow-lg rounded-card m-3" style={{backgroundColor: '#FAFAFA'}}>
            <div className='searchArea p-3'>
                <div className="home-content">

                <div className="row-display">
                    <h5 style={{color: '#004aad'}}>
                        <i className='bx bx-filter'></i> 
                        <strong>&nbsp;Time Report for CCM </strong>
                    </h5>

                    <div className="row-display">
                        <button className="btn btn-secondary btn-sm" onClick={() => history.goBack()}>
                            <i className='bx bx-arrow-back'></i>
                        </button>
                        &nbsp;&nbsp;
                        <Link to="/Admin/Report/RPM" className="link m-2">
                            RPM Time Report
                        </Link>
                        &nbsp;&nbsp;
                        <Link to="/adminDashboard" className="btn btn-secondary btn-sm">
                            <i className='bx bxs-home'></i>
                        </Link>
                    </div>   
                </div>
                <hr />

                <span className="notes-header"><b>Note: </b> You can generate CCM time report by doctor and by Nurse.</span>

                <div className="row cardWrapper">
                    
                    <div className={`cardButton ${reportBy === "patient" ? "cardActive" : ""}`}
                        onClick={() => setReportBy("patient")}>
                        <i className="fas fa-user" style={{color: '#FAFAFA', fontSize: '66px'}}></i>
                        <p> By Patient </p>
                    </div>

                    <div className={`cardButton ${reportBy === "doctor" ? "cardActive" : ""}`}
                        onClick={() => setReportBy("doctor")}>
                        <i className="fas fa-user-md" style={{color: '#FAFAFA', fontSize: '66px'}}></i>
                        <p> By Clinician </p>
                    </div>
                    
                    
                    <div className={`cardButton ${reportBy === "hr" ? "cardActive" : ""}`}
                        onClick={() => setReportBy("hr")}>
                        <i className="fas fa-users" style={{color: '#FAFAFA', fontSize: '66px'}}></i>
                        <p> By Nurse </p>
                    </div>
                </div>
                <br />

                {/* first row */}
                <div className="row">
                    <div className="col-md-3">
                    
                    {reportBy === 'patient' ? <>
                    <label className="form-label" htmlFor="from">Select Patient <span style={{color: 'red'}}>*</span></label>
                    <select 
                        className="form-control"
                        value={patientId}
                        onChange={(e) => setPatientId(e.target.value)}
                        >

                        <option>Select Patient</option>
                        {patients && patients.filter(item => item?.block === false).map((patient, index) => (
                                <option value={patient?._id} key={index}> {patient?.lastname}, {patient?.firstname} {moment(patient?.DOB).format("MM/DD/YYYY")} </option>
                            ))} 
                    </select>
                    </> : 
                    
                    reportBy === 'hr' ? <>    
                    <label className="form-label" htmlFor="from">Select Nurse <span style={{color: 'red'}}>*</span></label>
                    <select 
                        className="form-control"
                        value={hrId}
                        onChange={(e) => setHRId(e.target.value)}
                        >

                        <option>Select Nurse</option>
                        {hrs && hrs.filter(hr => hr.block === false).map((hr, index) => (
                                <option value={hr?._id} key={index}> {hr?.lastname}, {hr?.firstname} {hr?.npinumber} </option>
                            ))} 
                    </select>                        
                    </>
                    : <>
                    <label className="form-label" htmlFor="from">Select Clinician <span style={{color: 'red'}}>*</span></label>
                    <select 
                        className="form-control"
                        value={drId}
                        onChange={(e) => setDRId(e.target.value)}
                        >
                        <option>Select Clinician</option>
                        {doctors && doctors.map((doctor, index) => (
                                <option value={doctor?._id} key={index}> {doctor?.lastname}, {doctor?.firstname} {doctor?.npinumber} </option>
                            ))} 
                    </select>                        
                    </>}
                </div>


                    <div className="col-md-3">
                        <label className="form-label" htmlFor="month">Select Month </label>
                        <select 
                        name="month" 
                        className="form-control"
                        value={month} 
                        onChange={e => setMonth(e.target.value)}
                        >
                        <option value="01">January</option>
                        <option value="02">February</option>
                        <option value="03">March</option>
                        <option value="04">April</option>
                        <option value="05">May</option>
                        <option value="06">June</option>
                        <option value="07">July</option>
                        <option value="08">August</option>
                        <option value="09">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                        </select>        
                    </div>

                    <div className="col-md-3">     
                        <label className="form-label" htmlFor="year">Select Year </label>
                        <select 
                        name="year" 
                        className="form-control"
                        value={year} 
                        onChange={e => setYear(e.target.value)}
                        >
                        <option value="2022">2022</option>
                        <option value="2023">2023</option>
                        <option value="2024">2024</option>
                        <option value="2025">2025</option>
                        </select>
                    </div>

                    <div className="col-md-3 mt-2">     
                    <label>&nbsp;&nbsp;&nbsp;</label>
                    <button  
                        name="submit" 
                        type="submit" 
                        className="edit-pill mt-4"
                        onClick={submitHandler}
                    >
                        Generate Report
                    </button>
                      </div>
                     </div>

               </div>
              </div>

               {/* First Row Ends Here */}
               {loading ? <Loader /> : <>
                    {targets && targets.length > 0 ? <>
                                    
                        <br />
                        <div className="container row-display">
                            <div className="col-md-12">
                            <div className="row-display">
                                <h5 style={{color: '#004aad'}}>
                                    <i className='bx bxs-watch'></i> <strong>&nbsp;RPM Minutes Added </strong>
                                </h5>

                                <div style={{float: 'right', marginRight: '40px'}}>
                                    <div className="row">
                                    <div
                                        style={{ display: "none" }} // This make ComponentToPrint show   only while printing
                                    > 
                                    <TimeReportPDF ref={componentRef} 
                                        targets={targets}
                                        timeSpent={totalTime}
                                        month= {month}
                                        year= {year}
                                        category={reportCategory}
                                    />
                                    </div>
                                    
                                    <button onClick={handlePrint} 
                                        className="edit-pill">
                                            <i className='bx bxs-file-pdf' style={{color:'#F40F02'}}></i> Download Report .PDF</button>
                                            <button className="edit-pill ml-2" onClick={resetData}>Reset</button>
                                        </div>
                                    </div>


                            </div>     
                            
                            <div className="row-display mt-3">
                                <div className="col-md-4 card p-4 text-center">
                                    <h2>{totalTime} mins</h2>
                                    <p><b>Total Time Spent</b></p>
                                </div>

                                <div className="col-md-4 card p-4 text-center">
                                    <h2>{totalInteractiveMinutes} mins</h2>
                                    <p style={{color: 'green'}}><b>Interactive Minutes</b></p>
                                </div>

                                <div className="col-md-4 card p-4 text-center">
                                    <h2>{totalNonInteractiveMinutes} mins</h2>
                                    <p style={{color: 'red'}}><b>Non-Interactive Minutes</b></p>
                                </div> 
                                </div>
                              </div>        
                            </div>
                            <br />

                                    {targets && targets.reverse().map((trgt, index) => ( 
                                    <div className="m-2" key={index}>
                                        
                                        <div className="row-display">
                                        <div className="col-lg-3">
                                            <div className="row-display pl-4 pr-4">
                                                <label className="profile-label">Name: </label> 
                                                <label className="report-label ml-2"> {trgt?.assigned_patient_id?.firstname} {trgt?.assigned_patient_id?.lastname}</label>
                                            </div>

                                            <div className="row-display pl-4 pr-4">
                                                <label className="profile-label">D.O.B: </label> 
                                                <label className="report-label ml-2">{moment(trgt?.assigned_patient_id?.DOB).format("MM-DD-YYYY")}</label>
                                            </div>
                                        </div>

                                        {/* Time added details */}
                                        <div className="col-lg-3">
                                            <div className="row-display pl-4 pr-4">
                                                <label className="profile-label">S / D : </label> 
                                                <label className="report-label ml-2"> {moment(trgt?.startDate).format('MM/DD/YYYY') || 'N/A' }</label>
                                            </div>

                                            <div className="row-display pl-4 pr-4">
                                                <label className="profile-label">E / D: </label> 
                                                <label className="report-label ml-2">{moment(trgt?.endDate).format("MM/DD/YYYY") || 'N/A' }</label>
                                            </div> 
                                        </div>

                                        <div className='col-lg-2'>
                                            <div className="row-display pl-4 pr-4">
                                                    <label className="profile-label">S / T: </label> 
                                                    <label className="report-label ml-2">{trgt?.startTime || 'N/A'}</label>
                                                </div>
        
                                                <div className="row-display pl-4 pr-4">
                                                    <label className="profile-label">E / T : </label> 
                                                    <label className="report-label ml-2">{trgt?.endTime || 'N/A'}</label>
                                            </div>
                                        </div>

                                        <div className='col-lg-1'>
                                            <h5 className="display-4"> {trgt?.timeSpentInMinutes < 10 ? '0'+trgt?.timeSpentInMinutes : trgt?.timeSpentInMinutes}</h5>    
                                        </div>

                                        {/* Notes added */}
                                        <div className="col-lg-3">
                                            <div className="pl-4 pr-4">
                                              
                                            {trgt?.interactiveMinutes === true ?
                                                <b style={{color: 'green'}}>Interactive Minutes</b> 
                                                : 
                                                <b style={{color: 'red'}}>Non-Interactive Minutes</b>
                                            }
                                                
                                            <label className="report-label">added in <span style={{fontWeight: 'bold'}}>
                                                {reportCategory} category</span>
                                            </label>
                                                
                                            {trgt?.fileName ? <>
                                            <label className="profile-label">Attachment: </label> 
                                                <p><button className="btn btn-link" onClick={() => downloadFile(trgt?.fileName)}>{trgt?.fileName} </button>   
                                                </p></> : null}
                                            </div>
                                        </div>
                                        </div>
    
                                        <div className="row-display-secondary">
                                                <div className="mt-3 mr-3">
                                                    <img src={patientProfileImg} alt="hr/drImg" style={{width: '50px', height: '50px', borderRadius: '50%'}}/>
                                                </div>
                                                <div className="bubble bubble-alt">  
                                                    <div className="row-display">
                                                    <p className='mt-2' 
                                                        style={{letterSpacing: '1px'}}> 
                                                        {trgt?.assigned_hr_id?.firstname +" "+ trgt?.assigned_hr_id?.lastname +" ("+ ( trgt?.assigned_hr_id?.role + " )" ) }
                                                    </p>

                                                    <button className='edit-pill'
                                                        onClick={() => deleteHandler(trgt?._id)}>
                                                        Delete
                                                    </button>
                                                    </div>
                                                                        
                                                    <p className="mt-1 mr-3">{trgt?.conclusion} <br/> 
                                                    <p className="mt-1"><b>{moment(trgt?.createdAt).tz("America/New_York").format("MM-DD-YYYY")}</b></p>
                                                    </p>
                                                </div>
                                            </div>
                                        <hr />
                                    </div>

                                ))}
                            </> : ''}
                            </>}
            </div>
            </section>
    </>
  )
}

export default TimeReportCCM