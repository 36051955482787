import React from 'react';
import logo from '../assets/Images/official_logo.png';
import moment from 'moment';
import patientProfileImg from '../assets/Images/doctorIcon.png';


export default class MyDocument extends React.PureComponent {
    render() {

        const { targets } = this.props;
        const { timeSpent } = this.props;
        const { month } = this.props;
        const { year } = this.props;
        const { category } = this.props;


      return (
        <div>
            <div className="container row-display">
                <img src={logo} width="270" height="110" alt="logo" />
                <div>
                    <p className="mt-4"><b>Date Printed: </b>  {moment().tz("America/New_York").format("MM/DD/YYYY")} </p>
                    <i>Generated By Admin</i> 
                </div>
            </div>

            <hr />
            <div className="row-display">
                <div>
                <h3>Time Tracking Report ( {category} )</h3>
                <h5>{month} - {year}</h5>

                </div>
                <p><b>Total Time Spent</b> {timeSpent} mins</p> 
            </div>

            {targets && targets.map((trgt, index) => ( 
                                  <div className="m-5" key={index}>
                                  
                                      <div className="row-display">
                                      <div className="col-lg-5">
                                      <div className="row-display pl-4 pr-4">
                                             <label className="profile-label">EHR - ID: </label> 
                                             <label className="report-label ml-2"> {trgt?.assigned_patient_id?.emrId || '--'}</label>
                                       </div>

                                        <div className="row-display pl-4 pr-4">
                                             <label className="profile-label">Patient Name: </label> 
                                             <label className="report-label ml-2"> {trgt?.assigned_patient_id?.firstname} {trgt?.assigned_patient_id?.lastname}</label>
                                          </div>

                                          <div className="row-display pl-4 pr-4">
                                             <label className="profile-label">Patient Gender: </label> 
                                             <label className="report-label ml-2">{trgt?.assigned_patient_id?.gender}</label>
                                          </div>
 
                                          <div className="row-display pl-4 pr-4">
                                             <label className="profile-label">Patient Email: </label> 
                                             <label className="report-label ml-2">{trgt?.assigned_patient_id?.email}</label>
                                          </div>
 
                                          <div className="row-display pl-4 pr-4">
                                             <label className="profile-label">Patient DOB: </label> 
                                             <label className="report-label ml-2">{moment(trgt?.assigned_patient_id?.DOB).format("MM/DD/YYYY")}</label>
                                          </div>
                                      </div>

                                      {/* Time added details */}
                                      <div className="col-lg-4">
                                        <div className="row-display pl-4 pr-4">
                                             <label className="profile-label">Start Date: </label> 
                                             <label className="report-label ml-2"> {trgt?.startDate || 'N/A' }</label>
                                          </div>

                                          <div className="row-display pl-4 pr-4">
                                             <label className="profile-label">End Date: </label> 
                                             <label className="report-label ml-2">{trgt?.endDate || 'N/A' }</label>
                                          </div>
 
                                          <div className="row-display pl-4 pr-4">
                                             <label className="profile-label">Start Time: </label> 
                                             <label className="report-label ml-2">{trgt?.startTime || 'N/A'}</label>
                                          </div>
 
                                          <div className="row-display pl-4 pr-4">
                                             <label className="profile-label">End Time: </label> 
                                             <label className="report-label ml-2">{trgt?.endTime || 'N/A'}</label>
                                          </div>

                                          <div className="row-display pl-4 pr-4">
                                             <label className="profile-label">Category: </label> 
                                             <label className="report-label ml-2">RPM</label>
                                          </div>
                                      </div>
                                    
                                    {/* Notes added */}
                                      <div className="col-lg-3">
                                        <div className="pl-4 pr-4">
                                             <label className="profile-label">Time Calculated : </label> 
                                             <p className="report-label"> {trgt?.timeSpentInMinutes} mins</p>
                                            
                                             <label className="profile-label">Date Added : </label> 
                                             <p className="report-label"> {moment(trgt?.createdAt).format("MM/DD/YYYY")}</p>
                                          </div>
                                      </div>
            
                                      </div>
 
                                      <div className="row-display-secondary">
                                            <div className="mt-3 mr-3">
                                                <img src={patientProfileImg} alt="hr/drImg" style={{width: '50px', height: '50px', borderRadius: '50%'}}/>
                                            </div>
                                            <div className="bubble bubble-alt">  
                                                
                                                <b>HR. {trgt?.assigned_hr_id?.firstname} {trgt?.assigned_hr_id?.lastname}</b><br/>
                                                                    
                                                <p className="mt-1 mr-3">{trgt?.conclusion} <br/> 
                                                <p className="mt-1"><b>{moment(trgt?.createdAt).tz("America/New_York").format("MM/DD/YYYY")}</b></p>
                                                </p>
                                            </div>
                                        </div>
                                    <hr />
                                  </div>
                              ))}
        </div>
      )
    }
}