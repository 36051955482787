import React, {Fragment, useEffect, useState} from 'react'
import  Sidebar from '../../components/Staff/Sidebar';
import TopBar from '../../components/Staff/TopBar';
import MetaData from '../../layouts/MetaData';
import Loader from '../../layouts/Loader';
import {useSelector, useDispatch} from 'react-redux';
import { patientProfile, getPatientTelemetryData, getRemainingReadings, sortTelemetartData} from '../../actions/adminActions';
import {getTimeReport} from '../../actions/HRActions';
import { getPatientCarePlan } from '../../actions/HRActions';
import patientProfileImg from '../../assets/Images/add-user.png';
import CuffTelemetaryData from '../../components/Patient/CuffTelemetaryData';
import WeightTelemetaryData from '../../components/Patient/WeightTelemetaryData';
import moment from 'moment';
import { COMMENT_RESET } from '../../constants/HRConstants';
import { Badge, Tabs, Tab, Row, Col, Nav, Image } from 'react-bootstrap';
import systolicImg from '../../assets/Images/blood-pressure.png';
import { useAlert } from 'react-alert';
import Pagination from 'react-js-pagination';
import PatientProfileGraph from '../../components/PatientProfileGraph';
import { Link, useHistory } from 'react-router-dom';
import PatientRPMConsent from '../../components/Patient/PatientRPMConsent';
import PatientRPMCareplan from '../../components/Patient/PatientRPMCareplan';
import doctorIcon from '../../assets/Images/doctorIcon.png';
import notFound from '../../assets/Images/notFound.png';
import RPMMinutesProgress from '../../components/HR/RPMMinutesProgress';



const StaffPatientProfile = (props) => {
    
    const dispatch = useDispatch();
    const alert = useAlert();
    const history = useHistory();

    
    let patientid = props?.location?.state?.patientid;
    let readingmonth;
    let currMonthName  = moment().format('MMMM');


    const { loading, error, patient, isUpdated} = useSelector(state => state.patientProfile);
    const { commentSuccess} = useSelector(state => state.comments);
    const { deviceData, Count } = useSelector(state => state.deviceData);
    const { isAuthenticated} = useSelector(state => state.auth);
    const { careplan } = useSelector(state => state.careplan);
    const { count } = useSelector(state => state.readingsCount);
    const { totalTime } = useSelector(state => state.target);

    const [readingPerPage, setReadingsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [sort, sortBy] = useState(-1);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [accordion, setAccordion] = useState(false);

    let strtDate = moment().clone().startOf('month').format('YYYY-MM-DD');
    let lastDate = moment().clone().endOf('month').format('YYYY-MM-DD');



    useEffect(() => {
        if(isAuthenticated === false) {
			props?.history?.push("/login");
		}

        if(error){
            return alert.error(error);
        }


        dispatch(patientProfile(patientid));
        dispatch(getPatientTelemetryData(patientid, readingPerPage, currentPage, sort));
        dispatch(getPatientCarePlan(patientid));
        dispatch(getRemainingReadings(patientid));
        dispatch(getTimeReport(patientid, strtDate, lastDate));
        
        if(commentSuccess) {
            alert.success('Comment added');
            dispatch({ type: COMMENT_RESET });
            dispatch(patientProfile(patientid));
            dispatch(getPatientTelemetryData(patientid))
        }

        if(isUpdated) {
            alert.success('Updated Successfully');
        }

    }, [dispatch, alert, error, isUpdated, commentSuccess, currentPage, sort]);


    function setCurrentPageNumber(currentPage) {
        setCurrentPage(currentPage);
    }

    let readingsThisMonth;
    let ReadingsperMonth; 
 
    readingsThisMonth = count;
    ReadingsperMonth = careplan?.data?.readingsPerMonth;

    const refreshHandler = () => {
        dispatch(patientProfile(patientid));
        dispatch(getPatientTelemetryData(patientid, readingPerPage, currentPage, sort));
        dispatch(getPatientCarePlan(patientid));
        setStartDate('');
        setEndDate('');
        // setSort('')
    }

    const sortPatientTelemetaryData = () => {
        dispatch(sortTelemetartData(patientid, startDate, endDate, readingPerPage, currentPage));
    }

    const sendEmail = (email) => {
        window.open(`mailto:${email}`)
    }

    let filteredReadings = calcTotalReadings();

        function calcTotalReadings() {
        return deviceData && deviceData.filter(healthData => healthData?.deviceId?.deviceType === "bp").reduce((sum, a) =>  
            sum + 1, 0
        )
        }

    return (
        <Fragment>
           <MetaData title="Patient Profile"/>

                <Sidebar />    

                <section className="home-section">
                {/* TopBar */}
                <TopBar />
                
                {loading ? <Loader /> : <Fragment>
                <div className="shadow-lg p-3 mb-5 mr-4 ml-4 bg-white rounded">        
                        <div className="home-content">
                            <div className="container">    

                            {patient && <Fragment>
                            <div className="row-display">
                                
                                <h5 className="title pt-2 mt-2">{patient?.lastname}, {patient?.firstname} Details </h5>
                                
                                <div>
                                &nbsp;&nbsp;     
                                    <button className="btn btn-secondary btn-sm mt-2" onClick={() => history.goBack()}>
                                        <i className='bx bx-arrow-back'></i>
                                    </button>

                                    &nbsp;&nbsp;     
                                    <Link to="/doctor/dashboard" className="btn btn-secondary btn-sm mt-2">
                                        <i className='bx bx-home'></i>
                                    </Link>
                                </div>
                                </div>
                            <hr />

                                <div className="col-md-12">
                                <div className="row">

                                <div className="col-md-3">
                            <div className="card text-center">
                                <img src={patientProfileImg} className="img-responsive profile-card-img mt-4" alt="patientProfile" />
                                <b className="mt-3">{patient?.firstname} {patient?.lastname}  </b>
                                <span><small>{patient?._id}</small></span>
                                <Link to="/Nurse/Patient/Profile" className="link" style={{marginLeft: "10%", fontSize: "14px", marginTop: "7px"}} onClick={() => sendEmail(patient?.email)}>{patient?.email}</Link>
                                <span style={{padding: '5px', color: "#222"}}><small>Age: {moment().diff(moment(patient?.DOB).format("ll"), 'years')} yrs</small></span>
                                <br/>
                            </div>

                            {/* Consent Program */}
                            <br/>
                            <div className="card text-center p-1">
                                <b>Consent Role</b>  
                                <h3 style={{color: '#004aad'}}>
                                    {patient?.patientType}
                                </h3>
                                <b>for this patient</b>
                            </div>

                            <br />
                            <div className="card text-center p-2">
                                <b>Information</b>
                                <div className="row-display">
                                    <div style={{width: '120px'}}>
                                        <small><b>Acc. Status:</b></small>
                                    </div>

                                    <div style={{width: '200px'}}>
                                        {patient?.block === true ? <Badge bg="danger text-white" style={{padding: '5px', letterSpacing: '2px'}}>Blocked</Badge> : 
                                            <Badge bg="success text-white" style={{padding: '5px', letterSpacing: '2px'}}>Active</Badge>
                                        }
                                    </div>
                                </div>

                                <div className="row-display">
                                    <div style={{width: '120px'}}>
                                        <small><b>Gender:</b></small>
                                    </div>

                                    <div style={{width: '200px'}}>
                                        <small>{patient?.gender}</small>
                                    </div>
                                </div>

                                <div className="row-display-secondary">
                                    <div style={{width: '120px'}}>
                                        <small><b>D.O.B:</b></small>
                                    </div>

                                    <div style={{width: '200px'}}>
                                        <small>{moment(patient?.DOB).format('LL')}</small>
                                    </div>
                                </div>

                                <div className="row-display">
                                    <div style={{width: '120px'}}>
                                        <small><b>Primary Ph #:</b></small>
                                    </div>

                                    <div style={{width: '200px'}}>
                                        <small>{patient?.phone1 || '--'}</small>
                                    </div>
                                </div>

                                <div className="row-display">
                                    <div style={{width: '120px'}}>
                                        <small><b>Diseases:</b></small>
                                    </div>

                                    <div style={{width: '200px'}}>
                                        <small>{patient?.diseases || '--'}</small>
                                    </div>
                                </div>

                                <div className="row-display">
                                    <div style={{width: '120px'}}>
                                        <small><b>Ins. Co.:</b></small>
                                    </div>

                                    <div style={{width: '200px'}}>
                                        <small>{patient?.insurancecompany || '--'}</small>
                                    </div>
                                </div>

                                <div className="row-display">
                                    <div style={{width: '120px'}}>
                                        <small><b>Acc. Created:</b></small>
                                    </div>

                                    <div style={{width: '200px'}}>
                                        <small>{moment(patient?.createdAt).format('MM/DD/YYYY') || '--'}</small>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                                <div className="card">
                                    <div className="row">
                                        <div className="col-3 text-center" style={{backgroundColor: '#004aad'}}>
                                            <h3 className="text-white"> {totalTime} </h3>
                                        </div>
                                        <div className="col-7 text-center mt-2">
                                            <small>Minutes added in <b>{currMonthName}</b> in RPM Category</small>
                                        </div>
                                    </div>
                                </div>
                            <br />        

                            <div className="card">
                                <span className="text-center mt-3"> <small>{patient?.address}, {patient?.city} - {patient?.line2} - {patient?.city}, {patient?.state} - {patient?.zipCode} </small></span>
                                <hr />

                                <div className="row">
                                {/* Devices Details */}

                            
                            <div className="col-6 text-center" style={{borderRightWidth: '2px', borderRightStyle: 'solid', borderRightColor: '#F1F1FA'}}>
                                <b className="mt-3">Device (s) Assigned  </b>
                                <br/>
                                {patient?.assigned_devices && patient?.assigned_devices.map((deviceass, index) => (
                                    <div key={index}>
                                        {/* device IMEI */}
                                        <div className="row-display">
                                            <div style={{width: '120px'}}>
                                                <small>Device IMEI:</small>
                                            </div>

                                            <div style={{width: '170px'}}>
                                                <small>{deviceass?.deviceObjectId?.imei || '---'}</small>
                                            </div>
                                        </div>

                                        {/* device Type */}
                                        <div className="row-display">
                                            <div style={{width: '120px'}}>
                                                <small>Device Type:</small>
                                            </div>

                                            <div style={{width: '170px'}}>
                                                {deviceass?.deviceObjectId?.deviceType === 'bp' ? 
                                                <Badge bg="warning text-white" style={{padding: '5px', letterSpacing: '2px'}}>cuff</Badge>
                                                : deviceass?.deviceObjectId?.deviceType === 'spO2' ? <Badge bg="info text-white" style={{padding: '5px', letterSpacing: '2px'}}>Spo2</Badge> : 
                                                <td><Badge bg="danger text-white" style={{padding: '5px', letterSpacing: '2px'}}>Weight</Badge></td>}
                                            </div>
                                        </div>

                                        {/* device Type */}
                                        <div className="row-display">
                                            <div style={{width: '120px'}}>
                                                <small>Assign Date:</small>
                                            </div>

                                            <div style={{width: '170px'}}>
                                                <small>{deviceass?.deviceObjectId?.assignedTime || '---'}</small>
                                            </div>
                                        </div>

                                        <div className="row-display">
                                            <div style={{width: '120px'}}>
                                                <small><b>RPM Consent: </b></small>
                                            </div>

                                            <div style={{width: '170px'}}>
                                                <small>{patient?.rpmconsent === true ? <span style={{color: 'green', fontWeight: 'bold'}}>
                                                    Signed</span> : 
                                                    <span style={{color: 'red', fontWeight: 'bold'}}>Not Signed</span>}
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>           
                            
                            <div className="col-6">
                                {/* Assigned Doctor Nurse */}
                                <div className="text-center">
                                            <span className="text-center"><b>Assigned Doctor</b>
                                            {patient?.assigned_doctor_id ? <>
                                            <div>
                                                <img src={doctorIcon} alt="patientProfile" style={{width:'60px', height:'60px'}}/>    
                                                    <p className="pt-2 m-1">Dr. {patient?.assigned_doctor_id?.firstname} {patient?.assigned_doctor_id?.lastname}</p>
                                                    <small><b>Role: </b> Doctor</small>
                                            </div>
                                            </> : <p>
                                                <small className="tect-center mt-2"> No Doctor Assigned Yet</small>
                                            </p> }
                                            </span>      
                                            <br />
                                        </div>
                                    </div>
                                </div>    
                            </div>
                           <br/>


                           <div className="card">
                        <div className="row">
                            <div className="col-5 text-center">
                                <b><br/>Telemetary Readings</b>
                                <hr/>

                                {/* Telemetary Readings */}
                                {deviceData && deviceData.length > 0 ? <>
                                    {deviceData && deviceData.filter(healthData => healthData?.deviceId?.deviceType === "bp").slice(0,5).map((devicedata, index) => (
                                    <div key={index} className="row-display text-center ml-4 mt-2" >
                                        {devicedata.telemetaryData?.sys && devicedata.telemetaryData?.dia !== undefined ? <>
                                            <Image src={systolicImg} style={{width: '30px', height: '30px'}} /> 
                                                {devicedata?.telemetaryData?.sys} / {devicedata?.telemetaryData?.dia} <small>mmHG</small>
                                                {/* */}
                                                
                                                <br/>
                                                <br/>
                                        </> : " "}
                                    </div>
                                ))}
                            </> : <div className="text-center">
                                <img src={notFound} style={{width: '40px', height: '40px'}} alt="not found" />
                                    <p><small>No records found</small></p>
                                </div> 
                                }
                            </div>

                            <div className="col-7 text-center">
                                <b className="ml-2"><br/>Avg Systolic / Diastolic</b>
                                <hr />

                                <div className="row-display">
                                <div className="circularbox">
                                    <div className="percent">
                                    <svg stroke='darkblue'>
                                        <circle cx="70" cy="70" r="50"></circle>
                                        <circle cx="70" cy="70" r="50"></circle>  
                                    </svg> 

                                        <div className="number">
                                        {deviceData && deviceData.filter(healthData => healthData?.deviceId?.deviceType === "bp").slice(0,5).reduce((total, devicedata) =>  
                                        (Number(total) + Number(devicedata?.telemetaryData?.sys) / filteredReadings).toFixed(), 0
                                        )}
                                        
                                        </div>
                                    </div>
                                    <h5 className="text">Avg. Systolic</h5>
                                </div>

                                <div className="circularbox">
                                    <div className="percent">
                                    <svg stroke='dodgerblue'>
                                            <circle cx="70" cy="70" r="50"></circle>
                                            <circle cx="70" cy="70" r="50"></circle>  
                                        </svg> 

                                        <div className="number">
                                        {deviceData && deviceData.filter(healthData => healthData?.deviceId?.deviceType === "bp").reduce((total, devicedata) =>  
                                        (Number(total) + Number(devicedata?.telemetaryData?.dia) / filteredReadings).toFixed(), 0
                                        )} 
                                        </div>
                                    </div>
                                        <h5 className="text">Avg. Diastolic</h5>
                                    </div>
                                    </div>
                                   </div>
                                  </div>
                                </div>
                                
                        </div>

                        <div className="col-md-3 ">

                            <div className="patient-profile-data-div">
                                <p style={{fontSize: 14}} className="text-center mt-2">RPM Consent : </p>
                            <span className="check-icon mt-2">{patient?.rpmconsent === true ? 'Signed' : 'Not Signed'}</span>
                        </div>

                        {careplan ? <>
                                    <div className="patient-profile-data-div mt-2">
                                <p style={{fontSize: 14}} className="text-center mt-2">Readings /mo : </p>
                                <span className="check-icon mt-2">{careplan?.data?.readingsPerMonth}</span>
                                </div>
                                </> : ''}
                                
                        {careplan ? <>
                                    <div className="patient-profile-data-div mt-2">
                                        <p style={{fontSize: 14}} className="text-center mt-2">Remaining : </p>
                                        
                                        <span className="check-icon mt-2">{ReadingsperMonth - readingsThisMonth}</span>
                                    </div>
                                </> : ''}


                        <div className="patient-profile-data-div mt-2">
                            <p style={{fontSize: 14}} className="text-center mt-2">Initial Month : </p>
                            <span className="check-icon mt-2">{patient?.initialsetup ? patient?.initialsetup : 'N/A'}</span>
                        </div>   

                        <br />

                        <div className="card consentCard pl-3 pr-3">
                            <small className="title mt-3">RPM Careplan & Consent</small>
                            <hr />
                            <Tabs
                                id="justify-tab-example"
                                className="mb-3 mt-3"
                                >
                                <Tab eventKey="home" title="Careplan">
                                    <PatientRPMCareplan patientId={patient?._id}/>
                                </Tab>
                                <Tab eventKey="profile" title="Consent">
                                    <PatientRPMConsent patientId={patient?._id} isNurse={false} />
                                </Tab>
                            </Tabs>
                        </div>
                        
                        <br />
                        <div className="card">
                        <div className="card monthly-target-card p-2">
                            <span style={{color: '#FFF'}}>RPM Monthly Trgt ( {new Date().toLocaleString('en-us',{month:'short', year:'numeric'})} )</span>
                            <hr className="white-hr"/>
                                <RPMMinutesProgress patientId={patientid} />
                            </div>
                        </div>

                        </div>
                            
                        </div>
                    </div>
                    <br />   
                                
                        {/* Patient Telemetary Data */}
                        {deviceData && deviceData.length > 0 ? <Fragment>
                        <br/><br/>
                        <div className="col-md-3">
                            <h5 className="pt-2 mt-2">Telemetary Data <span style={{ color: '#004aad'}}> ( {Count} ) </span></h5>
                        </div>

                        <div className="row-display patient-profile-col-heading" style={{ 
                                padding: 10,
                                borderRadius: '10px'
                                }}> 
                            
                            <div style={{width: '30%'}}>
                            <label>To: </label>
                              <input 
                                type="date"
                                value={startDate}
                                onChange={e => setStartDate(e.target.value)}
                                max={moment().format("YYYY-MM-DD")} 
                                className="form-control"
                                />
                            </div>
                            
                            &nbsp;&nbsp;
                            <div style={{width: '30%'}}>                            
                            <label>From: </label>
                              <input 
                                type="date"
                                value={endDate}
                                onChange={e => setEndDate(e.target.value)}
                                max={moment().format("YYYY-MM-DD")} 
                                className="form-control"
                             />
                             </div>
                            
                             &nbsp;&nbsp;
                             <div style={{width: '30%'}}>                            
                                <label>Sort By: </label>
                                    <select 
                                    value={sort}
                                    onChange={e => sortBy(e.target.value)}
                                    className="form-control">
                                        <option value="-1">Descending (last to first)</option>
                                        <option value="1">Ascending (first to last)</option>
                                    </select>
                             </div>
                            
                            &nbsp;&nbsp;
                             <div> 
                                 <label>Search</label>
                                    <button 
                                        className="btn add-staff-btn"
                                        onClick={sortPatientTelemetaryData}>Search
                                    </button>    
                            </div>

                            <div> 
                                 <label>Reset</label>
                                    <button
                                        onClick={refreshHandler} 
                                        className="btn add-staff-btn">Reset
                                    </button>    
                            </div>
                            </div>

                        <br /><br />


                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <Row>
                                <Col sm={9}>
                                    <Nav variant="pills" className="flex-row">
                                        <Nav.Item style={{cursor: 'pointer'}}>
                                        <Nav.Link eventKey="first">BP Monitor Data</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item style={{cursor: 'pointer'}}>
                                        <Nav.Link eventKey="second">Weight Scale Monitor</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>

                        <Col sm={3}>        
                        {/* Accordion for graphical representation */}
                        <div className="container graphWrapper">
                        <button className="btn btn-sm btn-outline-primary" onClick={() => setAccordion(accordion => !accordion)}>
                            Show Graphically &nbsp;&nbsp;&nbsp;
                            <i className={accordion ? `bx bx-minus` : `bx bx-plus`}></i>
                        </button>
                        </div>
                        </Col>
                        {/* Accordion for graphical representation ends here */}
                        <div className="container graphWrapper">            
                            {accordion === true ? <div className="panel">
                                <PatientProfileGraph healthData={deviceData} />
                            </div> : ""}
                        </div>

                        <Col sm={12}>
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                {deviceData && deviceData.filter(healthdata => healthdata?.deviceId?.deviceType === 'bp').map((devicedata, index) => (
                                    <div key={index}>
                                        <CuffTelemetaryData healthData={devicedata} count={Count} readingsPerPage={readingPerPage} currentPage={currentPage} isAdmin={false} />
                                    </div>
                                ))}

                                    {/* Pagination */}
                                    {readingPerPage <= Count && (
                                        <div className="d-flex justify-content-center mt-5"> 
                                        <Pagination activePage={currentPage} 
                                        itemsCountPerPage={readingPerPage} 
                                        totalItemsCount = {Count}
                                        onChange={setCurrentPageNumber} 
                                        nextPageText = {'⟩'}
                                        prevPageText = {'⟨'}
                                        firstPageText = {'«'}
                                        lastPageText = {'»'}
                                        itemClass='page-item'
                                        linkClass="page-link"
                                        />           
                                    </div>
                                    )} 
                                    </Tab.Pane>
        

                                        <Tab.Pane eventKey="second">
                                        {deviceData && deviceData.filter(healthdata => healthdata?.deviceId?.deviceType === 'weight').map((devicedata, index) => (
                                            <div key={index}>
                                                <WeightTelemetaryData healthData={devicedata} count={Count} isAdmin={false} />
                                            </div>
                                        ))}
                                        </Tab.Pane>
                                    </Tab.Content>
                                    </Col>
                                  </Row>
                                </Tab.Container>
                                </Fragment> : <small className="text-center" style={{color: 'gray', marginLeft: '350px'}}>No telemetary data found <button className="btn btn-link" onClick={refreshHandler}>Refresh List</button></small>}
                                </Fragment> }
                            </div>

                            </div>
                           </div>
                         </Fragment> 
                         }
                </section>
        </Fragment>
    )
}

export default StaffPatientProfile
