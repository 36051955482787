// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client'
import App from './App';
import { Provider } from 'react-redux';
import store from './store';
import { positions, transitions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';


const options = {
  position: positions.BOTTOM_RIGHT,
  timeout: 5000,
  offset: '30px',
  transition: transitions.FADE
}

createRoot(document.getElementById('root')).render(<Provider store={store}>
  <AlertProvider template={AlertTemplate} {...options}>
    <App />   
  </AlertProvider>
</Provider>)


// ReactDOM.render(
//   <Provider store={store}>
//     <AlertProvider template={AlertTemplate} {...options}>
//       <App />   
//     </AlertProvider>
//   </Provider>,
//   document.getElementById('root')
// );
