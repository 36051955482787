import { NavLink } from 'react-router-dom';
import TDW_logo from '../../assets/Images/official_logo.png';


const Sidebar = () => {

    return (
        <>
            <div className="sidebar">
            <div className="logo-details">
                <img src={TDW_logo} className="logoImg" alt="logo" />
            </div>

            <ul className="nav-links">
                <li className="nav_link">
                    <NavLink key="admindashboard" to="/adminDashboard" activeClassName="link-name-active">
                        <i className="fas fa-home" style={{color: '#FEFFFF', fontSize: '24px'}}></i>
                        <span className="link_name">My Dashboard</span>
                    </NavLink>
                </li>
                
                <li className="nav_link">
                    <NavLink key="rpmPatients" to="/patients" activeClassName="link-name-active">
                    <i className="fas fa-user-injured" style={{color: '#FEFFFF', fontSize: '24px'}}></i>
                        <span className="link_name">RPM Patients</span>
                    </NavLink>
                </li>

                <li className="nav_link">
                    <NavLink key="ccmPatients" to="/CCM/Patients" 
                     activeClassName="link-name-active">
                    <i className="fas fa-hospital-user" style={{color: '#FEFFFF', fontSize: '24px'}}></i>
                        <span className="link_name">CCM Patients</span>
                    </NavLink>
                </li>

                <li className="nav_link">
                    <NavLink key="doctors" to="/doctors" activeClassName="link-name-active">
                    <i className="fas fa-user-md" style={{color: '#FEFFFF', fontSize: '24px'}}></i>
                        <span className="link_name">Manage Clinicians</span>
                    </NavLink>
                </li>

                <li className="nav_link">
                    <NavLink key="hr" to="/hrlist" activeClassName="link-name-active">
                    <i className="fas fa-users" style={{color: '#FEFFFF', fontSize: '24px'}}></i>
                    <span className="link_name">Nurse Resources</span>
                    </NavLink>
                </li>

                <li className="nav_link">
                    <NavLink key="inventory" to="/devices" activeClassName="link-name-active">
                    <i className="fas fa-weight" style={{color: '#FEFFFF', fontSize: '24px'}}></i>
                    <span className="link_name">Inventory Management</span>
                    </NavLink>
                </li>

                <li className="nav_link">
                    <NavLink key="'managelogs'" to="/logs" activeClassName="link-name-active">
                    <i className='fas fa-book-open' style={{color: '#FEFFFF', fontSize: '24px'}}></i>
                        <span className="link_name">Manage Logs</span>
                    </NavLink>
                </li>

                <li className="nav_link">
                    <NavLink key="credentials" to="/credentials" activeClassName="link-name-active">
                        <i className='fas fa-key' style={{color: '#FEFFFF', fontSize: '24px'}}></i>
                        <span className="link_name">Hard Reset Password</span>
                    </NavLink>
                </li>

                <hr className='white-hr'/>

                <li className="nav_link">
                    <NavLink key="timereport" to="/Admin/Report/RPM" activeClassName="link-name-active">
                        <i className='fas fa-history' style={{color: '#FEFFFF', fontSize: '24px'}}></i>
                        <span className="link_name">Time Report</span>
                    </NavLink>
                </li>

                <li className="nav_link">
                    <NavLink key="ksandasd" to="/report/financialReport" activeClassName="link-name-active">
                    <i className='fas fa-funnel-dollar' style={{color: '#FEFFFF', fontSize: '24px'}}></i>
                        <span className="link_name">Financial Report</span>
                    </NavLink>
                </li>

                <li className="nav_link">
                    <NavLink key="telemetarydatareport" to="/report/telemetary" activeClassName="link-name-active">
                    <i className='fas fa-heart' style={{color: '#FEFFFF', fontSize: '24px'}}></i>
                        <span className="link_name">Telemetary Data Report</span>
                    </NavLink>
                </li>

                <li className="nav_link">
                    <NavLink key="billingReport" to="/report/billing" activeClassName="link-name-active">
                    <i className='fas fa-file-invoice-dollar' style={{color: '#FEFFFF', fontSize: '24px'}}></i>
                        <span className="link_name">Billing Report</span>
                    </NavLink>
                </li>

                <li className="nav_link">
                    <NavLink key="nursesPerformance" to="/performance" activeClassName="link-name-active">
                    <i className='fas fa-chart-line' style={{color: '#FEFFFF', fontSize: '24px'}}></i>
                        <span className="link_name">Nurses Performance <span className="text-white newSpan">New</span></span>
                    </NavLink>
                </li>

                <li className="nav_link">
                    <NavLink key="ndjaiusd" to="/Admin/Report/InitialMonth" activeClassName="link-name-active">
                        <i className="fas fa-stream" style={{color: '#FEFFFF', fontSize: '24px'}}></i>
                        <span className="link_name">Initial Month Report</span>
                    </NavLink>
                </li>
            </ul>
        </div>    
        </>
    )
}

export default Sidebar;
