import { useEffect, useState } from 'react';
import MetaData from '../../layouts/MetaData';
import Sidebar from '../../components/AdminDashboard/Sidebar';
import TopBar from '../../components/AdminDashboard/TopBar';
import { updateDoctor, clearErrors, AddProfileImgDoctor } from '../../actions/adminActions';
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import { Formik, Form } from 'formik';
import {Link, useHistory} from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import defaultImg from "../../assets/Images/defaultUser.png";
import {HIDE_ALERT_MESSAGE} from '../../constants/Common';



const EditDoctor = (props) => {

    const dispatch = useDispatch();
    const alert = useAlert();
    const history = useHistory();

    let doctorInfo = props?.location?.state?.id;

    const {_id, firstname, lastname, gender, DOB, email, phone1, mobileNo, npinumber, licensenumber, specialization} = doctorInfo;

    const [docfirstname, setDocFirstName] = useState(firstname);
    const [doclastname, setDocLastName] = useState(lastname);
    const [docDOB, setDocDOB] = useState(DOB);
     const [docemail, setDocEmail] = useState(email);
    const [docgender, setDocGender] = useState(gender); 
    const [docphone1, setDocPhone1] = useState(phone1);
    const [docmobileno, setDocMobileNo] = useState(mobileNo);
    const [docnpi, setDocNpi] = useState(npinumber);
    const [doclicenseNumber, setDocLicenseNumber] = useState(licensenumber);
    const [docSpecialization, setDoctorSpecialization] = useState(specialization);

    const {message, error } = useSelector(state => state.common);

    const [imageError, setImageError] = useState(true);
    const [image, setImage] = useState({ preview: '', data: '' })
    const [showImageModal, setShowImageModal] = useState(false);

    const handleCloseImageModal = () => setShowImageModal(false);
    const handleImageModal = () => setShowImageModal(true);


    useEffect(() => {
        if(error){
            alert.error(error);

            dispatch({
                type: HIDE_ALERT_MESSAGE
            })
        }

        if(message){
            alert.success(message);
            props.history.push('/doctors');

            dispatch({
                type: HIDE_ALERT_MESSAGE
            })
        }
            
    }, [dispatch, error, message]);


    const updateHandler = () => {
        dispatch(updateDoctor(_id, docfirstname, doclastname, docDOB, docemail, docgender, docphone1, docmobileno, docnpi, doclicenseNumber, docSpecialization));
    }

    const handleFileChange = e => {
        e.preventDefault();
        
        if(e.target.files[0].length === 0){
            alert.error('No file selected');
            handleCloseImageModal(true);
        }

        if(e.target.files[0].size > 2e6){
            alert.error('Please upload image of less than 2 MB');
            handleCloseImageModal(true);
        };

        setImage(e.target.files[0]);
        setImageError(false);
      }

      const handleSubmit = () => {
        if(imageError === true) {
            alert.error('Select an Image to upload');
        }
        else {
            dispatch(AddProfileImgDoctor(image, doctorInfo?._id));
            setShowImageModal(false);
        }
    }


    return (
        <>
            <MetaData title="Edit Doctor"/>
                <Sidebar />    

                <section className="home-section">
                {/* TopBar */}
                <TopBar />

              <>
                <div className="shadow-lg p-3 mb-5 mr-4 ml-4 rounded-card">
                    <div className="home-content">
                        <div className="row-display">
                            <h5 className="title">Update Dr. {firstname} {lastname} </h5>
                            <div className="row-display">
                                    <button className="btn btn-secondary btn-sm" onClick={() => history.goBack()}>
                                        <i className='bx bx-arrow-back'></i>
                                    </button>
                                &nbsp;&nbsp;
                                <Link to="/adminDashboard">
                                    <button className="btn btn-secondary btn-sm">
                                        <i className='bx bxs-home'></i>
                                    </button>
                                </Link>
                            </div>
                        </div>
                        <hr />

                        <div className="row">
                            <div className="col-md-3 text-center mt-4">
                                {doctorInfo && doctorInfo?.profileImg ? <>
                                    <img src={`https://vitalsportal.com/v1/uploadFiles/${doctorInfo?.profileImg}`} 
                                    className="profile-img" alt="profileimg" 
                                />
                                </> :  <img src={defaultImg} className="profile-img" alt="profileimg" 
                                />}
                                
                                <button className="edit-pill rounded-btn" onClick={handleImageModal}>
                                    <i className="fas fa-camera"></i>
                                </button>
                            </div>

                            <div className="col-md-9">
                            <Formik initialValues={{ }}
                            enableReinitialize={true}
                            // validationSchema={validate}
                            onSubmit={updateHandler}
                        >

                        {/* { formik  => ( */}
                        <div>
                                    <Form>
                                    {/* First Name */}
                                    <div className="row">
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <label htmlFor="firstname" className="form-label mt-3">First Name</label>
                                            <input
                                                type="text" 
                                                name="firstname"
                                                className='form-control shadow-none'
                                                placeholder="First Name"
                                                value={docfirstname}
                                                onChange={(e) => setDocFirstName(e.target.value)} 
                                            />
                                    </div>

                                    {/* Last Name */}
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <label htmlFor="lastname" className="form-label mt-3">Last Name</label>
                                            <input
                                                type="text" 
                                                name="lastname"
                                                className='form-control shadow-none'
                                                placeholder="Last Name"
                                                value={doclastname}
                                                onChange={(e) => setDocLastName(e.target.value)} 
                                            />
                                    </div>

                                    {/* Email */}
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <label htmlFor="email" className="form-label mt-3">Email</label>
                                            <input
                                                type="email" 
                                                name="email"
                                                className='form-control shadow-none'
                                                placeholder="Email Address"
                                                value={docemail}
                                                onChange={(e) => setDocEmail(e.target.value)} 
                                            />
                                    </div>

                                    {/* Gender */}
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <label htmlFor="gender" className="form-label mt-3">Gender</label>
                                        <select
                                            label="Gender"
                                            name="gender"
                                            className="form-control"
                                            defaultValue={docgender}
                                            onChange={(e) => setDocGender(e.target.value)}
                                            >
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                        </select>
                                    </div>

                                    {/* DOB */}
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <label htmlFor="gender" className="form-label mt-3">DOB</label>
                                        <input
                                            type="date"
                                            label="Gender"
                                            name="gender"
                                            className="form-control"
                                            defaultValue={docDOB}
                                            onChange={(e) => setDocDOB(e.target.value)}
                                        />
                                    </div>

                                    {/* Phone1 */}
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <label htmlFor="phone1" className="form-label mt-3">Phone1</label>
                                        <input
                                            type="text"
                                            label="phone1"
                                            name="phone1"
                                            className="form-control"
                                            defaultValue={docphone1}
                                            onChange={(e) => setDocPhone1(e.target.value)}
                                        />
                                    </div>

                                    {/* Mobile No */}
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <label htmlFor="mobileno" className="form-label mt-3">Mobile No</label>
                                        <input
                                            type="text"
                                            label="mobileno"
                                            name="mobileno"
                                            className="form-control"
                                            defaultValue={docmobileno}
                                            onChange={(e) => setDocMobileNo(e.target.value)}
                                        />
                                    </div>

                                    {/* NPI Number */}
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <label htmlFor="npinumber" className="form-label mt-3">NPI</label>
                                        <input
                                            type="text"
                                            label="npinumber"
                                            name="npinumber"
                                            className="form-control"
                                            defaultValue={docnpi}
                                            onChange={(e) => setDocNpi(e.target.value)}
                                        />
                                    </div>

                                    {/* License Number */}
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <label htmlFor="licensenumber" className="form-label mt-3">License Number</label>
                                        <input
                                            type="text"
                                            label="licensenumber"
                                            name="licensenumber"
                                            className="form-control"
                                            defaultValue={doclicenseNumber}
                                            onChange={(e) => setDocLicenseNumber(e.target.value)}
                                        />
                                    </div>

                                    {/* Specialization */}
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <label htmlFor="licensenumber" className="form-label mt-3">Specialization</label>
                                        <input
                                            type="text"
                                            label="Specialization"
                                            name="specialization"
                                            className="form-control"
                                            defaultValue={docSpecialization}
                                            onChange={(e) => setDoctorSpecialization(e.target.value)}
                                        />
                                    </div>
                                </div> 

                                <br/>
                                {/* Buttons */}
                                <div className="row mr-3" style={{ float: 'right'}}>
                                    <button className="edit-pill ml-3" type="submit" >Update</button>
                                </div>

                                <br/><br/><br/>
                            </Form>
                            </div>
                            {/* )} */}
                        </Formik>  
                            </div>
                        </div>                    
                    </div>
                </div>
                </> 

                
                {/* Image Modal */}
                <Modal show={showImageModal}>
                    <Modal.Header>
                        <Modal.Title>Update Profile Image</Modal.Title>
                    </Modal.Header>
                    
                    <Modal.Body>
                    <label htmlFor="name">Choose your Image: </label>
                        <input 
                            type="file" 
                            name="image" 
                            onChange={handleFileChange} 
                            accept="image/jpeg, image/png, image/jpg" 
                        />
                        <br />

                        {/*...*/}
                    </Modal.Body>
                    
                    <Modal.Footer>
                        <button className="edit-pill" style={{backgroundColor: 'red'}} onClick={handleCloseImageModal}>Cancel</button>
                        <button className="edit-pill" onClick={handleSubmit}>Update</button>
                    </Modal.Footer>
                </Modal>

                </section>
        </>
    )
}

export default EditDoctor
