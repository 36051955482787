import React, { Fragment } from 'react'

const AboutUs = () => {
    return (
        <Fragment>
             <section className="container" id="about-us">
             <div className="col-md-12">
                <div className="row-display">
                        <div className="col-md-6">
                        <h2 className="display-5 about-us">About Us</h2>
                            <h5 className="telemedine-subheading">Core Solutions of Virtual Care</h5>
                            <br />
                            
                            <div className="about_us_points">
                                <p className="about-us-point">Remote Patient Monitoring</p>
                                <p className="about-us-point">Chronic Care Management</p>
                                <p className="about-us-point">Telemedicine</p>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="about-us-section"></div>
                        </div>
                    </div>

                    <div className="col-md-6">

                    </div>
                </div>        
                <div className="container">
                <br />
                

                </div>
            </section>
        </Fragment>
    )
}

export default AboutUs
