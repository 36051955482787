import {useState, useEffect} from 'react';
import nurseIcon from '../../assets/Images/woman.png';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getNursesProgressOnDashboard } from '../../actions/adminActions';


const NurseProgressComponent = () => {

    const dispatch = useDispatch();

    const [startDate, setStartDate] = useState(moment().clone().startOf('month').isoWeekday(8).format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().add(1, 'M').startOf('month').isoWeekday(8).format('YYYY-MM-DD'));
    const [consentCategory, setConsentCategory] = useState('CCM');

    const { nurseProgress } = useSelector(state => state.progress);

    useEffect(() => {
        dispatch(getNursesProgressOnDashboard(startDate, endDate, consentCategory));
    }, [dispatch]);

    const searchHandler = () => {
        dispatch(getNursesProgressOnDashboard(startDate, endDate, consentCategory));
    }



  return (
    <div>
         <div className="row-display">
                <div>    
                    <label className='form-label'>Start Date: </label>
                    <input type="date" className="form-control" name="startDate" value={startDate} onChange={e => setStartDate(e.target.value)} />
                </div>

                <div>
                    <label className='form-label'>End Date: </label>
                    <input type="date" className="form-control" name="endDate" value={endDate} onChange={e => setEndDate(e.target.value)} />   
                </div>
                
                <div className="form-label">
                    <label className='form-label'>Consent Type: </label>
                    <select 
                            className="form-control" 
                            name="category" 
                            value={consentCategory} 
                            onChange={e => setConsentCategory(e.target.value)}
                            // style={{width: '250px'}}
                            placeholder='Select Stats'
                        >
                            <option value="RPM">RPM Category</option>
                            <option value="CCM">CCM Category</option>
                    </select>
                </div>

                <div className="mt-4">
                    <button className="edit-pill" onClick={() => searchHandler()} >Search</button>
                </div>
                </div>
                <br />

                <b>Note: </b> <small>Total Minutes added by nurses between {moment(startDate).format("MM-DD-YYYY")} and {moment(endDate).format("MM-DD-YYYY")} </small>
                
                <div className="row mt-2">
                {nurseProgress.length > 0 ? <>
                    {nurseProgress && nurseProgress.sort((a, b) => b?.sum - a?.sum).map((nursesProgress, index) => (
                    <>
                     <div className="col-md-4 text-center" key={index}>
                        <div className={`card p-3 performanceCard ${index === 0 ? "activePerformanceCard" : index === 1 ? "justPerformingCard" : index === 2 ? "underPerformingCard" : "underPerformingCard"}`}>
                            <img src={nurseIcon} 
                                alt="nurseImg" 
                                width="90" 
                                height="90" 
                                className="roundedBorderImg centeredImg"/>
                            <br />
                            <small><b>{nursesProgress?._id?.firstname} {nursesProgress?._id?.lastname}</b></small>
                            
                            {nursesProgress?._id?.block === false ? <p>
                                <small style={{color: 'white'}}>Active</small>
                                </p> : <p>
                                <small style={{color: 'red'}}>Deactive</small>
                            </p> }

                            <small>
                                <span className="text-white">Total Mins Added</span> <br/>                   
                                <h3>{nursesProgress?.sum}</h3>                   
                            </small>
                        </div>
                    </div> 
                    </>
                ))}
                </> : <>
                <span>
                    <div className="col-md-12">
                        <small style={{color: 'red'}}>No Minutes added b/w this time span.</small>
                    </div>
                </span>
                </> }          
            </div>
    </div>
  )
}

export default NurseProgressComponent