import React, {useEffect, useState} from 'react';
import MetaData from '../../layouts/MetaData';
import {useAlert} from 'react-alert';
import { TIME_SUMMARY_REPORT_RESET } from '../../constants/adminConstants';
import Loader from '../../layouts/Loader';
import { useDispatch, useSelector} from 'react-redux';
import { getTimeSummaryReportByDoctor, getTimeSummaryReportforCCMByDrHr } from '../../actions/adminActions';
import ExportSummaryReportToCSV from '../../components/ExportSummaryReportToCSV';
import Sidebar from '../../components/Staff/Sidebar';
import TopBar from '../../components/Staff/TopBar';
import { Table } from 'react-bootstrap';

const DoctorTimeSummaryReport = () => {

  const alert = useAlert();
  const dispatch = useDispatch();
  const [month, setMonth] = useState('07');
  const [year, setYear] = useState('2023');
  const [category, setCategory] = useState('RPM');
  const [reportBy, setReportBy] = useState('doctor');

  const { user } = useSelector(state => state.auth);
  const {loading, timeSummaryReport} = useSelector(state => state.summaryReport);

  let hrId = '122372y81';

  useEffect(() => {

    dispatch({
      type: TIME_SUMMARY_REPORT_RESET
  })
    
}, [category]);



  const generateTimeSummaryByHR = () => {
    if(category === 'RPM') {
      dispatch(getTimeSummaryReportByDoctor(user._id, month, year, category));
    } else if(category === 'CCM') {
      dispatch(getTimeSummaryReportforCCMByDrHr(user._id, hrId, month, year, reportBy)); 
    }
  }

  const resetReport = () => {
    dispatch({
        type: TIME_SUMMARY_REPORT_RESET
    })
  }  


  return (
    <>
     <MetaData title="Time Summary Report" />
        <Sidebar />
            
            <section className="home-section">
                {/* TopBar */}  
                <TopBar />

        <>
            <div className="shadow-lg p-3 mb-5 mr-4 ml-4 bg-white rounded">        
                <div className="home-content">
                    <div className="container">
                            <h5 className="title">Time Summary Report </h5> 
                            <hr />
                            <span className="notes-header"><b>Note: </b> You can generate time summary report by selecting month and year.</span>

                            <div className="row cardWrapper">
                            <div className="col-md-3 mt-4">
                                <select 
                                  name="month" 
                                  className="form-control"
                                  value={month} 
                                  onChange={e => setMonth(e.target.value)}
                                  >
                                  <option value="01">January</option>
                                  <option value="02">February</option>
                                  <option value="03">March</option>
                                  <option value="04">April</option>
                                  <option value="05">May</option>
                                  <option value="06">June</option>
                                  <option value="07">July</option>
                                  <option value="08">August</option>
                                  <option value="09">September</option>
                                  <option value="10">October</option>
                                  <option value="11">November</option>
                                  <option value="12">December</option>
                                </select>
                            </div>

                            <div className="col-md-3 mt-4">
                                <select 
                                  name="month" 
                                  className="form-control"
                                  value={year} 
                                  onChange={e => setYear(e.target.value)}
                                  >
                                  <option value="2022">2022</option>
                                  <option value="2023">2023</option>
                                  <option value="2024">2024</option>
                                  <option value="2025">2025</option>
                                </select>              
                            </div>

                            <div className="col-md-3 mt-4">
                                <select 
                                  name="month" 
                                  className="form-control"
                                  value={category} 
                                  onChange={e => setCategory(e.target.value)}
                                  >
                                  <option value="RPM">RPM</option>
                                  <option value="CCM">CCM</option>
                                </select>              
                            </div>

                            <div className="col-md-3 mt-4">
                                <button className="submit-btn" onClick={generateTimeSummaryByHR}>Generate</button>
                            </div>
                            </div>

                        </div>
                  </div>

                  <br />
                  {timeSummaryReport && timeSummaryReport?.length > 0 ? <>
                    <div className="row-display">
                    <div className="col-md-9">

                    </div>
                    <button className="accordion" onClick={resetReport}><i className='bx bxs-file-pdf'></i> Reset</button> &nbsp;    
                    
                    <div>
                        <div
                            style={{ display: "none" }}// This make ComponentToPrint show   only while printing
                        > 
                        </div>
                        {/* <button className="accordion"><i className='bx bxs-file-pdf'></i> Download Excel</button> */}
                        <div className="col-md-2 col-lg-2">
                            <ExportSummaryReportToCSV csvData={timeSummaryReport} fileName={`TimeSummaryReport${category}.csv`} category={category} />
                        </div>
                    </div>
                </div>
              </> : null }

                  <br />
                {loading ? <Loader /> : <>
                {timeSummaryReport && timeSummaryReport?.length > 0 ? <>
                        <div className="row-display">
                          <div style={{ 
                              fontSize: '14px',
                              backgroundColor: 'gray',
                              color: '#FFF',
                              padding: '5px',
                              width: '180px',
                              borderRadius: '5px', 
                              textAlign: 'center',
                              height: '30px'
                          }}><span>{timeSummaryReport?.length}</span> record(s) found.
                          </div>
                          
                          <span><b>Report Preview</b></span>
                          
                          <div>
                            <span style={{color: '#9B111E'}}>
                              <small>
                                <i className='bx bxs-circle'></i> Shows reading {'<'} 16 | minutes {'<'} 20
                              </small>
                            </span> 
                            
                            <br />

                            <span style={{color: '#009150'}}>
                              <small>
                                <i className='bx bxs-circle'></i> Shows reading {'>'} 16 | minutes {'>'} 20
                                </small>
                              </span>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <br />
                        <Table striped hover bordered>
                            <thead align="center">
                                <tr>
                                <th>Sr.</th>
                                <th>Patient Name</th>
                                {category === 'RPM' ? <th>Total Readings Received </th> : null}
                                
                                <th>Total Minutes</th>
                                <th>CPT Codes</th>
                                <th>Month</th>
                                <th>Category</th>
                                </tr>

                                {timeSummaryReport && timeSummaryReport.map((summaryReport, index) => (
                                  <tr key={index}> 
                                    <td>{index + 1}</td>

                                    <td style={{wordWrap: 'break-word'}}>{summaryReport?.patientName}</td>

                                    {category === 'RPM' ? <>
                                      {summaryReport.totalReadings >= 16 ? <td style={{color: 'green', wordWrap: 'break-word',  fontWeight: 'bold'}}>{summaryReport?.totalReadings}</td> : <td style={{color: 'red', fontWeight: 'bold', wordWrap: 'break-word'}}>{summaryReport?.totalReadings}</td>}
                                    </> : null}                                    


                                    {/* CPT Codes */}
                                      {summaryReport?.totalMinutes < 20 ? <td style={{color: '#e3404f', fontWeight: 'bold'}}>
                                        {summaryReport?.totalMinutes} mins</td> : 
                                        <td style={{color: 'green', fontWeight: 'bold'}}>
                                          {summaryReport?.totalMinutes} mins
                                        </td>
                                      }


                                  {category === 'RPM' ? <>
                                  <td>
                                        {summaryReport.totalReadings >= 16 ? <text style={{fontSize: '14px', color: 'green', fontWeight: 'bold'}}> 99454</text> : <text style={{fontSize: '14px', color: 'red', fontWeight: 'bold'}}> 99454</text>}
                                        {summaryReport.totalMinutes >= 20 && summaryReport.totalMinutes < 40 ?  <p style={{fontSize: '14px', color: 'green', fontWeight: 'bold'}}>99457</p> : summaryReport.totalMinutes >= 40 ? <p style={{fontSize: '14px', color: 'green', fontWeight: 'bold'}}>99457 <br /> 99458</p> : <p style={{fontSize: '14px', color: 'red', fontWeight: 'bold'}}> 99457</p>}
                                    </td>
                                  </> : <>
                                  <td style={{color: 'green', fontWeight: 'bold'}}>
                                        {summaryReport?.totalMinutes >=20 && summaryReport?.totalMinutes < 40 ? '99490' : 
                                        summaryReport?.totalMinutes >= 40 && summaryReport?.totalMinutes < 60 ? '99490, 99439' : 
                                        summaryReport?.totalMinutes >= 60 && summaryReport?.totalMinutes < 90 ? '99487' : 
                                        summaryReport?.totalMinutes >= 90 ? '99487, 99489' : '--'  
                                        }
                                      </td>
                                  </>}
                                    

                                    <td style={{wordWrap: 'break-word'}}>{summaryReport?.Month}</td>
                                    <td>{category}</td>

                                  </tr>
                                ))}
                            </thead>
                            <tbody>
                                  
                            </tbody>
                          </Table>
                        </div>


                   </> : <>
                   <div className="text-center">
                       <b>No Result Found.</b>
                   </div>
                   </>}
                </>}

              </div>
        </>
      </section>
    
    </>
  )
}

export default DoctorTimeSummaryReport;