import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { logout } from '../../actions/authActions';
import { useSelector, useDispatch } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { getAdminNotifications } from '../../actions/adminActions';
import { getAdminProfile } from '../../actions/authActions';
import { useAlert } from 'react-alert';
import SessionTimeout from '../../components/SessionTimeout';
import adminImg from '../../assets/Images/892781.png';


const moment = require('moment-timezone');

const TopBar = () => {
    
    const dispatch = useDispatch();
    const alert = useAlert();

    const { notifications, error} = useSelector(state => state.adminNoti);
    const { user } = useSelector(state => state.auth);
    const {admin} = useSelector(state => state.adminsProfile);



    useEffect(() => {
        if(error) {
            alert.error(error);
        }

        dispatch(getAdminNotifications());
        dispatch(getAdminProfile(user?._id));

    }, [dispatch, error, alert]);

    useEffect(() => {
        let sidebar = document.querySelector(".sidebar");
            let sidebarBtn = document.querySelector(".sidebarBtn");
            
            sidebarBtn.onclick = function() {
                sidebar.classList.toggle("active")
    }
    });

    const logoutHandler = () => {
        dispatch(logout());
    }

    
    return (
        <>

        <SessionTimeout />

            <div className="navbar-top">
            <div className="row-display" style={{padding: '0px 10px'}}>
                <div className="sidebar-button">
                    <i className="bx bx-menu sidebarBtn"></i>
                    <h4 className="mt-2"><span style={{color: '#004aad'}}>
                        Admin Dashboard
                    <p style={{fontSize: '13px'}}>
                        Welcome Back
                    </p></span></h4>    
                </div>

                <div className="row-display">
                        <div className="notification-dropdown">
                        <Dropdown className="admin-topbar-dropdown">
                            <Dropdown.Toggle variant="link" id="dropdown-basic">

                            <i className='bx bxs-bell' style={{color: '#004aad', fontSize: '32px'}}></i>
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="admin-topbar-notification-dropdown">
                            {notifications && notifications?.data?.slice(0,50).map((noti, index) =>(
                                <Dropdown.Item key={index} className="drop-down-item-active">
                                    {noti?.noti_type === 'bp' ? <>
                                    <Link style={{textDecoration: 'none'}} to={{ pathname: "/patientProfile", state: {patientid: noti?.patientId}}}>
                                    {/* <Alert className="notification-text" variant={noti?.status === "High" ? "danger" : noti?.status === 'Elevated' ? "warning" : "info"}> */}
                                        <small>{noti?.textAny}</small>
                                        <div>
                                            <small style={{fontSize: '12px', color: 'gray', float:'right'}}>{moment(noti?.createdAt).tz("America/New_York").format("MM/DD/YYYY")}</small>
                                        </div>    
                                    {/* </Alert> */}
                                </Link></> : <>
                                <Link className="link" to="/adminDashboard">
                                        <div className="notification-text">
                                            <p>{noti?.textAny}</p>
                                                <small style={{fontSize: '12px', float:'right', marginTop: '-10px'}}>{moment(noti?.createdAt).tz("America/New_York").format("MM/DD/YYYY")}</small>
                                        </div>    
                                </Link>
                                </>    
                                }
                                
                                </Dropdown.Item>
                            ))}
                            </Dropdown.Menu>
                        </Dropdown>
                        </div>
                        {/* Notifications */}

                        <div className="dropdown dropdown-bg">
                        {admin && <>
                            {admin?.profileImg ? <div>
                            <img src={`https://vitalsportal.com/v1/uploadFiles/${admin?.profileImg}`} 
                            width="50" height="50" className="roundedBorderImg" />

                                &nbsp;&nbsp;<b>
                                    {admin?.name }
                                 &nbsp;&nbsp;<i className="fas fa-angle-down"></i></b> 
                        </div> :  
                        <div>
                            <img src={adminImg} width="50" height="50" className="roundedBorderImg" />
                            &nbsp;&nbsp;<b>
                                    {admin?.name }
                                 &nbsp;&nbsp;<i className="fas fa-angle-down"></i></b> 
                        </div>
                        }

                        <div className="dropdown-content">

                        <ul style={{listStyle: 'none'}}>
                            <li>
                                <Link className="dropdown-item" to="/me"><small>My Profile</small></Link>
                            </li>

                            {user?._id === '637b3619d625a251ff17005a' ? <>
                                <Link className="dropdown-item" to="/admins"><small>Admins List</small></Link>
                            </> : null}
                            

                            <li>
                                <Link className="dropdown-item" to="#" onClick={logoutHandler} style={{color: "red"}}><small>Sign out</small></Link>
                            </li>
                        </ul>      
                        </div>
                        </>}
                                                
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TopBar;
