    import React, {useEffect, useState} from 'react';
    import { useDispatch, useSelector } from 'react-redux';
    import { Modal, Button, Badge } from 'react-bootstrap';
    import { Formik, Form } from 'formik';
    import TextField from '../../components/Form/TextField';
    import { carePlanOfPatient, getTimeReport } from '../../actions/HRActions';
    import { getRemainingReadings} from '../../actions/adminActions';
    import moment from 'moment';
    import { ADDING_CARE_PLAN_RESET, ADDING_TIME_SPENT_RESET } from '../../constants/HRConstants';
    import hypertensionImg from '../../assets/Images/hypertension.png';
    import { useAlert } from 'react-alert';
    import { Link, useHistory } from 'react-router-dom';
    import AddTimeManually from '../../components/HR/AddTimeManually';
    import RPMMinutesProgress from '../../components/HR/RPMMinutesProgress';
    import CCMMinutesProgress from '../../components/HR/CCMMinutesProgress';
    import notFound from '../../assets/Images/notFound.png';
    import PatientCCMConsent from '../../components/Patient/PatientCCMConsent';
    import PatientCareplans from '../../components/Patient/PatientCareplans';
    import PatientRPMConsent from '../../components/Patient/PatientRPMConsent';
    import PatientRPMCareplan from '../../components/Patient/PatientRPMCareplan';
    import manIcon from '../../assets/Images/man.png';
    import womanIcon from '../../assets/Images/woman.png';

    const HRPatientInfo = ({patient, telemetaryReadings}) => {
    let nurseRole = '';

    const dispatch = useDispatch();   
    const alert = useAlert();
    const history = useHistory();    

    var check = moment(new Date(), 'YYYY/MM/DD');

    let month = check.format('M');
    month = Number(month)
    var year = check.format('YYYY');

    //  Careplan Fields
    const [carePlanShow ,setCarePlanShow] = useState(false);
    const [description, setDescription] = useState('');
    const [readingsInDay, setReadingsInDay] = useState('');
    const [readingsInNight, setReadingsInNight] = useState('');
    const [readingsPerMonth, setReadingsPerMonth] = useState('');
    const [fileName, setFileName] = useState({});
    const [minutesCategory, setMinutesCategory] = useState('RPM');

    const handleClose = () => setAddTimeShow(false);
    const handleShow = () => setAddTimeShow(true);
    const handleCarePlanModalClose = () => setCarePlanShow(false);
    const handleCarePlanModalShow = () => setCarePlanShow(true);
    const [addTimeShow, setAddTimeShow] = useState(false);

    const { careplan } = useSelector(state => state.careplan);
    const { totalTime } = useSelector(state => state.totalTimeSpent);
    // const { totalTimeinCCM } = useSelector(state => state.totalTimeSpentInCCM);
    const {isSuccessful, carePlanAdded, error: careplanerror } = useSelector(state => state.timeSpent);
    const { count } = useSelector(state => state.readingsCount);
    const {user} = useSelector(state => state.auth); 
    // const {  targets} = useSelector(state => state.target);

    let hrId = user?._id;
    let patientid = patient?._id;
    // let patientCCMNurse = patient?.assigned_ccm_nurse_id?._id;
    // let patientRPMNurse = patient?.assigned_hr_id?._id;
    // let IsCCM = patient?.isCCM;

    let startDate = moment().clone().startOf('month').format('YYYY-MM-DD');
    let endDate = moment().clone().endOf('month').format('YYYY-MM-DD');

        useEffect(() => {
        if(careplanerror){
            return alert.error(careplanerror);
        }

        
        if(isSuccessful) {
            setAddTimeShow(false);
            alert.success('Time added');
            dispatch({ type: ADDING_TIME_SPENT_RESET})
        }

        if(carePlanAdded){
        setCarePlanShow(false);
        alert.success('Care plan added');
        dispatch({ type: ADDING_CARE_PLAN_RESET });
        setDescription(''); 
        }

        // dispatch(hrTimeSpentOfCurrentMonth(patientid, hrId, startDate, endDate));
        // dispatch(hrTimeSpentOfCurrentMonthinCCMCategory(patientid, hrId, startDate, endDate));
        dispatch(getRemainingReadings(patientid));
        dispatch(getTimeReport(patientid, startDate, endDate));

        },[dispatch, careplanerror, isSuccessful, carePlanAdded]);



    let currMonthName  = moment().format('MMMM');
    let readingsThisMonth;
    let ReadingsperMonth; 

    readingsThisMonth = count;
    ReadingsperMonth = careplan?.data?.readingsPerMonth;

    const handleFileChange = e => {
        setFileName(e.target.files[0]);
    }

    const submitCarePlan = () => {
        if(description === '') {
            setCarePlanShow(false);
            alert.error('Description is required');
            return
        }

    dispatch(carePlanOfPatient(patientid, hrId, 
            description, 
            readingsPerMonth, 
            readingsInDay,
            readingsInNight, 
            fileName))
        }

        let filteredReadings = calcTotalReadings();

        function calcTotalReadings() {
        return telemetaryReadings && telemetaryReadings.filter(healthData => healthData?.deviceId?.deviceType === "bp").reduce((sum, a) =>  
            sum + 1, 0
        )
        }

    return (
        <div className="container">

        {/* New Profile look starts from here */}
        <div className="row-display p-2">
            
            <h5 style={{color: '#004aad'}} className="mt-2">
                <i className="bx bx-user"></i> <strong>&nbsp;{patient?.lastname}, {patient?.firstname} 's Details </strong>
            </h5>
            
            <div className="row-display">
            {careplan ? <>
                
            </> : <>
                <div>
                    <button className="edit-pill mt-2" onClick={handleCarePlanModalShow}>
                        <i className='bx bxs-paper-plane pr-2' ></i> 
                        Add RPM Careplan
                    </button>
                </div>    
            </>}

            <button className="edit-pill ml-3 mt-2" onClick={handleShow}>
                    <i className='bx bx-time pr-2'> </i>
                    Add Time Manually
            </button>

            &nbsp;&nbsp;     
            <button className="btn btn-secondary btn-sm mt-2" onClick={() => history.goBack()}>
                <i className='bx bx-arrow-back'></i>
            </button>

            &nbsp;&nbsp;     
            <Link to="/Nurse/Dashboard" className="btn btn-secondary btn-sm mt-2">
                <i className='bx bx-home'></i>
            </Link>
            </div>
        </div>
        <hr />


        <div className='row'>
            <div className='col-md-5'>
            <div className='row'>    
            <img src={patient?.gender === 'male' ? manIcon : womanIcon} 
                className="img-responsive" alt="patientProfie" width="80px" height="80px" 
            />
            &nbsp;&nbsp;&nbsp;
            <div>
                <p className="m-0 p-0"><strong>EHR-ID : </strong> {patient?.emrId || 'N/A'}</p>    
                <p className="m-0 p-0">
                    <strong>{patient?.lastname}, {patient?.firstname}</strong> &nbsp;&nbsp;
                    {patient?.block === true ? <small style={{color: 'red', fontWeight: 'bold'}}>Blocked</small> : 
                        <small style={{color: 'green', fontWeight: 'bold'}}>Active</small>
                    }
                </p>
                
                <p>
                    <div className='row-display'>
                        {moment().diff(moment(patient?.DOB).format("MM/DD/YYYY"), 'years')} years,
                        &nbsp; {patient?.gender}
                        &nbsp;&nbsp;&nbsp; <span className="text-muted">Consent Role : </span> &nbsp;{patient?.patientType}
                    </div>
                </p>           
                </div>     
                </div>
                </div>

                <div className='col-md-4'>
                    <strong>Disease(s)</strong>
                    <p className="shrink-text">
                    {patient?.diseases || '--'}
                    </p>
                </div>

                <div className='col-md-3'>
                    <strong>Address</strong>
                    <p className="shrink-text">
                    {patient?.address || null}, {patient?.city || null} - {patient?.line2 || null} - {patient?.city || null}, {patient?.state || null} - {patient?.zipCode || null}
                    </p>
                </div>
            </div>
            
            <hr className='white-hr'/>

            {/* Second Row */}
            <div className="container">            
                <div className='row-display'>
                    <div>
                        <strong>Date of birth:</strong>
                        <p>{moment(patient?.DOB).format('MM/DD/YYYY')}</p>
                    </div>

                        
                    <div className='pl-3'>
                        <strong>Assigned Doctor:</strong>
                        {patient?.assigned_doctor_id ? <>
                            <p>
                            {patient?.assigned_doctor_id?.lastname || null}, {patient?.assigned_doctor_id?.firstname || null}
                            </p>
                        </> : null}
                    </div> 

                    <div className='pl-3'>
                        <strong>Primary Phone:</strong>
                        <p>
                        {patient?.phone1 || '--'}
                        </p>
                    </div>

                    <div className='pl-3'>
                        <strong>Insurance Company:</strong>
                        <p>
                        {patient?.insurancecompany || '--'}
                        </p>
                    </div>

                    <div className='pl-3'>
                        <strong>Account Created Date:</strong>
                        <p>
                        {moment(patient?.createdAt).tz("America/New_York").format('MM/DD/YYYY hh:mm A') || '--'}
                        </p>
                    </div>    
                </div>
            </div>
            
            <hr className='white-hr'/>
            
            {/* Assigned Devices Section */}    
        <div className='row'>
            <div className='col-md-9'>   
             <div className='row'>
                <div className='col-6'>
                <h5 className='title'> Assigned Device (s) </h5><br/>   
                    {patient?.patientType === 'RPM' || patient?.patientType === 'Both' ? <>
                    {patient?.assigned_devices && patient?.assigned_devices.map((deviceass, index) => (
                    <div className='row' key={index}>
                        <img src={hypertensionImg} 
                            className="img-responsive" alt="patientProfie" width="120px" height="120px" 
                        />
                        <div className='ml-4'>
                            <p className='p-0 m-0'>
                                <strong>Device IMEI: </strong> {deviceass?.deviceObjectId?.imei || '---'}
                            </p>
                            <p className='p-0 m-0'>
                                <strong>Device Type: </strong> {deviceass?.deviceObjectId?.deviceType === 'bp' ? 
                                                    <Badge bg="primary text-white" style={{padding: '5px', letterSpacing: '2px'}}>cuff</Badge>
                                                        : deviceass?.deviceObjectId?.deviceType === 'spO2' ? <Badge bg="info text-white" style={{padding: '5px', letterSpacing: '2px'}}>Spo2</Badge> : 
                                                        <td><Badge bg="danger text-white" style={{padding: '5px', letterSpacing: '2px'}}>Weight</Badge></td>}
                            </p>
                            <p className='p-0 m-0'>
                                <strong>Assign Date: </strong> {deviceass?.deviceObjectId?.assignedTime || '---'}
                            </p>
                            
                            <p className='p-0 m-0'>
                                <strong>RPM Consent : </strong> {patient?.rpmconsent === true ? <span style={{color: 'green', fontWeight: 'bold'}}>
                                    Signed</span> : 
                                <span style={{color: 'red', fontWeight: 'bold'}}>Not Signed</span>}
                            </p>
                            <p><strong>Initial Month:</strong> {patient?.initialsetup}</p>
                        </div>
                    </div>
                    ))}
                    </>
                    : null }
                    </div>
                    
                    {/* Telemetary Readings */}
                     {/* Telemetary Readings  */}
                <div className='col-6'>
                    <h5 className='title'>Recent Readings</h5>
                    {telemetaryReadings && telemetaryReadings.length > 0 ? <>
                        {telemetaryReadings && telemetaryReadings.filter(healthData => healthData?.deviceId?.deviceType === "bp").slice(0,7).map((devicedata, index) => (
                            <div key={index} className="row-display text-center ml-4 mt-2" >
                                {devicedata.telemetaryData?.sys && devicedata.telemetaryData?.dia !== undefined ? <>
                                        <div>
                                            <strong>{index + 1}</strong>
                                        </div>
                                        
                                        <div> 
                                            {devicedata?.telemetaryData?.sys >= 139 || devicedata?.telemetaryData?.sys <= 110 ? 
                                            <span style={{color: 'red', fontWeight: 'bold'}}>{devicedata?.telemetaryData?.sys}</span> : 
                                            <span>{devicedata?.telemetaryData?.sys}</span>} 
                                            / 
                                            {devicedata?.telemetaryData?.dia >= 90 || devicedata?.telemetaryData?.dia <= 70 ? 
                                            <span style={{color: 'red', fontWeight: 'bold'}}>{devicedata?.telemetaryData?.dia}</span> : 
                                            <span>{devicedata?.telemetaryData?.dia}</span>} <small>mmHG</small>
                                        </div>

                                        <div>
                                        {moment(devicedata?.telemetaryData?.ts * 1000).tz("America/New_York").format("MM/DD/YYYY hh:mm z A")}
                                        </div>
                                        <br/>
                                </> : " "}
                            </div>
                            
                        ))}
                    </> : <div className="text-center">
                        <img src={notFound} style={{width: '40px', height: '40px'}} alt="not found" />
                            <p><small>No records found</small></p>
                        </div> 
                        }

                    {/* Avg Systolic and avg diastolic  */}
                    <br />
                    <div className="text-center">
                    <div className="row-display">
                                <div className="circularbox">
                                    <div className="percent">
                                    <svg stroke='#242582'>
                                        <circle cx="70" cy="70" r="40"></circle>
                                        <circle cx="70" cy="70" r="40"></circle>  
                                    </svg> 

                                        <div className="number">
                                        {telemetaryReadings && telemetaryReadings.filter(healthData => healthData?.deviceId?.deviceType === "bp").slice(0,10).reduce((total, devicedata) =>  
                                        (Number(total) + Number(devicedata?.telemetaryData?.sys) / filteredReadings).toFixed(), 0
                                        )}
                                        
                                        </div>
                                    </div>
                                    <h5 className="text">Avg. Systolic</h5>
                                </div>

                                <div className="circularbox">
                                    <div className="percent">
                                    <svg stroke='#5CDB95'>
                                            <circle cx="70" cy="70" r="40"></circle>
                                            <circle cx="70" cy="70" r="40"></circle>  
                                        </svg> 

                                        <div className="number">
                                        {telemetaryReadings && telemetaryReadings.filter(healthData => healthData?.deviceId?.deviceType === "bp").reduce((total, devicedata) =>  
                                        (Number(total) + Number(devicedata?.telemetaryData?.dia) / filteredReadings).toFixed(), 0
                                        )} 
                                        </div>
                                    </div>
                                    <h5 className="text">Avg. Diastolic</h5>
                                </div>

                                <div className="circularbox">
                                    <div className="percent">
                                    <svg stroke='green'>
                                            <circle cx="70" cy="70" r="40"></circle>
                                            <circle cx="70" cy="70" r="40"></circle>  
                                        </svg> 

                                        <div className="number">
                                        {telemetaryReadings && telemetaryReadings.filter(healthData => healthData?.deviceId?.deviceType === "bp").reduce((total, devicedata) =>  
                                        (Number(total) + Number(devicedata?.telemetaryData?.pul) / filteredReadings).toFixed(), 0
                                        )} 
                                        </div>
                                    </div>
                                    <h5 className="text">Avg. Pulse</h5>
                                </div>
                                </div> 
                            </div>
                        </div>

                    </div>
                </div>

                {/* Monthly Targets */}
            <div className='col-md-3'>
            <div className="card monthly-target-card p-2">    
                <h5 className="text-white mt-2">Monthly Target</h5> 
                    <p>
                        <small style={{color: '#fff'}}>For the month of {new Date().toLocaleString('en-us',{month:'long', year:'numeric'})}
                        </small>
                    </p>
                

                {patient?.patientType === 'Both' ? <>
                    <select className="form-control" value={minutesCategory} onChange={e => setMinutesCategory(e.target.value)}>
                        <option value="RPM">RPM Category</option>
                        <option value="CCM">CCM Category</option>
                    </select>
                        <br />
                    </> : null}                                    
                
                            
                        {patient?.patientType === 'RPM' ? <RPMMinutesProgress patientId={patientid} />
                            : patient?.patientType === 'CCM' ?  <CCMMinutesProgress patientId={patientid} />
                            : patient?.patientType === 'Both' ? 
                                <>
                                {minutesCategory === 'RPM' ? <RPMMinutesProgress patientId={patientid} /> 
                                : 
                                <CCMMinutesProgress patientId={patientid} />
                                }
                                </>
                            : null }
                        </div>
                    </div>
                </div>

                <hr className='white-hr'/>



                <div className="row">
                    <div className="col-md-3">
                        <PatientRPMCareplan patientId={patient?._id} isNurse={true}/>
                    </div>

                    <div className="col-md-3">
                        <PatientRPMConsent patientId={patient?._id} isNurse={true} />
                    </div>

                    <div className="col-md-3">
                        <PatientCareplans patientId={patient?._id} isNurse={true}/>
                    </div>

                    <div className="col-md-3">
                        <PatientCCMConsent patientId={patient?._id} isNurse={true} />
                    </div>
                </div>                
                <hr className='white-hr' />                
            
            {/* Careplan Modal */}
            <Modal show={carePlanShow} onHide={handleCarePlanModalClose}>
            <Modal.Header >
                <Modal.Title>Add Careplan</Modal.Title> 
                <Button variant="danger" onClick={handleCarePlanModalClose}><i className='bx bx-x'></i></Button>
            </Modal.Header>

                <Modal.Body>
                    <Formik initialValues={{
                        description: '', 
                        readingsPerMonth:'',
                        readingsPerDay: '',
                        fileName: ''

                    }}
                    onSubmit={values => {
                        submitCarePlan(values)
                    }}
                    >
                    { formik => (
                        <div>
                            <Form>                            
                                <TextField 
                                    label="Readings / mo" 
                                    name="readingsPerMonth" 
                                    type="number" 
                                    placeholder="Readings / mo"
                                    value={readingsPerMonth} 
                                    onChange={(e) => setReadingsPerMonth(e.target.value)}
                                />

                                <TextField 
                                    label="Reading in Morning" 
                                    name="readingsInSlot1" 
                                    type="number" 
                                    placeholder="Readings / day"
                                    value={readingsInDay} 
                                    onChange={(e) => setReadingsInDay(e.target.value)}
                                />

                                <TextField 
                                    label="Reading in Night" 
                                    name="readingsInSlot2" 
                                    type="number" 
                                    placeholder="Readings / day"
                                    value={readingsInNight} 
                                    onChange={(e) => setReadingsInNight(e.target.value)}
                                />

                            <label htmlFor="description" className="form-label mt-3">Description</label>
                                <textarea 
                                    label="Description" 
                                    name="description"
                                    className="form-control"
                                    rows="4"	
                                    value={description} 
                                    onChange={(e) => setDescription(e.target.value)}
                                    placeholder="Type description here .... "
                            />

                                <input 
                                    label="File" 
                                    name="fileName" 
                                    type="file"
                                    accept=".doc, .docx, .pdf"
                                    className="form-control"
                                    style={{border: 'none'}} 
                                    onChange={handleFileChange}
                                />
                            <br/>
                            <div className="row-class" style={{justifyContent: 'space-between'}}>
                                <button className="reset-btn ml-3" type="submit"> Submit</button>
                            </div>
                            </Form>
                        </div>
                    )}   
                    </Formik>   
                </Modal.Body>
            </Modal>
            {/* Careplan Modal ends here*/}

            {/* Time spent Modal */}
            <Modal size="md" show={addTimeShow} onHide={handleClose}>
            <Modal.Header >
                <Modal.Title>Add Time Manually</Modal.Title> 
                <Button variant="danger" onClick={handleClose}><i className='bx bx-x'></i></Button>
            </Modal.Header>

                <Modal.Body>
                    <AddTimeManually 
                        totalTime={totalTime} 
                        hrId={hrId} 
                        patientId={patientid} 
                        patientType={patient?.patientType}
                        />   
                </Modal.Body>
            </Modal>
            {/* Time spent Modal ended here */}
        </div>
    )
    }

    export default HRPatientInfo