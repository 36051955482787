import React, {useEffect, useState} from 'react';
import { Button, Badge } from 'react-bootstrap';
import { removeAssignedDevice} from '../../actions/adminActions';
import { useDispatch, useSelector} from 'react-redux';
import { removePatientsDoctor, patientProfile, removePatientsHR, removeCCMNurse } from '../../actions/adminActions';
import { Link, useHistory } from 'react-router-dom';
import RPMMinutesProgress from '../../components/HR/RPMMinutesProgress';
import CCMMinutesProgress from '../../components/HR/CCMMinutesProgress';
import { useAlert } from 'react-alert';
import PatientCCMConsent from '../../components/Patient/PatientCCMConsent';
import PatientCareplans from '../../components/Patient/PatientCareplans';
import {CCM_CONSENT_RESET} from '../../constants/HRConstants';
import { CCM_CAREPLAN_RESET } from '../../constants/HRConstants';
import manIcon from '../../assets/Images/man.png';
import womanIcon from '../../assets/Images/woman.png';
import hypertensionImg from '../../assets/Images/hypertension.png';
import PatientRPMConsent from '../../components/Patient/PatientRPMConsent';
import PatientRPMCareplan from '../../components/Patient/PatientRPMCareplan';
import NotFound from '../../components/NotFound';


const moment = require('moment-timezone');

const PatientInfo = ({patient,patientid, telemetaryReadings, batterySignal}) => {

const dispatch = useDispatch();
const history = useHistory();
const alert = useAlert();

// let strtDate = '2023/05/01';
// let edDate = '2023/05/31'


const [minutesCategory, setMinutesCategory] = useState('CCM');
const {error, message} = useSelector(state => state.common);


const removeAssignDevice = (device, patientid) => {
    var confirmation = window.confirm(`Please confirm to remove device from patient ?`);
        if(confirmation === true){
            dispatch(removeAssignedDevice(device, patientid));
            return;
        }
    }


useEffect(() => {
    if(message){
        alert.success(message);
        dispatch(patientProfile(patientid));
        
        dispatch({
            type: CCM_CONSENT_RESET
        });
        dispatch({
            type: CCM_CAREPLAN_RESET
        });
    } 

    if(error){
        alert.error(error);
        dispatch({
            type: CCM_CONSENT_RESET
        });
        dispatch({
            type: CCM_CAREPLAN_RESET
        });
    }

}, [dispatch, message, error])


let filteredReadings = calcTotalReadings();

function calcTotalReadings() {
    return telemetaryReadings && telemetaryReadings.filter(healthData => healthData?.deviceId?.deviceType === "bp").reduce((sum, a) =>  
    sum + 1, 0
    )
}

const removeDoctor = (doctorId) => {
    var confirmation = window.confirm('Are you sure you want to remove Doctor from' + 
    ` ${patient?.lastname} ` + `${patient?.firstname}` + '?' );

    if(confirmation){
        dispatch(removePatientsDoctor(patientid, doctorId));
    }
}

const removeRPMNurse = () => {
    var confirmation = window.confirm('Are you sure you want to remove RPM Nurse from' + 
    ` ${patient?.lastname} ` + `${patient?.firstname}` + '?' );

    if(confirmation){
        dispatch(removePatientsHR(patientid));
    }
}

const RemovePatientCCMNurse = () => {
    var confirmation = window.confirm('Are you sure you want to remove CCM Nurse from' + 
    ` ${patient?.lastname} ` + `${patient?.firstname}` + '?' );

    if(confirmation){
        dispatch(removeCCMNurse(patientid));
    }
}

return (
    <>
        <div className="row-display">
            <h5 style={{color: '#004aad'}}>
                <i className="bx bx-user"></i> <strong>&nbsp;{patient?.lastname}, {patient?.firstname} 's Details </strong>
            </h5>
            
            <div>
                <Button className="btn btn-secondary btn-sm" onClick={() => history.goBack()}><i className='bx bx-arrow-back' ></i></Button> &nbsp;
                <Link to="/adminDashboard" className="btn btn-secondary btn-sm"><i className='bx bx-home'></i></Link> &nbsp;
                <Link className="edit-pill" to={{pathname: '/Patients/Edit', state: {patientDetails: patient}}}>
                    Edit Profile
                </Link>
            </div>
        </div>
        <br/>

        <div className='row'>
            <div className='col-md-5'>
            <div className='row'>    
            <img src={patient?.gender === 'male' ? manIcon : womanIcon} 
                className="img-responsive" alt="patientProfie" width="80px" height="80px" 
            />
            &nbsp;&nbsp;&nbsp;
            <div>
                <p className="m-0 p-0"><strong>EHR-ID : </strong> {patient?.emrId || 'N/A'}</p>    
                <p className="m-0 p-0">
                    <strong>{patient?.lastname}, {patient?.firstname}</strong> &nbsp;&nbsp;
                    {patient?.block === true ? <small style={{color: 'red', fontWeight: 'bold'}}>Blocked</small> : 
                        <small style={{color: 'green', fontWeight: 'bold'}}>Active</small>
                    }
                </p>

                {patient?.email ? <p className='m-0 p-0'><small>{patient?.email}</small></p> : null}
                
                <div className='row-display'>
                    {moment().diff(moment(patient?.DOB).format("MM/DD/YYYY"), 'years')} years,
                    &nbsp; {patient?.gender}
                    &nbsp;&nbsp;&nbsp; <span>Consent Role : </span> &nbsp;{patient?.patientType}
                </div>
                           
              </div>     
             </div>
            </div>

            <div className='col-md-4'>
                <strong>Disease(s)</strong>
                <p className="shrink-text">
                {patient?.diseases || '--'}
                </p>
            </div>

            <div className='col-md-3'>
                <strong>Address</strong>
                <p className="shrink-text">
                {patient?.address || null}, {patient?.city || null} - {patient?.line2 || null} - {patient?.city || null}, {patient?.state || null} - {patient?.zipCode || null}
                </p>
            </div>
        </div>
        
        <hr className='white-hr'/>

        <div className="container">            
        <div className='row-display'>
                    <div>
                        <strong>Date of birth:</strong>
                        <p>{moment(patient?.DOB).format('MM/DD/YYYY')}</p>
                    </div>

                        
                    <div className='pl-3'>
                        <strong>Assigned Doctor:</strong>
                        {patient?.assigned_doctor_id ? <>
                            <p>
                            <Link to="/patientProfile" className='link'>{patient?.assigned_doctor_id?.lastname || null}, {patient?.assigned_doctor_id?.firstname || null}
                            </Link>
                            </p>
                            <button className="edit-pill" 
                                onClick={() => removeDoctor(patient?._id, patient?.assigned_doctor_id?._id)}>
                                <i className='bx bxs-user-minus'></i> Remove Doctor
                            </button>
                        </> : null}
                    </div> 

                    <div className='pl-3'>
                        <strong>Primary Phone:</strong>
                        <p>
                        {patient?.phone1 || '--'}
                        </p>
                    </div>

                    <div className='pl-3'>
                        <strong>Insurance Company:</strong>
                        <p>
                        {patient?.insurancecompany || '--'}
                        </p>
                    </div>

                    <div className='pl-3'>
                        <strong>Account Created Date:</strong>
                        <p>
                        {moment(patient?.createdAt).tz("America/New_York").format('MM/DD/YYYY hh:mm A') || '--'}
                        </p>
                    </div>    
                </div>
            </div>
        
        <br />
        <div className='row pl-3'>
            <div>
                <strong>Assigned RPM Nurse:</strong>
                {patient?.assigned_hr_id ? <>
                    <p>
                        {patient?.assigned_hr_id?.lastname || null}, {patient?.assigned_hr_id?.firstname || null}
                    </p>
                    <button className="edit-pill" 
                        onClick={() => removeRPMNurse()}>
                        <i className='bx bxs-user-minus'></i> Remove RPM Nurse
                    </button>
                </> : <p style={{color: 'red'}}>Not Assigned</p>}
            </div>

            <div className='pl-5'>
                <strong>Assigned CCM Nurse:</strong>
                {patient?.assigned_ccm_nurse_id ? <> 
                    <p>
                        {patient?.assigned_ccm_nurse_id?.lastname}, {patient?.assigned_ccm_nurse_id?.firstname}
                    </p>

                    <button className="edit-pill" 
                        onClick={() => RemovePatientCCMNurse()}>
                        <i className='bx bxs-user-minus'></i> Remove CCM Nurse
                    </button>
                </> : <p style={{color: 'red'}}>Not Assigned</p> }
            </div>
        </div>

        <hr />                    


        {/* Assigned Devices Section */}    
        <div className='row'>
            <div className='col-md-9'>   
             <div className='row'>
                <div className='col-6'>
                <strong> Assigned Device (s) </strong>   
                <br /><br />
                    {patient?.assigned_devices?.length > 0 ? <>
                    {patient?.assigned_devices && patient?.assigned_devices.map((deviceass, index) => (
                    <div className='row' key={index}>
                        <br/>
                        <img src={hypertensionImg} 
                            className="img-responsive" alt="patientProfie" width="120px" height="120px" 
                        />
                        <div className='ml-4'>
                            <p className='p-0 m-0'>
                                <strong>Device IMEI: </strong> {deviceass?.deviceObjectId?.imei || '---'}
                            </p>
                            <p className='p-0 m-0'>
                                <strong>Device Type: </strong> {deviceass?.deviceObjectId?.deviceType === 'bp' ? 
                                                    <Badge bg="primary text-white" style={{padding: '5px', letterSpacing: '2px'}}>cuff</Badge>
                                                        : deviceass?.deviceObjectId?.deviceType === 'spO2' ? <Badge bg="info text-white" style={{padding: '5px', letterSpacing: '2px'}}>Spo2</Badge> : 
                                                        <td><Badge bg="danger text-white" style={{padding: '5px', letterSpacing: '2px'}}>Weight</Badge></td>}
                            </p>
                            <p className='p-0 m-0'>
                                <strong>Assign Date: </strong> {deviceass?.deviceObjectId?.assignedTime || '---'}
                            </p>
                            <p className='p-0 m-0'>
                            {batterySignal >= 0 && batterySignal <= 10 ? 
                                <small style={{color: 'red', fontWeight: 'bold'}}>Weak</small> : batterySignal > 10 && batterySignal <= 20 ? 
                                <small style={{color: 'orange', fontWeight: 'bold'}}>Medium</small> : batterySignal > 20 ? <small style={{color: 'green', fontWeight: 'bold'}}>Strong</small> : '--'}
                            </p>
                            <p className='p-0 m-0'>
                                <strong>RPM Consent : </strong> {patient?.rpmconsent === true ? <span style={{color: 'green', fontWeight: 'bold'}}>
                                                            Signed</span> : 
                                                            <span style={{color: 'red', fontWeight: 'bold'}}>Not Signed</span>}
                            </p>
                            <p><strong>Initial Month:</strong> {patient?.initialsetup}</p>

                            <div className="row mt-3">
                                <button className="edit-pill ml-2" onClick={() => {navigator.clipboard.writeText(deviceass?.deviceObjectId?.imei); alert.success('IMEI COPIED')}}> 
                                    <i className='bx bx-edit'></i>
                                </button>

                                <Link to={{ pathname:"/devices"}} className="edit-pill ml-2">
                                    <i className='bx bx-detail'></i>
                                </Link>

                                <button className="edit-pill ml-2" style={{backgroundColor: '#C82333', color: '#FFF'}} onClick={() => removeAssignDevice(deviceass, patientid)}>
                                    Remove
                                </button>
                            </div>

                        </div>
                    </div>
                    ))}
                    </>
                    : <NotFound title="No Device(s) assigned yet."/> }
                    </div>
                {/* Telemetary Readings  */}
                <div className='col-6'>
                    <strong>Recent Readings</strong>
                    {telemetaryReadings && telemetaryReadings.length > 0 ? <>
                        {telemetaryReadings && telemetaryReadings.filter(healthData => healthData?.deviceId?.deviceType === "bp").slice(0,7).map((devicedata, index) => (
                            <div key={index} className="row-display text-center ml-4 mt-2" >
                                {devicedata.telemetaryData?.sys && devicedata.telemetaryData?.dia !== undefined ? <>
                                        <div>
                                            <strong>{index + 1}</strong>
                                        </div>
                                        
                                        <div> 
                                            {devicedata?.telemetaryData?.sys >= 139 || devicedata?.telemetaryData?.sys <= 110 ? 
                                            <span style={{color: 'red', fontWeight: 'bold'}}>{devicedata?.telemetaryData?.sys}</span> : 
                                            <span>{devicedata?.telemetaryData?.sys}</span>} 
                                            / 
                                            {devicedata?.telemetaryData?.dia >= 90 || devicedata?.telemetaryData?.dia <= 70 ? 
                                            <span style={{color: 'red', fontWeight: 'bold'}}>{devicedata?.telemetaryData?.dia}</span> : 
                                            <span>{devicedata?.telemetaryData?.dia}</span>} <small>mmHG</small>
                                        </div>

                                        <div>
                                        {moment(devicedata?.telemetaryData?.ts * 1000).tz("America/New_York").format("MM/DD/YYYY hh:mm z A")}
                                        </div>
                                        <br/>
                                </> : " "}
                            </div>   
                        ))}

                    {/* Avg Systolic and avg diastolic  */}
                    <br />
                    <div className="text-center">
                        <div className="row-display">
                                <div className="circularbox">
                                    <div className="percent">
                                    <svg stroke='#242582'>
                                        <circle cx="70" cy="70" r="40"></circle>
                                        <circle cx="70" cy="70" r="40"></circle>  
                                    </svg> 

                                        <div className="number">
                                        {telemetaryReadings && telemetaryReadings.filter(healthData => healthData?.deviceId?.deviceType === "bp").slice(0,10).reduce((total, devicedata) =>  
                                        (Number(total) + Number(devicedata?.telemetaryData?.sys) / filteredReadings).toFixed(), 0
                                        )}
                                        
                                        </div>
                                    </div>
                                    <h5 className="text">Avg. Systolic</h5>
                                </div>

                                <div className="circularbox">
                                    <div className="percent">
                                    <svg stroke='#5CDB95'>
                                            <circle cx="70" cy="70" r="40"></circle>
                                            <circle cx="70" cy="70" r="40"></circle>  
                                        </svg> 

                                        <div className="number">
                                        {telemetaryReadings && telemetaryReadings.filter(healthData => healthData?.deviceId?.deviceType === "bp").reduce((total, devicedata) =>  
                                        (Number(total) + Number(devicedata?.telemetaryData?.dia) / filteredReadings).toFixed(), 0
                                        )} 
                                        </div>
                                    </div>
                                    <h5 className="text">Avg. Diastolic</h5>
                                </div>

                                <div className="circularbox">
                                    <div className="percent">
                                    <svg stroke='green'>
                                            <circle cx="70" cy="70" r="40"></circle>
                                            <circle cx="70" cy="70" r="40"></circle>  
                                        </svg> 

                                        <div className="number">
                                        {telemetaryReadings && telemetaryReadings.filter(healthData => healthData?.deviceId?.deviceType === "bp").reduce((total, devicedata) =>  
                                        (Number(total) + Number(devicedata?.telemetaryData?.pul) / filteredReadings).toFixed(), 0
                                        )} 
                                        </div>
                                    </div>
                                    <h5 className="text">Avg. Pulse</h5>
                                </div>
                                </div> 
                    </div>

                    </> : <NotFound title="No record found"/>
                        }

                    
                </div>
             </div>
            </div>
            
            {/* Monthly Targets */}
            <div className='col-md-3'>
            <div className="card monthly-target-card p-2">    
                <h5 className="text-white mt-2">Monthly Target</h5> 
                    <p>
                        <small style={{color: '#fff'}}>For the month of {new Date().toLocaleString('en-us',{month:'long', year:'numeric'})}
                        </small>
                    </p>
                

                {patient?.patientType === 'Both' ? <>
                    <select className="form-control" value={minutesCategory} onChange={e => setMinutesCategory(e.target.value)}>
                        <option value="RPM">RPM Category</option>
                        <option value="CCM">CCM Category</option>
                    </select>
                    <br />
                </> : null}                                    
            
                        
                {patient?.patientType === 'RPM' ? <RPMMinutesProgress patientId={patientid} />
                        : patient?.patientType === 'CCM' ?  <CCMMinutesProgress patientId={patientid} />
                        : patient?.patientType === 'Both' ? 
                            <>
                            {minutesCategory === 'RPM' ? <RPMMinutesProgress patientId={patientid} /> 
                            : minutesCategory === 'CCM' ?
                            <CCMMinutesProgress patientId={patientid} />
                             : null }
                            </>
                        : null }
                    </div>
            </div>
        </div>
        
        <hr className="white-hr" />
        {/* Careplans & Consents */}
            <div className="row">
                <div className="col-md-3" style={{borderRightStyle: 'solid', borderRightWidth: '2px', borderRightColor: '#EAEAEA'}}>
                    <PatientRPMCareplan patientId={patient?._id}/>  
                </div>

                <div className="col-md-3" style={{borderRightStyle: 'solid', borderRightWidth: '2px', borderRightColor: '#EAEAEA'}}>
                    <PatientRPMConsent patientId={patient?._id} isNurse={false} />
                </div>

                <div className="col-md-3" style={{borderRightStyle: 'solid', borderRightWidth: '2px', borderRightColor: '#EAEAEA'}}>
                    <PatientCareplans patientId={patient?._id}/>
                </div>

                <div className="col-md-3" style={{borderRightStyle: 'solid', borderRightWidth: '2px', borderRightColor: '#EAEAEA'}}>
                    <PatientCCMConsent patientId={patient?._id} />
                </div>
            </div>
        </>
)
}

export default PatientInfo