import React, {useEffect} from 'react';
import { getPatientCarePlan } from '../../actions/HRActions';
import { useSelector, useDispatch } from 'react-redux';
import { useAlert } from 'react-alert';
import { Image } from 'react-bootstrap';
import moment from 'moment';
import doctorIcon from '../../assets/Images/doctorIcon.png';
import NotFound from '../../components/NotFound';


const PatientRPMCareplan = ({patientId}) => {

    const dispatch = useDispatch();
    const alert = useAlert();
    const { error, careplan } = useSelector(state => state.careplan);

    useEffect(() => {
    
        dispatch(getPatientCarePlan(patientId));
    
    },[dispatch]);

  return (
    
    <div>
      <h5 className="title">RPM Careplan</h5>
      <br />
        {careplan ? <div>
         <div className="row pl-3">   
            <small> Readings Per Month : </small> 
            <small className="pl-2">{careplan?.data?.readingsPerMonth}</small> <br/>
         </div>

         <div className="row pl-3">   
            <small> Readings in morning : </small> 
            <small className="pl-2">{careplan?.data?.readingsInSlot1}</small> 
         </div>

         <div className="row pl-3">   
            <small> Readings in evening : </small>
            <small className="pl-2">{careplan?.data?.readingsInSlot2}</small> <br/>
         </div>

         <div className="row-display-secondary">
              <div>
                <Image src={doctorIcon} style={{width: '60px', height: '60px'}} />
              </div>

              <div className="pl-2">
                <small><i>Added By:</i></small><br/>
                  <b>{careplan?.data?.assigned_hr_id?.firstname +"  "+ careplan?.data?.assigned_hr_id?.lastname }</b>
                  <p className="text-muted">
                    {moment(careplan?.data?.createdAt).tz("America/New_York").format('MM/DD/YYYY')}
                  </p>
              </div> 
            </div>

    </div> :  <NotFound title="RPM Careplan Not Added" />
    }
    </div>
  )
}

export default PatientRPMCareplan