import React, {Fragment, useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import {  
    getTimeReportCCM, 
} from '../../actions/HRActions';
import Loader from '../../layouts/Loader';
import patientProfileImg from '../../assets/Images/doctorIcon.png';


const CCMMinutesSpent = ({patientId}) => {

  const dispatch = useDispatch();
  const [count, setCount] = useState(0);

  const [month, setMonth] = useState("08");
  const [year, setYear] = useState("2023");


  const { loading, targets, totalTime, totalInteractiveMinutes, totalNonInteractiveMinutes} = useSelector(state => state.target);

    useEffect(() => {
        setCount((count) => count + 1);
            dispatch(getTimeReportCCM(patientId, month, year));
        return
    }, []);

    const refreshHandler = () => {
        dispatch(getTimeReportCCM(patientId, month, year)); 
    }

    const downloadFile = (fileName) => {
        window.open(`https://vitalsportal.com/v1/uploadFiles/${fileName}`);
      }

  return (
    <div>
         {loading ? <Loader /> : <>
                    {targets && targets.length > 0 ? <Fragment>
                        <div className="container row-display-secondary">
                            <div className="col-md-4 card p-4 m-1 text-center">
                                <h2>{totalTime} mins</h2>
                                <p><b>Total Time Spent in CCM</b></p>
                            </div>

                            <div className="col-md-4 card p-4 m-1 text-center">
                                <h2>{totalInteractiveMinutes} mins</h2>
                                <p style={{color: 'green'}}><b>Interactive Minutes</b></p>
                            </div>

                            <div className="col-md-4 card p-4 m-1 text-center">
                                <h2>{totalNonInteractiveMinutes} mins</h2>
                                <p style={{color: 'red'}}><b>Non-Interactive Minutes</b></p>
                            </div> 
                        </div>    


                        {targets && targets.map((trgt, index) => ( 
                                    <div className="m-2" key={index}>
                                        
                                        <div className="row-display">
                                        <div className="col-lg-3">
                                            <div className="row-display pl-4 pr-4">
                                                <label className="profile-label"> Name: </label> 
                                                <label className="report-label ml-2"> {trgt?.assigned_patient_id?.lastname +" "+ trgt?.assigned_patient_id?.firstname}</label>
                                            </div>

                                            <div className="row-display pl-4 pr-4">
                                                <label className="profile-label"> D.O.B: </label> 
                                                <label className="report-label ml-2">{moment(trgt?.assigned_patient_id?.DOB).format("MM/DD/YYYY")}</label>
                                            </div>
                                        </div>

                                        {/* Time added details */}
                                        <div className="col-lg-3">
                                            <div className="row-display pl-4 pr-4">
                                                <label className="profile-label">S / D: </label> 
                                                <label className="report-label ml-2"> {moment(trgt?.startDate).format("MM/DD/YYYY") || 'N/A' }</label>
                                            </div>

                                            <div className="row-display pl-4 pr-4">
                                                <label className="profile-label">E / D: </label> 
                                                <label className="report-label ml-2">{moment(trgt?.endDate).format("MM/DD/YYYY") || 'N/A' }</label>
                                            </div> 
                                        </div>

                                        <div className='col-lg-2'>
                                            <div className="row-display pl-4 pr-4">
                                                    <label className="profile-label">S / T: </label> 
                                                    <label className="report-label ml-2">{trgt?.startTime || 'N/A'}</label>
                                                </div>
        
                                                <div className="row-display pl-4 pr-4">
                                                    <label className="profile-label">E / T: </label> 
                                                    <label className="report-label ml-2">{trgt?.endTime || 'N/A'}</label>
                                            </div>
                                        </div>

                                        <div className='col-lg-1'>
                                            <h5 className="display-4"> {trgt?.timeSpentInMinutes < 10 ? '0'+trgt?.timeSpentInMinutes : trgt?.timeSpentInMinutes}</h5>    
                                        </div>

                                        {/* Notes added */}
                                        <div className="col-lg-3">
                                            <div className="pl-4 pr-4">
                                              
                                            {trgt?.interactiveMinutes === true ?
                                                <b style={{color: 'green'}}>Interactive Minutes</b> 
                                                : 
                                                <b style={{color: 'red'}}>Non-Interactive Minutes</b>
                                            }
                                                
                                            <label className="report-label">added in <span style={{fontWeight: 'bold'}}>
                                                CCM category</span>
                                            </label>
                                                
                                            {trgt?.fileName ? <>
                                            <label className="profile-label">Attachment: </label> 
                                                <p><button className="btn btn-link" onClick={() => downloadFile(trgt?.fileName)}>{trgt?.fileName} </button>   
                                                </p></> : null}
                                            </div>
                                        </div>
                                        </div>
    
                                        <div className="row-display-secondary">
                                                <div className="mt-3 mr-3">
                                                    <img src={patientProfileImg} alt="hr/drImg" style={{width: '50px', height: '50px', borderRadius: '50%'}}/>
                                                </div>
                                                <div className="bubble bubble-alt">  
                                                    
                                                    <div className='row-display'>
                                                        <b className='text-white' 
                                                            style={{letterSpacing: '1px'}}> 
                                                            {trgt?.assigned_assistant_id ? trgt?.assigned_assistant_id?.lastname +', '+ trgt?.assigned_assistant_id?.firstname : trgt?.assigned_hr_id?.lastname +", "+ trgt?.assigned_hr_id?.firstname}
                                                        </b>
                                                    </div>
                                                                        
                                                    <p className="mt-1 mr-3">{trgt?.conclusion} <br/> 
                                                    <p className="mt-1"><b>{moment(trgt?.createdAt).tz("America/New_York").format("MM-DD-YYYY hh:mm A")}</b></p>
                                                    </p>
                                                </div>
                                            </div>
                                        <hr />
                                    </div>
                                ))}   
                            </Fragment> : <div className="text-center mt-3 pb-2">
                               <p>CCM minutes not found</p>
                               <button className="btn btn-outline-primary btn-sm" onClick={() => refreshHandler()}>Refresh Minutes</button>
                            </div>}
                            </>}
                    </div>
                )
            }

export default CCMMinutesSpent;