import React from 'react';

const Loader = () => {
    return (
        
        <div>
        <div className="loader text-white"> 
            Loading...                 
        </div>
        
        </div>
    )
}

export default Loader
