import {useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {getPatientsWithCriticalData} from '../../actions/adminActions';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';


const PatientsWithCriticalReadingsAdmin = () => {
  
  const dispatch = useDispatch();

  const {criticalPts} = useSelector(state => state.criticalPatients);


  useEffect(() => {
    dispatch(getPatientsWithCriticalData());
  }, [dispatch]);

  const respondedResults = criticalPts && criticalPts.filter(item => item?.notes?.length > 0)
  const countRespondedResult = respondedResults.length;

  const notRespondedResults = criticalPts && criticalPts.filter(a => !a?.notes?.length);
  const countNotRespondedResult = notRespondedResults.length;

  
  let sysBPCategory; //variable to store category of BP
  let color

    function calcSysBpCategory(sys) {
        switch(true){
            case (sys > 210):
                sysBPCategory = "Hypertension- stage 4"
                color = '#FE0004'
                break;
            case (sys >= 180 && sys <= 210):
                sysBPCategory = "Hypertension-Stage 3"
                color = '#FE504F'
                break;
            case (sys >= 160 && sys <= 179):
                sysBPCategory = "Hypertension-Stage 2"
               color = '#FE504F'
            break;
            case (sys >= 140 && sys <= 159):
                sysBPCategory = "Hypertension-Stage 1"
                color = '#FF32CB'
            break;
            case (sys >= 130 && sys <= 139):
                sysBPCategory = "Pre-hypertension"
                color = '#FFFF00'
            break;
            case (sys >= 121 && sys <= 129):
                sysBPCategory = "High Normal B.P"
                color = '#CBFE33'
            break;
            case (sys >= 100 && sys <= 120):
                sysBPCategory = "Normal Blood Pressure"
                color = '#00FF99'
            break;
            case (sys >= 90 && sys <= 99):
                sysBPCategory = "Low Normal B.P"
                color = '#CDFFCC'
            break;
            case (sys >= 70 && sys <= 89):
                sysBPCategory = "Low Blood Pressure"
                color = '#DEEAF6'
            break;
            case (sys >= 50 && sys <= 69):
                sysBPCategory = "Too Low Blood Pressure"
                color = '#9CC3E4'
            break;
            case (sys < 50):
                sysBPCategory = "Extremely Low B.P"
                color = '#2E73B4'
            break;
            default:
                sysBPCategory = "Invalid B.P"
                color = '000'
            } 
        }

  
  return (
    <div className="shadow-lg bg-white recent-readings-card">
            <div className="row-display">
                <div>
                    <h5 className="title pl-3">Critical Readings</h5>
                    <p> <small className="pl-3">
                            RPM Patient with critical readings during past three days.
                        </small>
                    </p>   
                </div>

                <div>
                    <span style={{color: 'green'}}>{countRespondedResult} Responded</span> &nbsp;&nbsp;
                    <span style={{color: 'red'}}>{countNotRespondedResult} Not Responded</span>
                </div>
            </div>

                    <Table bordered className="text-center">
                        <thead>
                        <tr>
                            <th>Patient</th>
                            <th style={{width: '80px'}}>B.P</th>
                            <th style={{width: '60px'}}>Pulse</th>
                            <th>Date & Time</th>
                            <th>Code & Status</th>
                            <th style={{width: '70px'}}></th>
                        </tr>
                        </thead>
                        <tbody>
                        {criticalPts && criticalPts.map((pts, index) => (
                        <tr key={index} className={pts?.notes && pts?.notes?.length > 0 ? 'coloredRow' : 'reddishRow'}>
                            <td><Link to={{ pathname: "/patientProfile", state: {patientid: pts?.assigned_patient_id?._id}}} className="link">{pts?.assigned_patient_id?.lastname +" , "+ pts?.assigned_patient_id?.firstname } </Link> </td>
                            {calcSysBpCategory(pts?.telemetaryData?.sys)}
                            <td> {
                                pts?.telemetaryData?.sys >= 139 || pts?.telemetaryData?.sys <= 110 ? 
                                <span style={{color: 'red', fontWeight: 'bold'}}>{pts?.telemetaryData?.sys}</span> : 
                                <span>{pts?.telemetaryData?.sys}</span>} &nbsp; / &nbsp; 
                                {pts?.telemetaryData?.dia >= 90 || pts?.telemetaryData?.dia <= 70 ? 
                                <span style={{color: 'red', fontWeight: 'bold'}}>{pts?.telemetaryData?.dia}</span> : 
                                <span>{pts?.telemetaryData?.dia}</span>}
                            </td>
                            <td>{pts?.telemetaryData?.pul}</td>

                            <td>{moment(pts?.telemetaryData?.ts * 1000).tz("America/New_York").format("MM/DD/YYYY hh:mm z A")}</td>
                            
                            <td style={{color: color, textAlign: 'center'}}>
                                <i className='bx bxs-circle' style={{fontSize: '18px'}}></i> &nbsp; {sysBPCategory}
                            </td>
                           
                            <td>
                            {pts?.notes?.length > 0 ? <i className="fas fa-check" style={{color: 'green'}}></i> : 
                                <i className="fas fa-times" style={{color: 'red'}}></i>}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </div>
        )
    }

export default PatientsWithCriticalReadingsAdmin