import { useState, useEffect } from 'react';
import Sidebar from '../../components/AdminDashboard/Sidebar';
import TopBar from '../../components/AdminDashboard/TopBar';
import MetaData from '../../layouts/MetaData';
import { Link, useHistory } from "react-router-dom";
import { getHrLists, getDoctors } from '../../actions/adminActions';
import { useAlert } from 'react-alert';
import { useSelector, useDispatch } from 'react-redux';
import { RESET_TIME_REPORT_DATA } from '../../constants/HRConstants';
import { getTimeReportCCMByHR } from '../../actions/HRActions';
import patientProfileImg from '../../assets/Images/doctorIcon.png';
import NotFound from '../../components/NotFound';
import moment  from 'moment';
import Loader from '../../layouts/Loader';




const NursesPerformanceWithDrs = () => {
    
    const history = useHistory();
    const alert = useAlert();
    const dispatch = useDispatch();

    
    const [hrId, setHRId] = useState('');
    const [drId, setDrId] = useState('');
    const [month, setMonth] = useState("08");
    const [year, setYear] = useState("2023");
    const [reportCategory, setReportCategory] = useState('CCM');

    const { hrs } = useSelector(state => state.hrslist);
    const {error, message} = useSelector(state => state.common);
    const { loading, targets, totalTime, totalInteractiveMinutes, totalNonInteractiveMinutes} = useSelector(state => state.target);
    const { doctors } = useSelector(state => state.doctor);


    useEffect(() =>{
        if(error){
            alert.error(error);
        }

        if(message){
            alert.success(message);
        } 

        dispatch(getHrLists());
        dispatch(getDoctors(10, 1));

        dispatch({ type: RESET_TIME_REPORT_DATA });


    }, [dispatch, error, message])

    const submitHandler = () => {
        if(!hrId) {
            alert.error('Please select nurse');
            return;
        }

        dispatch(getTimeReportCCMByHR(hrId, month, year)); 

    }

    const resetData = () => {
        dispatch({ type: RESET_TIME_REPORT_DATA });
    }

    const downloadFile = (fileName) => {
        window.open(`https://vitalsportal.com/v1/uploadFiles/${fileName}`);
    }

    let total = 0;



    return (
    <>  
         <MetaData title="Nurses Performance"/>
            <Sidebar />    

            <section className="home-section">
            {/* TopBar */}
            <TopBar />

            <div className="shadow-lg rounded-card m-3" style={{backgroundColor: '#FAFAFA'}}>
            <div className='searchArea p-3'>
                <div className="home-content">

                <div className="row-display">
                    <h5 style={{color: '#004aad'}}>
                        <i className='bx bx-filter'></i> 
                        <strong>&nbsp;Nurses Performance </strong>
                    </h5>

                    <div className="row-display">
                        <button className="btn btn-secondary btn-sm" onClick={() => history.goBack()}>
                            <i className='bx bx-arrow-back'></i>
                        </button>
                        &nbsp;&nbsp;
                        <Link to="/adminDashboard" className="btn btn-secondary btn-sm">
                            <i className='bx bxs-home'></i>
                        </Link>
                    </div>
                </div>
                </div>
                <hr />
                <span className="notes-header"><b>Note: </b> You can generate nurse performance report by selecting nurse from dropdown menu.</span>

                <br/><br />

                <div className="row-display">
                    <div className="col-md-3">
                    <label className="form-label" htmlFor="from">Select Nurse </label>
                    <select 
                        className="form-control"
                        value={hrId}
                        onChange={(e) => setHRId(e.target.value)}
                        >

                        <option>Select Nurse</option>
                        {hrs && hrs.filter(hr => hr.block === false).map((hr, index) => (
                                <option value={hr?._id} key={index}>{hr?.lastname}, {hr?.firstname} {hr?.npinumber} </option>
                            ))} 
                    </select>       
                    </div>

                    <div className="col-md-3">
                    <label htmlFor="from" className="profile-label">Select Month </label>
                        <select 
                            label="From" 
                            name="from" 
                            className="form-control"
                            value={month}
                            onChange={(e) => setMonth(e.target.value)}
                        >
                        <option value="01">January</option>
                        <option value="02">February</option>
                        <option value="03">March</option>
                        <option value="04">April</option>
                        <option value="05">May</option>
                        <option value="06">June</option>
                        <option value="07">July</option>
                        <option value="08">August</option>
                        <option value="09">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                        </select>
                    </div>

                    <div className="col-md-3">     
                    <label htmlFor="year" className="profile-label">Select Year</label>
                        <select 
                            label="From" 
                            name="from" 
                            className="form-control"
                            value={year}
                            onChange={(e) => setYear(e.target.value)}
                        >
                            <option value="SelectYear">Select Year</option>
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                        </select>
                    </div>

                    <div className="col-md-3">     
                        <button className='edit-pill mt-4' onClick={submitHandler}>Search</button>
                    </div>
                    </div>

                    <br/><br/>
                    <div>
                    {loading ? <Loader /> : <>
                    {targets && targets.length > 0 ? <>
                                    
                        <br />
                        <div className="container row-display">
                            <div className="col-md-12">
                            <div className="row-display">
                                <h5 style={{color: '#004aad'}}>
                                    <i className='bx bxs-watch'></i> <strong>&nbsp;CCM Minutes Added </strong>
                                </h5>

                             

                                <div className="row-display">
                                <label className="form-label">Select Doctor</label> &nbsp;&nbsp;
                                    <select className="form-control" 
                                        style={{width: '220px'}}
                                        value={drId}
                                        onChange={e => setDrId(e.target.value)}
                                        >
                                        {doctors && doctors.map((dr, index) => (
                                            <option value={dr?._id} key={index}> {dr?.firstname +" "+ dr.lastname}</option>
                                        ))}   
                                    </select>

                                &nbsp;&nbsp;
                                <button className="btn btn-sm btn-danger" onClick={resetData}>Reset</button>

                                </div>
                                
                            </div>     

                            <hr />
                            
                            <div className="row-display-secondary">
                                <div className="col-md-4 card p-4 m-2 text-center">
                                    <h2>{totalTime} mins</h2>
                                    <p><b>Total Time Spent</b></p>
                                </div>

                                <div className="col-md-4 card p-4 m-2 text-center">
                                    <h2>{totalInteractiveMinutes} mins</h2>
                                    <p style={{color: 'green'}}><b>Interactive Minutes</b></p>
                                </div>

                                <div className="col-md-4 card p-4 m-2 text-center">
                                    <h2>{totalNonInteractiveMinutes} mins</h2>
                                    <p style={{color: 'red'}}><b>Non-Interactive Minutes</b></p>
                                </div> 
                            </div>
                              </div>        
                            </div>
                            <br />

                            {drId ? <>
                                <div className="colored-row">
                                <b className="container title">Minutes with selected doctor: </b>
                                <div className="row-display container text-center pt-3">
                                    <div className="col-md-4">
                                        <div className="row-display">
                                            <h5>{targets.filter(item => item?.assigned_patient_id?.assigned_doctor_id === drId).reduce((sum, i) => (sum += i.timeSpentInMinutes), 0)} <b style={{color: 'green'}}>Total Minutes</b></h5>
                                        </div>    
                                    </div>

                                    <div className="col-md-4">
                                        <div className="row-display">
                                            <h5>{targets.filter(item => item?.assigned_patient_id?.assigned_doctor_id === drId && item?.interactiveMinutes === true).reduce((sum, i) => (sum += i.timeSpentInMinutes), 0)} <b style={{color: 'green'}}>Interactive Minutes</b></h5>
                                        </div>    
                                    </div>

                                    <div className="col-md-4">
                                        <div className="row-display">
                                            <h5>{targets.filter(item => item?.assigned_patient_id?.assigned_doctor_id === drId && item?.interactiveMinutes === false).reduce((sum, i) => (sum += i.timeSpentInMinutes), 0)} <b style={{color: 'green'}}>Interactive Minutes</b></h5>
                                        </div>    
                                    </div>
                                </div>
                                </div>
                            </> : null}
                            <br/>


                                    {targets && targets.filter(item => drId ? item?.assigned_patient_id?.assigned_doctor_id === drId : item).reverse().map((trgt, index) => ( 
                                    <div className="m-2" key={index}>
                                    
                                  
                                        
                                        <div className="row-display">
                                        <div className="col-lg-3">
                                            <div className="row-display pl-4 pr-4">
                                                <label className="profile-label">Name: </label> 
                                                <label className="report-label ml-2"> {trgt?.assigned_patient_id?.lastname +" "+ trgt?.assigned_patient_id?.firstname}</label>
                                            </div>

                                            <div className="row-display pl-4 pr-4">
                                                <label className="profile-label">D.O.B: </label> 
                                                <label className="report-label ml-2">{moment(trgt?.assigned_patient_id?.DOB).format("MM/DD/YYYY")}</label>
                                            </div>
                                        </div>

                                        {/* Time added details */}
                                        <div className="col-lg-3">
                                            <div className="row-display pl-4 pr-4">
                                                <label className="profile-label">S / D: </label> 
                                                <label className="report-label ml-2"> {moment(trgt?.startDate).format("MM/DD/YYYY") || 'N/A' }</label>
                                            </div>

                                            <div className="row-display pl-4 pr-4">
                                                <label className="profile-label">E / D : </label> 
                                                <label className="report-label ml-2">{moment(trgt?.endDate).format("MM/DD/YYYY") || 'N/A' }</label>
                                            </div> 
                                        </div>

                                        <div className='col-lg-2'>
                                            <div className="row-display pl-4 pr-4">
                                                    <label className="profile-label">S / T: </label> 
                                                    <label className="report-label ml-2">{trgt?.startTime || 'N/A'}</label>
                                                </div>
        
                                                <div className="row-display pl-4 pr-4">
                                                    <label className="profile-label">E / T: </label> 
                                                    <label className="report-label ml-2">{trgt?.endTime || 'N/A'}</label>
                                            </div>
                                        </div>

                                        <div className='col-lg-1'>
                                            <h5 className="display-4"> {trgt?.timeSpentInMinutes < 10 ? '0'+trgt?.timeSpentInMinutes : trgt?.timeSpentInMinutes}</h5>    
                                        </div>

                                        {/* Notes added */}
                                        <div className="col-lg-3">
                                            <div className="pl-4 pr-4">
                                              
                                            {trgt?.interactiveMinutes === true ?
                                                <b style={{color: 'green'}}>Interactive Minutes</b> 
                                                : 
                                                <b style={{color: 'red'}}>Non-Interactive Minutes</b>
                                            }
                                                
                                            <label className="report-label">added in <span style={{fontWeight: 'bold'}}>
                                                {reportCategory} category</span>
                                            </label>
                                                
                                            {trgt?.fileName ? <>
                                            <label className="profile-label">Attachment: </label> 
                                                <p><button className="btn btn-link" onClick={() => downloadFile(trgt?.fileName)}>{trgt?.fileName} </button>   
                                                </p></> : null}
                                            </div>
                                        </div>
                                        </div>
    
                                        <div className="row-display-secondary">
                                                <div className="mt-3 mr-3">
                                                    <img src={patientProfileImg} alt="hr/drImg" style={{width: '50px', height: '50px', borderRadius: '50%'}}/>
                                                </div>
                                                <div className="bubble bubble-alt">  
                                                    
                                                <div className='row-display'>
                                                    <b className='text-white' 
                                                        style={{letterSpacing: '1px'}}> 
                                                        {trgt?.assigned_hr_id?.lastname}, {trgt?.assigned_hr_id?.firstname}
                                                    </b>
                                                </div>
                                                                        
                                                    <p className="mt-1 mr-3">{trgt?.conclusion} <br/> 
                                                    <p className="mt-1"><b>{moment(trgt?.createdAt).tz("America/New_York").format("MM-DD-YYYY")}</b></p>
                                                    </p>
                                                </div>
                                            </div>
                                        <hr />
                                    </div>

                                ))}
                            </> : <NotFound title="No Record Found!" /> }
                            </>}


                </div>

                </div>
                </div>






            </section>

    </>
  )
}

export default NursesPerformanceWithDrs