import React from 'react';
import logo from '../assets/Images/official_logo.png';
import moment from 'moment';
import patientProfileImg from '../assets/Images/doctorIcon.png';




export default class WeightScalePDF extends React.PureComponent {
    render() {
         const { healthData } = this.props;
         const {startDate} = this.props;
         const {endDate} = this.props;
         const {withNotes} = this.props;

        //  let color;
        //  let filteredReadings = calcTotalReadings();

        // function calcTotalReadings() {
        //     return healthData && healthData.filter(data => data?.deviceId?.deviceType === "weight").reduce((sum, a) =>  
        //         sum + 1, 0
        //     )
        // }



      return (
          <div>
          
            <div>
            <div className="container row-display">
                <img src={logo} width="270" height="110" alt="logo" />

                <div>
                    <p className="mt-4"><b>Date Printed: </b>  {moment().tz("America/New_York").format("MM/DD/YYYY")} </p>
                    <i>Generated By Admin</i> 
                </div>
            </div>
            <hr />

            <b>
                Weight Scale Reports
                <p>{startDate} to {endDate}</p>
            </b>
            <br />

            <div style={{marginLeft: '60px', marginRight: '60px'}}>

            <h5 className="text-center" style={{color: '#004aad'}}><b>Weight Scale Result</b></h5>
            <br />


            <div className="row-display">
                <div>
                    <b> Patient Name : </b> {healthData[0]?.assigned_patient_id?.lastname}, {healthData[0]?.assigned_patient_id?.firstname}
                    <br />
                    <p><b> Patient Gender : </b> {healthData[0]?.assigned_patient_id?.gender} </p>
                </div>
                </div>

            
                    {healthData && healthData.map((data, index) => (
                        <>
                        <div className="telemetary-card" key={index}>
                        <p>
                            Patient Name: <b>{data?.assigned_patient_id?.firstname} {data?.assigned_patient_id?.lastname}</b>
                        </p>
                        
                        <div className="row-display">
                            {/* <div>
                                <Image src={systolicImg} className="systolic-image" />    
                            </div> */}
 
                                
                            <div>
                            <span className="profile-label">Weight : {(data?.telemetaryData?.wt / 454).toFixed()} lbs. mmHG</span>
                                <br/>
                                  
                                <p>
                                <small>
                                    Approx {((data?.telemetaryData?.wt / 454).toFixed() * 0.45359237).toFixed()} KG
                                </small>  
                                </p>
                            </div>

                            <div>
                            <span className="profile-label">Fat : {data?.telemetaryData?.fat} </span>
                                <br/>
                            </div>

                           

                            <div>
                                <span className="profile-label">BMI : {data?.telemetaryData?.bmi}</span>
                            </div>
                        </div> {/* First row ends here*/}

                         {/* Device & Patient Info */}
                        <br />
                        <div className="row-display telemetary-patient-row pl-2 pr-2">
                            <div>
                                <span className="telemetary-patient-row">Device ID: </span>
                                <span className="ml-2"> {data?.deviceId?._id}</span>
                            </div>

                            <div>
                                <span className="telemetary-patient-row ml-2">Device Type: </span>
                                <span className="ml-2">{data?.deviceId?.deviceType}</span>
                            </div>

                            <div>
                                <span className="telemetary-patient-row ml-2">Reading Date: </span>
                                <span className="ml-2"> {data?.dateAdded}</span>
                            </div>
                        </div>
                    </div>

                    <div>
                        {withNotes === true && <>
                            {data?.notes.map((note, index) => ( <div key={index}>
                            <div className="row-display-secondary">
                                <div className="mt-3 mr-3">
                                    <img src={patientProfileImg} alt="hr/drImg" style={{width: '50px', height: '50px', borderRadius: '50%'}}/>
                                </div>
                                <div>  
                                    
                                    <b>{note?.conclusion_hr_id ? (<span>{note?.conclusion_hr_id?.firstname}  {note?.conclusion_hr_id?.lastname}</span>) : (<span>
                                        {note?.conclusion_doctor_id?.firstname}  {note?.conclusion_doctor_id?.lastname}
                                    </span>)}</b><br/>
                                                        
                                    <p className="mt-1 mr-3">{note?.conclusion} <br/> 
                                    <p className="mt-1" style={{letterSpacing: '1px'}}>{moment(note?.dateTime).tz("America/New_York").format("MM/DD/YYYY hh:mm")}</p>
                                    </p>
                                </div>
                            </div>
                        </div> 
                        ))}
                        </>}                         
                    </div>


                    <hr />
                    <div style={{float: "right"}}>
                        <small><b>Record {index + 1} of {healthData?.length}</b></small>
                    </div>
                    </>
                ))}
           </div>            
         </div>
        </div>
      )
    }
  }