import React from 'react';
import { CSVLink } from 'react-csv';
import moment from 'moment';


const ExportBillingReportToCSV = ({csvData, fileName, month, year}) => {
  let CptCode = '';

  let monthArray = ["January", "February", "March", "April", "May", "June", "July",
    "August", "September", "October", "November", "December"];

//   console.log('Month is '+ month);  

  function calcCPT(readings, minutes) {
    switch(true){
      case (readings < 16 && minutes < 20):
        CptCode = ''
        break; 
      case (readings < 16 && minutes >=20 && minutes < 40):
          CptCode = "99457"
      break;
      case (readings < 16 && minutes >=40 && minutes < 60):
          CptCode = "99457, 99458"
      break;
      case (readings < 16 && minutes >=60 && minutes < 80):
          CptCode = "99457, 99458, 99458"
      break;
      case (readings < 16 && minutes >=80 && minutes < 100):
          CptCode = "99457, 99458, 99458, 99458"
      break;
      case (readings < 16 && minutes >=100 && minutes < 120):
          CptCode = "99457, 99458, 99458, 99458, 99458"
      break;
      case (readings < 16 && minutes >=120 && minutes < 140):
          CptCode = "99457, 99458, 99458, 99458, 99458, 99458"
      break;
      case (readings < 16 && minutes >=140 && minutes < 160):
          CptCode = "99457, 99458, 99458, 99458, 99458, 99458, 99458"
      break;

      case (readings < 16 && minutes >=160):
          CptCode = "99457, 99458, 99458, 99458, 99458, 99458, 99458, 99458"
      break;   



      case (readings >= 16 && minutes < 20):
          CptCode = '99454'
          break;
      
      case (readings >= 16 && minutes >= 20 && minutes < 40):
            CptCode = '99454, 99457'
            break; 
      case (readings >= 16 && minutes >= 40 && minutes < 60):
          CptCode = '99454, 99457, 99458'
      break;
      
      case (readings >= 16 && minutes >= 60 && minutes < 80):
          CptCode = '99454, 99457, 99458, 99458'
      break;
      
      case (readings >= 16 && minutes >= 80 && minutes < 100):
          CptCode = '99454, 99457, 99458, 99458, 99458'
      break;

      case (readings >= 16 && minutes >= 100 && minutes < 120):
          CptCode = '99454, 99457, 99458, 99458, 99458, 99458'
      break;

      case (readings >= 16 && minutes >= 120):
          CptCode = '99454, 99457, 99458, 99458, 99458, 99458, 99458'
      break;

      default: 
      break;
      }
    }


    let result = csvData.map((report, index) => {

        report?.initialSetup && report?.initialSetup === monthArray[month - 1] + "/" + year ? calcCPT(report?.totalMinutes) : calcCPT(report?.totalReadings, report?.totalMinutes);

        return {
            'Sr No':index + 1,
            'Month': report?.Month,
            'EHR-ID':report?.emrId,
            'Patient Name':report?.patientName,
            'D.O.B':moment(report?.DOB).format('MM/DD/YYYY'),
            'Total Readings':report?.totalReadings,
            'Total Minutes': report?.totalMinutes,
            'initial CPT':report?.initialSetup && report?.initialSetup === monthArray[month - 1] + "/" + year ? '99453' : null,
            'CPT Code': CptCode,
            'Last Activity': report?.lastActivity ? moment(report?.lastActivity).tz("America/New_York").format("MM/DD/YYYY hh:mm A") : null,
            'Category': 'RPM' 
          }
    })


  return (
    <button className="edit-pill">
        <i className='bx bxs-cloud-upload' style={{color: 'green'}}></i> <CSVLink data={result} filename={fileName}> <span style={{color: '#FFF'}}>Download .csv</span></CSVLink> 
    </button>
  )
}

export default ExportBillingReportToCSV;