import {useEffect} from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { getTotalMinutesReportMonthwise } from '../../actions/adminActions';
import { useDispatch, useSelector } from 'react-redux';


const RPMTotalMonthlyMinutesGraph = ({category}) => {

  const dispatch = useDispatch();

  const { monthlyTargets } = useSelector(state => state.monthlyTargetMinutes);


  useEffect(() => {
    dispatch(getTotalMinutesReportMonthwise(category));
    
}, [dispatch, category]);

let data = monthlyTargets && monthlyTargets.map(targets => {
     
  return {
      'date': targets?._id?.month,
      'Total Minutes': targets?.total
  } 
});

  return (
      <>
    <section> 
        <br/>
       <div className='overflowXAllow'> 
       <ResponsiveContainer width="100%" aspect={4/2} style={{overflowY: 'hidden !important' }}>
        <BarChart 
            data={data}>
            <XAxis dataKey="date" />
            <YAxis dataKey="Total Minutes"/>
            <CartesianGrid strokeDasharray="1 1" />
            <Tooltip />
            <Legend/> 
            <Bar dataKey="Total Minutes" fill="#0E194F" />
        </BarChart>
      </ResponsiveContainer>
      </div>
    </section>
    </>
  )
}

export default RPMTotalMonthlyMinutesGraph