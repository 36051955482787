import {useState, useEffect} from 'react';
import Sidebar from '../../components/AdminDashboard/Sidebar';
import TopBar from '../../components/AdminDashboard/TopBar';
import MetaData from '../../layouts/MetaData';
import { Link } from 'react-router-dom';
import { getDoctors } from '../../actions/adminActions';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { TIME_SUMMARY_REPORT_RESET } from '../../constants/adminConstants';
import { getCCMBillingReportByDoctor } from '../../actions/adminActions';
import moment from 'moment';
import { Table } from 'react-bootstrap';
import Loader from '../../layouts/Loader';
import ExportCCMBillingReportToCSV from '../../components/ExportCCMBillingReportToCSV'



const CCMBillingReport = () => {

    const dispatch = useDispatch();
    const [month, setMonth] = useState('09');
    const [year, setYear] = useState("2023");
    const [doctorId, setDoctorId] = useState("");
    const { error, doctors } = useSelector(state => state.doctor);
    const {loading, timeSummaryReport} = useSelector(state => state.summaryReport);

    let monthArray = ["January", "February", "March", "April", "May", "June", "July",
    "August", "September", "October", "November", "December"];


    useEffect(() => {
        if(error){
            alert.error(error);
        }
    
        dispatch(getDoctors(10, 1));
        
        dispatch({
          type: TIME_SUMMARY_REPORT_RESET
        })
    
    }, [dispatch]);

    const doctorOptions = []
    doctors && doctors.map((doctor) => (
        doctorOptions.push({ value: doctor?._id, label: [doctor?.lastname +" ,", doctor?.firstname].join(" ")})
    ))

    const getDoctorProfile = (doctor) => {
        setDoctorId(doctor.value);
    }

    const generateBillingReport = () => {
            dispatch(getCCMBillingReportByDoctor(doctorId, month, year));
        }
    
    const resetReport = () => {
        dispatch({
            type: TIME_SUMMARY_REPORT_RESET
            })
    }

  return (
    <>
        <MetaData title="Biling Report"/>
        <Sidebar />    

        <section className="home-section">
        {/* TopBar */}
        <TopBar />

        <div className="shadow-lg p-3 mb-5 mr-4 ml-4 rounded-card" style={{backgroundColor: '#FAFAFA'}}>
            <div className="home-content">

                <div className="row-display">
                    <h5 style={{color: '#004aad'}}>
                        <i className="bx bx-edit"></i> <strong>&nbsp;CCM Billing Report </strong>
                    </h5>
                
                    <div className="row-display">
                        <Link to="/adminDashboard">
                            <button className="btn btn-secondary btn-sm">
                                <i className='bx bx-arrow-back'></i>
                            </button>
                        </Link>
                        &nbsp;&nbsp;

                      <Link to="/report/billing" className="link pt-2">RPM Billing Report</Link> &nbsp;


                        <Link to="/adminDashboard">
                            <button className="btn btn-secondary btn-sm">
                            <i className='bx bxs-home'></i>
                            </button>
                        </Link>
                    </div>   
                </div>
                <hr />

                <div className="row">
               
                <div className="col-md-3">
                <b>Select Clinician  <span style={{color: 'red'}}> *</span>  </b>
                    <Select
                        options={doctorOptions}
                        onChange={getDoctorProfile}
                        className="react-selectbox mt-2"
                    />
                </div>

                <div className="col-md-3">
                <label htmlFor="from" className="profile-label">Select Month </label>
                        <select 
                            label="From" 
                            name="from" 
                            className="form-control"
                            value={month}
                            onChange={(e) => setMonth(e.target.value)}
                        >
                            <option value="01">January</option>
                            <option value="02">February</option>
                            <option value="03">March</option>
                            <option value="04">April</option>
                            <option value="05">May</option>
                            <option value="06">June</option>
                            <option value="07">July</option>
                            <option value="08">August</option>
                            <option value="09">September</option>
                            <option value="10">October</option>
                            <option value="11">November</option>
                            <option value="12">December</option>
                        </select>
                    </div>

                    <div className="col-md-3">
                        <label htmlFor="year" className="profile-label">Select Year</label>
                        <select 
                            label="From" 
                            name="from" 
                            className="form-control"
                            value={year}
                            onChange={(e) => setYear(e.target.value)}
                        >
                            <option value="SelectYear">Select Year</option>
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                        </select>
                    </div>

                    <div className="mt-4">
                        <label>&nbsp;</label>
                        <button className="edit-pill" onClick={generateBillingReport}>Generate Report</button>
                    </div>
                
                </div>
                <hr />

                {loading ? <Loader /> : <>
                {timeSummaryReport && timeSummaryReport?.length > 0 ? <>
 
                <div className="row-display">
                    <div>
                        <h5 className="title">Billing Report Preview</h5>
                    </div>

                    <div className="row-display">
                        <button className="edit-pill" onClick={resetReport}>
                            <i className='bx bxs-file-pdf'></i> Reset
                        </button> &nbsp;

                        <div>
                            <ExportCCMBillingReportToCSV 
                              csvData={timeSummaryReport} 
                              fileName={'CCM Billing Report '+ moment().tz("America/New_York").format('MM/DD/YYYY')+'.csv'} 
                              month={month}
                              year={year}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-md-12">
                          <br />
                        <Table striped bordered hover>
                            <thead align="center">
                                <tr>
                                  <th width="40px">#</th>
                                  <th width="120px">EHR-ID</th>
                                  <th>Patient Name</th>
                                  <th>D.O.B</th>
                                  <th width="80px">Total Minutes</th>
                                  <th width="80px">CPT</th>
                                  <th>Last Activity</th>
                                  <th>Month</th>
                                </tr>


                                {timeSummaryReport && timeSummaryReport.sort((a, b) => (a.patientName > b.patientName) ? 1 : -1).filter(a => a?.totalMinutes > 0).map((summaryReport, index) => (
                                    <tr key={index} className={summaryReport?.initialSetup && summaryReport?.initialSetup === monthArray[month - 1] + "/" + year ? 'coloredRow' : null}> 
                                      <td>{index + 1}</td>

                                      <td>{summaryReport?.emrId || 'N/A'}</td>

                                      <td><Link className="link"  
                                        to={{ pathname: "/patientProfile", state: {patientid: summaryReport?.patientId 
                                        }}}>
                                          {summaryReport?.patientName}
                                          </Link></td>

                                      <td>{moment(summaryReport?.DOB).format('MM/DD/YYYY')}</td>                                     
                                      
                                      {/* Total Minutes */}
                                      {summaryReport?.totalMinutes < 20 ? <td style={{color: '#e3404f', fontWeight: 'bold'}}>{summaryReport?.totalMinutes}</td> : <td style={{color: 'green', fontWeight: 'bold'}}>{summaryReport?.totalMinutes}</td>}                            

                                      {/* CPT Codes */}
                                       <td style={{color: 'green', fontWeight: 'bold'}}>   
                                        {summaryReport?.totalMinutes >=20 && summaryReport?.totalMinutes < 40 ? '99490' : 
                                            summaryReport?.totalMinutes >= 40 && summaryReport?.totalMinutes < 60 ? '99490, 99439' : 
                                            summaryReport?.totalMinutes >= 60 && summaryReport?.totalMinutes < 90 ? '99487' : 
                                            summaryReport?.totalMinutes >= 90 && summaryReport?.totalMinutes < 120 ? '99487, 99489' : 
                                            summaryReport?.totalMinutes >= 120 && summaryReport?.totalMinutes < 150 ? '99487, 99489, 99489, 99489' : 
                                            summaryReport?.totalMinutes >= 150 ? '99487, 99489, 99489, 99489, 99489' :
                                            null  
                                        }
                                      </td>

                                      <td>{summaryReport?.lastActivity ? moment(summaryReport?.lastActivity).format("MM/DD/YYYY") : '--'}</td> 

                                      <td>{monthArray[month - 1]}</td>
                                    </tr>
                                  ))}

                                </thead>
                          </Table>
                        </div> 
                        </> : null }
                        </> }



                </div>
            </div>
            </section>
            </>
  )
}

export default CCMBillingReport