import {useState} from 'react';
import MaleFemalePieChart from '../../components/Graphs/MaleFemalePieChart';
import RPMTotalMonthlyMinutesGraph from '../../components/Graphs/RPMTotalMonthlyMinutesGraph';
import NurseProgressComponent from './NurseProgressComponent';

const AdminPatientStats = ({ activePatients, InactivePatients, malePts, femalePts, rpmPts, ccmPts, rpmMinutes, ccmMinutes}) => {

    const [category, setCategory] = useState('CCM');
    const [showCategory, setShowCategory] = useState('nurseAnalytics');


    return (
    <>
    <div className="col-md-12">
        <div className="row">    
            <div className="col-md-7 shadow-lg rounded-card p-4" style={{backgroundColor: '#FFF'}}>
                <div className="row-display">
                    <h5 className='title m-0 p-0'> {showCategory === 'nurseAnalytics' ? 'Nurses Performance' : 'Patients Analytics'}</h5>
                    
                    <select 
                        className="form-control" 
                        name="category" 
                        value={showCategory} 
                        onChange={e => setShowCategory(e.target.value)}
                        style={{width: '180px'}}
                        placeholder='Select Stats'
                    >
                        <option value="patientAnalytics">Patient Analytics</option>
                        <option value="nurseAnalytics">Nurse Analytics</option>
                    </select>
                </div>
                <hr/>
            {showCategory === 'patientAnalytics' ? <>
            <div className="row">
            <div className="col-md-4">
                <div>
                    <span style={{color: 'green'}}>
                        Active Patients - 
                    </span>
                </div>

                <div className="mt-2">
                    <span style={{color: 'red'}}>
                        Deactivated Patients - 
                    </span>
                </div>

                <div className="mt-2">
                    <span style={{color: 'orange'}}>
                        RPM Patients - 
                    </span>
                </div>

                <div className="mt-2">
                    <span style={{color: '#179C8C'}}>
                        CCM Patients - 
                    </span>
                </div>

                <div className="mt-2">
                    <span style={{color: 'blue'}}>
                        Male Patients - 
                    </span>
                </div>

                <div className="mt-2">
                    <span style={{color: 'crimson'}}>
                        Female Patients - 
                    </span>
                </div>
            </div>

            <div className="col-md-3">
                <div>
                    <span style={{color: 'green'}}>
                        {activePatients && activePatients}
                    </span>
                </div>

                <div className="mt-2"> 
                    <span style={{color: 'red'}}>
                        {InactivePatients && InactivePatients}
                    </span>
                </div>

                <div className="mt-2"> 
                    <span style={{color: 'orange'}}>
                        {rpmPts && rpmPts}
                    </span>
                </div>

                <div className="mt-2"> 
                    <span style={{color: '#179C8C'}}>
                        {ccmPts && ccmPts}
                    </span>
                </div>

                <div className="mt-2"> 
                    <span style={{color: 'blue'}}>
                        {malePts && malePts}
                    </span>
                </div>

                <div className="mt-2"> 
                    <span style={{color: 'crimson'}}>
                        {femalePts && femalePts}
                    </span>
                </div>

                </div>

                <div className="col-md-5">
                    <MaleFemalePieChart 
                        active={activePatients}
                        inactive={InactivePatients}
                        rpm={rpmPts}
                        ccm={ccmPts}
                    />
                </div>
                </div>

                <hr />
                <div className="row-display">
                    <div>
                        <b className="title">RPM Minutes added since last day</b>
                        <p><b className="text-muted">{rpmMinutes ? rpmMinutes : 0} Minutes</b></p>
                    </div>

                    <div style={{borderRightStyle: 'solid', borderRightWidth: '4px', borderRightColor: 'gray'}}>

                    </div>
                    <div>
                        <b className="title">CCM Minutes added since last day</b>
                        <p><b className="text-muted">{ccmMinutes ? ccmMinutes : 0} Minutes</b></p>
                    </div>
                </div>
                
                {/* Nurse Analytics */}
                </> : <>
                <NurseProgressComponent />
                </>}
            </div>

            <div className="col-md-5 p-4">
                <div className="row-display">
                    <div style={{width: '100%'}}>
                        <h5 className='title m-0 p-0'> {category} Minutes Stats</h5>
                    </div>
                    <select 
                        name="category" 
                        value={category} 
                        onChange={e => setCategory(e.target.value)} 
                        className="form-control">
                        <option value="RPM">RPM</option>
                        <option value="CCM">CCM</option>    
                    </select>
                </div>
                <p><small>Total {category} minutes added monthwise.</small></p>
                <hr/>
                <RPMTotalMonthlyMinutesGraph category={category}/>
            
            </div>
        </div>    
        
    
        </div>
        <br />
    </>
  )
}

export default AdminPatientStats