    import {useState, useEffect} from 'react'
    import MetaData from '../../layouts/MetaData';
    import  HRSidebar from '../../components/HR/HRSidebar';
    import HRTopBar from '../../components/HR/HRTopbar';
    import { useDispatch, useSelector } from 'react-redux';
    import { getHRPatients, getHRStats, getNursePtsRecentReadings} from '../../actions/HRActions';
    import { getHrProfile, getTimeSummaryReportByHR } from '../../actions/adminActions';
    import folderImg from '../../assets/Images/folder.png';
    import { Link } from 'react-router-dom';
    import { Badge, Table, DropdownButton, Dropdown, Image, ProgressBar } from 'react-bootstrap';
    import Loader from '../../layouts/Loader';
    import { useAlert } from 'react-alert';
    import moment from 'moment';
    import receptionIcon from '../../assets/Images/reception.png';
    import networkImg from '../../assets/Images/network.png';
    import activePatientImg from '../../assets/Images/patient.png';
    import verifiedImg from '../../assets/Images/verified-user.png';
    import systolicImg from '../../assets/Images/blood-pressure.png';
    import PatientsWithCriticalDataDoctor from '../../components/Staff/PatientsWithCriticalDataDoctor';




    const HRDashboard = () => {
        let compliantStatus; // variable to store compliant status of patient
        const [month, setMonth] = useState('09'); //moment().month()
        const [year, setYear] = useState(moment().year());
        const [category, setCategory] = useState('RPM');

        const { loading, user, isAuthenticated} = useSelector(state => state.auth);
        const {  error, hrpatients} = useSelector(state => state.hrPatients);
        const { hrProfile } = useSelector(state => state.hrprofile);
        const { totalPatients, activePatients } = useSelector(state => state.hrStats)
        const {timeSummaryReport} = useSelector(state => state.summaryReport);
        const {recentReadings} = useSelector(state => state.recentReadings);


        const [sort, setSort] = useState(true);
        const [query, setQuery] = useState("");
        const keys = ["firstname", "lastname", "DOB", "email", "phone1"];

        const dispatch = useDispatch();
        const alert = useAlert();
        let id = user._id;
        let docId = hrProfile?.assigned_doctor_id?._id;

        
        useEffect(() => {
            if(error) {
                alert.error(error);
            }

            dispatch(getHrProfile(id));

            if(docId){
                dispatch(getHRPatients(docId));
            }

            dispatch(getHRStats(id));
            dispatch(getTimeSummaryReportByHR(id, month, year,category));
            dispatch(getNursePtsRecentReadings(docId));
        }, [dispatch, isAuthenticated, docId])

        function calculateCompliancyStatus(date) {  
            var m = moment(date);
            var today = moment().startOf('day');
            var days = moment.duration(today - m).asDays();
    
            switch(true){
                case (days >= 0 && days < 3):
                    compliantStatus = 'compliant'
                    break;
    
                case (days >= 3 && days <= 7):
                    compliantStatus = "mostLikelyCompliant"
                    break;
    
                case (days >= 8 && days <= 20):
                    compliantStatus = "notLikelyCompliant"
                    break;
    
                case (days >= 8 && days <= 31):
                    compliantStatus = "nonCompliant"
                    break;
                
                default:
                    compliantStatus = 'nonCompliant'
                    break;
            }
        }

        let color

    function calcSysBpCategory(sys) {
        switch(true){
            case (sys > 210):
                color = '#FE0004'
                break;
            case (sys >= 180 && sys <= 210):
                color = '#FE504F'
                break;
            case (sys >= 160 && sys <= 179):
               color = '#FE504F'
            break;
            case (sys >= 140 && sys <= 159):
                color = '#FF32CB'
            break;
            case (sys >= 130 && sys <= 139):
                color = '#FFFF00'
            break;
            case (sys >= 121 && sys <= 129):
                color = '#CBFE33'
            break;
            case (sys >= 100 && sys <= 120):
                color = '#00FF99'
            break;
            case (sys >= 90 && sys <= 99):
                color = '#CDFFCC'
            break;
            case (sys >= 70 && sys <= 89):
                color = '#DEEAF6'
            break;
            case (sys >= 50 && sys <= 69):
                color = '#9CC3E4'
            break;
            case (sys < 50):
                color = '#2E73B4'
            break;
            default:
                color = '000'
            } 
        }

        const refreshRecentReadings = () => {
            dispatch(getNursePtsRecentReadings(docId));
        }

        return (
            <>
                <MetaData title="Nurse Dashboard" />
                <HRSidebar />
                
                <section className="home-section">
                    <HRTopBar />

                     {/* Welcome back section */}
                     <div className="welcomeBack-background">
                            <div className="row-display">
                                <div className="col-md-8">
                                    <h3 className="welcomeBack-heading">Welcome Back, {hrProfile?.firstname +" "+ hrProfile?.lastname}</h3>
                                    <p className="m-0 p-0"><b>Assigned Role is : {hrProfile?.consentRole}</b></p>
                                    <p className="m-0 p-0">Today is : {moment().tz("America/New_York").format('llll')}</p>
                                    <hr />
                                    <b>Keep up the good work, All the best.</b>
                                </div>
                                <div className="col-md-4">
                                    <div className="row">
                                        <img src={receptionIcon} width= '110px' height= '110px' />
                                        <div>
                                            <h4 className="welcomeBack-heading pl-5">Assigned Clin.</h4>
                                            <p className="text-center"><b>Clin. {hrProfile?.assigned_doctor_id ? hrProfile?.assigned_doctor_id?.lastname +", "+ hrProfile?.assigned_doctor_id?.firstname : 'Not Assigned'}</b></p>{}
                                            <p className="p-0 m-0 pl-5"><b>Account Created Date.</b></p>
                                            <p className="pl-5 text-muted">{moment(hrProfile.createdAt).tz("America/New_York").format("MM/DD/YYYY hh:mm A")}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    {/* Welcome back section */}
                    <br />
                        <div className="container-fluid">
                        <div className="home-content">
                            <div className="overview-boxes">
                                <div className="box box0">
                                    <div className="left-side">
                                        <span className="box_topic text-white">Assigned Patients <br /></span>
                                        <div className="number text-white">{totalPatients && totalPatients < 10 ? '0'+totalPatients : totalPatients }</div>
                                        <div className="indicator">
                                            <i className="bx bx-up-arrow-alt down"></i>
                                            <span className="text text-white">Updated Just now</span>
                                        </div>
                                    </div>
                                    <Image src={networkImg} className="cart" />
                                </div>

                                {/* box 2 */}
                                <div className="box box0">
                                    <div className="left-side">
                                        <span className="box_topic text-white">Active Patients <br /></span>
                                        <div className="number text-white">{activePatients && activePatients < 10 ? '0'+activePatients : activePatients }</div>
                                        <div className="indicator">
                                            <i className="bx bx-up-arrow-alt down"></i>
                                            <span className="text text-white">Updated just now</span>
                                        </div>
                                    </div>
                                    <Image src={activePatientImg} className="cart" />
                                </div>

                                {/* box 3 */}
                                <div className="box box0">
                                    <div className="left-side">
                                        <span className="box_topic text-white">Pts. Minutes Completed <br /></span>
                                        <div className="number text-white">                                            
                                            {timeSummaryReport && timeSummaryReport.filter(completedReadings => completedReadings.totalMinutes >= 20).length}
                                        </div>
                                        <div className="indicator">
                                            <i className="bx bx-up-arrow-alt down"></i>
                                            <span className="text text-white">Minutes {'>='} 20 this month</span>
                                        </div>
                                    </div>
                                    <Image src={verifiedImg} className="cart" />
                                </div>

                                {/* box 4 */}
                                <div className="box box0">
                                    <div className="left-side">
                                        <span className="box_topic text-white">Pts. Readings Completed <br /></span>
                                        <div className="number text-white">
                                            {timeSummaryReport && timeSummaryReport.filter(completedReadings => completedReadings.totalReadings >= 16).length}
                                        </div>
                                        <div className="indicator">
                                            <i className="bx bx-up-arrow-alt down"></i>
                                            <span className="text text-white">Readings {'>='} 16 this month</span>
                                        </div>
                                    </div>
                                    <Image src={systolicImg} className="cart" />
                                </div>

                                <br />
                            </div>
                          </div>
                        <div>
                    </div>
                    <br />


                
                    {/* <div className="chatButton">
                         <Link to="/Nurse/Chat" 
                               className="roundedChatButton text-center" 
                               style={{fontSize: '40px', paddingTop: '12px'}}>
                            <i className='bx bx-conversation'></i>
                          </Link>               
                    </div> */}
                
                <br />
                <div className='row'>
                    <div className='col-md-9'>
                        {user.consentRole === 'RPM' || user.consentRole === 'Both' ? <>
                            <PatientsWithCriticalDataDoctor isNurse={true} userId={docId}/>
                        </> : null}
                    </div>

                    <div className='col-md-3'>
                        <div className="shadow-lg bg-white p-3 mb-2 rounded  recent-readings-card">
                            <div className='row-display'>
                                <h5 className="title">Recent Readings</h5>
                                <button className="edit-pill" onClick={() => refreshRecentReadings()}>Refresh</button>
                            </div>

                            <p className='m-0 p-0'><small style={{letterSpacing: '1px'}}>Total <span style={{color: 'red'}}>{recentReadings && recentReadings?.length} </span> readings received during last three days</small></p>    
                            <hr className="blue-hr m-0 p-0"/>

                                {recentReadings && recentReadings.map((pts, index) => (
                                <div key={index}>
                                <br/>    
                                <p>
                                    {pts?.assigned_patient_id?.firstname + " "+ pts?.assigned_patient_id?.lastname } 
                                </p>  

                                {calcSysBpCategory(pts?.telemetaryData?.sys)} 

                                <div className="row-display mt-2">
                                <div>
                                    <span><b>Sys</b></span>
                                    <p>{pts?.telemetaryData?.sys}</p>
                                </div>

                                <i className='bx bxs-circle' style={{color: color, fontSize: '22px'}}></i>

                                <div>
                                    <span><b>Dia</b></span>
                                    <p>{pts?.telemetaryData?.dia}</p>
                                </div>

                                <div>
                                    <span><b>Pulse</b></span>
                                    <p>{pts?.telemetaryData?.pul}</p>
                                </div>
                                </div>

                            <p style={{float: 'right'}}>
                                <small>{moment(pts?.telemetaryData?.ts * 1000).tz("America/New_York").format("MM/DD/YYYY hh:mm z A")}</small>
                            </p>
                            
                            </div>
                            ))}
                            </div>
                        </div>          
                    </div>              
                    <br />


                {loading ? <Loader /> : <>
                        <div className="shadow-lg pb-3 bg-white">        
                            <div className="home-content">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-12">
                                        <div className="row-display">
                                        <h5 style={{color: '#004aad'}} className="mt-2">
                                            <i className='bx bx-user'></i> <strong>&nbsp;Your Doctor's Patients </strong>
                                            <p><small>Patients of your assigned doctor will be listed below.</small></p>
                                        </h5>


                                            <div className="row-display"> 
                                                <input 
                                                    type="text" 
                                                    className="form-control mt-2"
                                                    onChange={e => setQuery(e.target.value)}
                                                    placeholder="Search Patient . . ."
                                                    style={{width: '180px', height: '40px'}}
                                                /> 

                                                &nbsp;&nbsp;&nbsp;
                                                <button className="btn add-staff-btn mt-2" 
                                                onClick={() => setSort(sort => !sort)}
                                                style={{height: '40px'}}
                                                >
                                                {sort ? <i className="bx bx-down-arrow-alt"></i> : <i className="bx bx-up-arrow-alt"></i>}
                                                    </button>
                                            </div>
                                        
                                        </div>
                                        </div>
                                    </div> 
                                    
                                    {hrpatients?.length > 0 ? (<>
                                    <div className="col-md-12">
                                        <>
                                        <Table className="table table-sm table-striped">
                                            <thead align="center">
                                                <tr>
                                                    <th>EHR-ID</th>
                                                    <th>Name</th>
                                                    <th>DOB </th>
                                                    <th>Device Status</th>
                                                    <th>Acc Status</th>
                                                    <th>Clinician</th>
                                                    <th>Compliancy Status</th>
                                                    <th>Last Reading </th>
                                                    <th>Consent Program</th>
                                                    <th style={{width: '70px'}}></th> 
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {sort ? <>
                                                {hrpatients && hrpatients.slice(0,10).filter(item => keys.some(key => item[key].toLowerCase().includes(query))).map((patient, index) => (  
                                            <tr key={index}>
                                            
                                            <td>{patient?.emrId || 'N/A'}</td>

                                            {patient?.block === false ? <td>
                                            <Link style={{textDecoration: 'none', color: 'dodgerblue', fontWeight: 'bold'}} 
                                                to={{ pathname: "/Nurse/Patient/Profile", state: {patientid: patient?._id }}}>
                                                    {patient?.lastname}, {patient?.firstname}
                                            </Link>
                                            </td> : <td>{patient?.lastname}, {patient?.firstname} </td> }
 
                                            
                                            <td> {moment(patient?.DOB).format("MM/DD/YYYY")} <p>{patient?.gender}</p></td>
                                                        
                                            <td>{patient?.batterySignals?.sig >= 0 && patient?.batterySignals?.sig <= 10 ? 
                                                    <text style={{color: 'red', fontWeight: 'bold'}}>Weak</text> : patient?.batterySignals?.sig > 10 && patient?.batterySignals?.sig <= 20 ? 
                                                    <text style={{color: 'orange', fontWeight: 'bold'}}>Medium</text> : patient?.batterySignals?.sig > 20 ? <text style={{color: 'green', fontWeight: 'bold'}}>Strong</text> : '--'} 

                                                {patient?.batterySignals?.sig ? <ProgressBar striped variant="info" now={patient?.batterySignals?.bat} label={`${patient?.batterySignals?.bat}%`} /> : '--'}
                                            </td>
                                    
                                            {patient?.block === false ? <td>
                                                <span style={{color: 'green'}}>Activated</span>
                                                </td> : <td><span style={{color: 'red'}}>Inactive</span>
                                            </td>}

                                            {patient?.assigned_doctor_id ? <>
                                                <td style={{color: '#23408e'}}>Clin.{patient?.assigned_doctor_id?.lastname}, {patient?.assigned_doctor_id?.firstname}</td>
                                                </> : <>
                                                <td><span style={{color: 'red'}}>Not Assigned</span></td>
                                            </>}
    
                                            {calculateCompliancyStatus(moment(patient?.lastReading))}    
                            
                                            <td>
                                                {patient?.lastReading ? 
                                                    compliantStatus === 'compliant' ? <p style={{color: 'green', fontWeight: 'bold'}}>
                                                        Compliant</p> : compliantStatus === 'mostLikelyCompliant' ? <p style={{color: '#F95800', fontWeight: 'bold'}}>
                                                            Most Likely Compliant</p> : compliantStatus === 'notLikelyCompliant' ? <p style={{color: 'dodgerblue', fontWeight: 'bold'}}>
                                                                Not Likely Compliant</p> : compliantStatus === 'nonCompliant' ? <p style={{color: 'red', fontWeight: 'bold'}}>
                                                                Non Compliant</p> : null : '--'}
                                            </td>

                                            <td><span style={{fontWeight: 'bold'}}>{patient?.lastReading ? moment(patient?.lastReading).format("MM/DD/YYYY") : '--'}</span>
                                            {/* <p>{patient?.lastReading ?  moment(patient?.lastReading).fromNow() : null}</p> */}
                                            </td>

                                            <td>{patient?.patientType}
                                            </td>        

                                            <td>
                                                <DropdownButton id="dropdown-basic-button" variant="Light" disabled={patient?.block === true ? true : false}>
                                                    <Dropdown.Item>
                                                        <Link className="link" to={{ pathname: "/Nurse/Patient/Profile", state: {patientid: patient?._id}}}>View Profile</Link>
                                                    </Dropdown.Item>
                                                </DropdownButton>
                                            </td>
                                            </tr> 
                                            ))}
                                            </> : <>
                                            
                                            {hrpatients && hrpatients.reverse().filter(item => keys.some(key => item[key].toLowerCase().includes(query))).map((patient, index) => ( 
                                                <tr key={index}>
                                                
                                            {patient?.block === false ? <td>
                                                    <Link style={{textDecoration: 'none', color: 'dodgerblue', fontWeight: 'bold'}} 
                                                     to={{ pathname: "/Nurse/Patient/Profile", state: {patientid: patient?._id }}}>
                                                        {patient?.firstname} {patient?.lastname} <p>{patient?.phone1}</p></Link>
                                                </td> : <td>
                                                {patient?.firstname} {patient?.lastname} <p>{patient?.phone1}</p>
                                                </td> }
                                                
                                                <td> {moment(patient?.DOB).format("ll")} <p><Badge bg="dark text-white">{patient?.gender}</Badge></p></td>
                                                
                                                <td>{patient?.batterySignals?.sig >= 0 && patient?.batterySignals?.sig <= 10 ? 
                                                    <text style={{color: 'red', fontWeight: 'bold'}}>Weak</text> : patient?.batterySignals?.sig > 10 && patient?.batterySignals?.sig <= 20 ? 
                                                    <text style={{color: 'orange', fontWeight: 'bold'}}>Medium</text> : patient?.batterySignals?.sig > 20 ? <text style={{color: 'green', fontWeight: 'bold'}}>Strong</text> : '--'} 

                                                    {patient?.batterySignals?.sig ? <ProgressBar striped variant="info" now={patient?.batterySignals?.bat} label={`${patient?.batterySignals?.bat}%`} /> : '--'}
                                                </td>

                                                {patient?.block === false ? <td>
                                                <Badge pill bg="success text-white" style={{fontSize: '12px', fontWeight: 300}}>Activated</Badge>
                                                </td> : <td><Badge pill bg="danger text-white" style={{fontSize: '12px'}}>Inactive</Badge>
                                            </td>}


                                                {patient?.assigned_doctor_id ? <>
                                                    <td style={{color: '#23408e'}}>Dr.{patient?.assigned_doctor_id?.firstname} {patient?.assigned_doctor_id?.lastname}</td>
                                                    </> : <>
                                                    <td><Badge pill bg="danger text-white" style={{fontSize: '12px'}}>Not Assigned</Badge></td>
                                                </>}
                                                
                                               
                                                {calculateCompliancyStatus(moment(patient?.lastReading))}    
                            
                                                <td>
                                                    {patient?.lastReading ? 
                                                        compliantStatus === 'compliant' ? <p style={{color: 'green', fontWeight: 'bold'}}>
                                                            Compliant</p> : compliantStatus === 'mostLikelyCompliant' ? <p style={{color: '#F95800', fontWeight: 'bold'}}>
                                                                Most Likely Compliant</p> : compliantStatus === 'notLikelyCompliant' ? <p style={{color: 'dodgerblue', fontWeight: 'bold'}}>
                                                                    Not Likely Compliant</p> : compliantStatus === 'nonCompliant' ? <p style={{color: 'red', fontWeight: 'bold'}}>
                                                                    Non Compliant</p> : null : '--'}
                                                </td>

                                                <td><span style={{fontWeight: 'bold'}}>{patient?.lastReading ? moment(patient?.lastReading).format("LL") : '--'}</span>
                                                    {/* <p>{patient?.lastReading ?  moment(patient?.lastReading).fromNow() : null}</p> */}
                                                </td>

                                                <td>{patient?.assigned_hr_id === id ? <b>RPM</b> : 
                                                    patient?.assigned_ccm_nurse_id === id ? <b>CCM</b> : 
                                                    patient?.assigned_hr_id === id && patient?.assigned_ccm_nurse_id === id ? <b>RPM, CCM</b> : 
                                                    null }  
                                                </td>

                                                <td>
                                                <DropdownButton id="dropdown-basic-button" variant="Light" disabled={patient?.block === true ? true : false}>
                                                    <Dropdown.Item>
                                                        <Link className="link" to={{ pathname: "/Nurse/Patient/Profile", state: {patientid: patient?._id}}}>View Profile</Link>
                                                    </Dropdown.Item>
                                                </DropdownButton>
                                                </td>
                                            </tr> 
                                            ))}

                                            </> }
                                            
                                            </tbody>
                                            </Table>    
                                        </>                      
                                </div>
                            </> ) : <>
                            <div>   
                                            
                            <img src={folderImg} className="no-record-found-img"/>
                            <p className="doctor-specilizations">No Patient Assigned Yet...</p>                            
                                        </div>
                                    </> }
                                </div>
                            </div>
                        </div>
                    </>
                }
                <br/>

                </div>                
            </section>
        </>
        )
    }

    export default HRDashboard;
