import React from 'react';
import { PieChart, Pie, Cell} from 'recharts';
  
  
const MaleFemalePieChart = ({active, inactive, rpm, ccm}) => {
  
// Sample data
const data = [
  {name: 'Active', patients: active},
  {name: 'RPM', patients: rpm},
  {name: 'CCM', patients: ccm},
];

const COLORS = ['green', 'orange', '#179C8C'];

const RADIAN = Math.PI / 180;
  
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {/* {`${(percent * 100).toFixed(0)}%`} &nbsp; */}
        {data[index].name}
      </text>
    );
  }

    return (
            <PieChart width={210} height={200}>
                <Pie data={data} 
                  dataKey="patients" 
                  outerRadius={100} 
                  fill="#004aad"
                  labelLine={false}
                  label={renderCustomizedLabel}
                > 
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                    />
                ))}
                </Pie>
            </PieChart>
    );
    }
  
export default MaleFemalePieChart;