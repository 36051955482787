
export const HR_PATIENTS_REQUEST = 'HR_PATIENTS_REQUEST';
export const HR_PATIENTS_SUCCESS = 'HR_PATIENTS_SUCCESS';
export const HR_PATIENTS_FAIL = 'HR_PATIENTS_FAIL';

export const ADDING_COMMENT_REQUEST = 'ADDING_COMMENT_REQUEST';
export const ADDING_COMMENT_SUCCESS = 'ADDING_COMMENT_SUCCESS';
export const ADDING_COMMENT_FAIL = 'ADDING_COMMENT_FAIL';
export const COMMENT_RESET = 'COMMENT_RESET';

export const ADDING_TIME_SPENT_SUCCESS = 'ADDING_TIME_SPENT_SUCCESS';
export const ADDING_TIME_SPENT_FAIL = 'ADDING_TIME_SPENT_FAIL';
export const ADDING_TIME_SPENT_RESET = 'ADDING_TIME_SPENT_RESET';

export const TIME_REPORT_REQUEST = 'TIME_REPORT_REQUEST';
export const TIME_REPORT_SUCCESS = 'TIME_REPORT_SUCCESS';
export const TIME_REPORT_FAIL = 'TIME_REPORT_FAIL'
export const RESET_TIME_REPORT_DATA = 'RESET_TIME_REPORT_DATA';

export const TARGETED_MINUTES_REQUEST = 'TARGETED_MINUTES_REQUEST';
export const TARGETED_MINUTES_SUCCESS = 'TARGETED_MINUTES_SUCCESS';
export const TARGETED_MINUTES_FAIL = 'TARGETED_MINUTES_FAIL';

export const INITIAL_MONTH_REPORT_REQUEST = 'INITIAL_MONTH_REPORT_REQUEST';
export const INITIAL_MONTH_REPORT_SUCCESS = 'INITIAL_MONTH_REPORT_SUCCESS';
export const INITIAL_MONTH_REPORT_FAIL ='INITIAL_MONTH_REPORT_FAIL'; 
export const RESET_INITIAL_MONTH_DATA = 'RESET_INITIAL_MONTH_DATA';

export const ADDING_CARE_PLAN_SUCCESS = 'ADDING_CARE_PLAN_SUCCESS';
export const ADDING_CARE_PLAN_RESET = 'ADDING_CARE_PLAN_RESET';
export const ADDING_CARE_PLAN_FAIL = 'ADDING_CARE_PLAN_FAIL'

export const PATIENT_CARE_PLAN_SUCCESS = 'PATIENT_CARE_PLAN_SUCCESS';
export const PATIENT_CARE_PLAN_FAIL = 'PATIENT_CARE_PLAN_FAIL';

export const UPDATE_CARE_PLAN_SUCCESS = 'UPDATE_CARE_PLAN_SUCCESS';
export const UPDATE_CARE_PLAN_FAIL = 'UPDATE_CARE_PLAN_FAIL';
export const UPDATE_CARE_PLAN_RESET = 'UPDATE_CARE_PLAN_RESET';

export const TIME_SPENT_OF_CURRENT_MONTH_SUCCESS = 'TIME_SPENT_OF_CURRENT_MONTH_SUCCESS';
export const TIME_SPENT_OF_CURRENT_MONTH_FAIL = 'TIME_SPENT_OF_CURRENT_MONTH_FAIL';

export const TIME_SPENT_OF_CURRENT_MONTH_IN_CCM_SUCCESS = 'TIME_SPENT_OF_CURRENT_MONTH_IN_CCM_SUCCESS';
export const TIME_SPENT_OF_CURRENT_MONTH_IN_CCM_FAIL = 'TIME_SPENT_OF_CURRENT_MONTH_IN_CCM_FAIL';

export const PATIENT_CP_REPORT_REQUEST = 'PATIENT_CP_REPORT_REQUEST';
export const PATIENT_CP_REPORT_SUCCESS = 'PATIENT_CP_REPORT_SUCCESS';
export const PATIENT_CP_REPORT_FAIL = 'PATIENT_CP_REPORT_FAIL';

export const GET_HR_NOTIFICATIONS_REQUEST = 'GET_HR_NOTIFICATIONS_REQUEST';
export const GET_HR_NOTIFICATIONS_SUCCESS = 'GET_HR_NOTIFICATIONS_SUCCESS';
export const GET_HR_NOTIFICATIONS_FAIL = 'GET_HR_NOTIFICATIONS_FAIL';

export const GET_CAREPLAN_LIST_REQUEST = "GET_CAREPLAN_LIST_REQUEST";
export const GET_CAREPLAN_LIST_SUCCESS = "GET_CAREPLAN_LIST_SUCCESS";
export const GET_CAREPLAN_LIST_FAIL = "GET_CAREPLAN_LIST_FAIL";

export const HR_STATS_SUCCESS = 'HR_STATS_SUCCESS';
export const HR_STATS_FAIL = 'HR_STATS_FAIL';

export const GET_CCM_CONSENT_REQUEST = 'GET_CCM_CONSENT_REQUEST';
export const GET_CCM_CONSENT_SUCCESS = 'GET_CCM_CONSENT_SUCCESS';
export const CCM_CONSENT_RESET = 'CCM_CONSENT_RESET';

export const GET_CCM_CAREPLAN_REQUEST = 'GET_CCM_CAREPLAN_REQUEST';
export const GET_CCM_CAREPLAN_SUCCESS = 'GET_CCM_CAREPLAN_SUCCESS';
export const CCM_CAREPLAN_RESET = 'CCM_CAREPLAN_RESET';

export const CHAT_PATIENT_REQUEST = 'CHAT_PATIENT_REQUEST';
export const CHAT_PATIENT_SUCCESS = 'CHAT_PATIENT_SUCCESS';
export const CHAT_PATIENT_FAIL = 'CHAT_PATIENT_FAIL';

export const CLEAR_ERRORS = 'CLEAR_ERRORS';

