import React, {useEffect} from 'react';
import moment from 'moment';
import {Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import {getPatientsWithCriticalRPMDataHrDr} from '../../actions/adminActions';



const PatientsWithCriticalDataDoctor = ({isDr, isNurse, userId}) => {
    
    const dispatch = useDispatch();

    const {criticalPts} = useSelector(state => state.criticalPatients);

    useEffect(() => {
        dispatch(getPatientsWithCriticalRPMDataHrDr(isDr, isNurse, userId));
      }, [dispatch]);


      let sysBPCategory; //variable to store category of BP
let color

    function calcSysBpCategory(sys) {
        switch(true){
            case (sys > 210):
                sysBPCategory = "Hypertension- stage 4"
                color = '#FE0004'
                break;
            case (sys >= 180 && sys <= 210):
                sysBPCategory = "Hypertension-Stage 3"
                color = '#FE504F'
                break;
            case (sys >= 160 && sys <= 179):
                sysBPCategory = "Hypertension-Stage 2"
               color = '#FE504F'
            break;
            case (sys >= 140 && sys <= 159):
                sysBPCategory = "Hypertension-Stage 1"
                color = '#FF32CB'
            break;
            case (sys >= 130 && sys <= 139):
                sysBPCategory = "Pre-hypertension"
                color = '#FFFF00'
            break;
            case (sys >= 121 && sys <= 129):
                sysBPCategory = "High Normal B.P"
                color = '#CBFE33'
            break;
            case (sys >= 100 && sys <= 120):
                sysBPCategory = "Normal Blood Pressure"
                color = '#00FF99'
            break;
            case (sys >= 90 && sys <= 99):
                sysBPCategory = "Low Normal B.P"
                color = '#CDFFCC'
            break;
            case (sys >= 70 && sys <= 89):
                sysBPCategory = "Low Blood Pressure"
                color = '#DEEAF6'
            break;
            case (sys >= 50 && sys <= 69):
                sysBPCategory = "Too Low Blood Pressure"
                color = '#9CC3E4'
            break;
            case (sys < 50):
                sysBPCategory = "Extremely Low B.P"
                color = '#2E73B4'
            break;
            default:
                sysBPCategory = "Invalid B.P"
                color = '000'
            } 
        }
    
        const refreshCriticalReading = () => {
            dispatch(getPatientsWithCriticalRPMDataHrDr(isDr, isNurse, userId));
        }
 
    return (
    <>

        <div className="shadow-lg bg-white p-3 mb-2 rounded  recent-readings-card">
        <div className="row-display">
            <h5 className="title">Critical Readings</h5>
            <button className="edit-pill" onClick={() => refreshCriticalReading()}>Refresh List</button>
        </div>
        <p>There are total <span style={{color: 'red', fontWeight: 'bold'}}>{criticalPts.length}</span> critical readings during past three days. </p>

        <Table bordered className="text-center">
            <thead>
                <tr>
                    <th>Patient</th>
                    <th>B.P</th>
                    <th style={{width: '70px'}}>Pulse</th>
                    <th>Date & Time</th>
                    <th style={{width: '70px'}}>Code</th>
                    <th>Status</th>
                    <th>Responded</th>
                </tr>
                </thead>
                <tbody>
                {criticalPts && criticalPts.reverse().map((pts, index) => (
                <tr key={index} className={pts?.notes && pts?.notes?.length > 0 ? 'coloredRow' : 'reddishRow'}>
                    <td>
                    {isDr === true ? <>
                        <Link to={{ pathname: "/doctor/patient/profile", state: {patientid: pts?.assigned_patient_id?._id}}} className="link">{pts?.assigned_patient_id?.firstname + " "+ pts?.assigned_patient_id?.lastname } </Link>   
                            </> : <>
                        <Link to={{ pathname: "/Nurse/Patient/Profile", state: {patientid: pts?.assigned_patient_id?._id}}} className="link">{pts?.assigned_patient_id?.firstname + " "+ pts?.assigned_patient_id?.lastname } </Link>   
                        </>}
                    </td>

                    {calcSysBpCategory(pts?.telemetaryData?.sys)}
                    <td> {
                        pts?.telemetaryData?.sys >= 139 || pts?.telemetaryData?.sys <= 110 ? 
                        <span style={{color: 'red', fontWeight: 'bold'}}>{pts?.telemetaryData?.sys}</span> : 
                        <span>{pts?.telemetaryData?.sys}</span>} &nbsp; / &nbsp; 
                        {pts?.telemetaryData?.dia >= 90 || pts?.telemetaryData?.dia <= 70 ? 
                        <span style={{color: 'red', fontWeight: 'bold'}}>{pts?.telemetaryData?.dia}</span> : 
                        <span>{pts?.telemetaryData?.dia}</span>}
                    </td>
                    <td>{pts?.telemetaryData?.pul}</td>
                    <td>{moment(pts?.telemetaryData?.ts * 1000).tz("America/New_York").format("MM/DD/YYYY hh:mm z A")}</td>
                    <td>
                        <i className='bx bxs-circle' style={{color: color, fontSize: '22px'}}></i>
                    </td>
                    <td>{sysBPCategory}</td>
                    <td>{pts?.notes?.length > 0 ? <i className="fas fa-check" style={{color: 'green'}}></i> : 
                        <i className="fas fa-times" style={{color: 'red'}}></i>}
                    </td>
                </tr>
            ))}
            </tbody>
        </Table>
    </div>

         {/* <div className="container-fluid mt-3 mb-5">
           <h5 className="title">Patients with critical readings</h5>   
            <p><small>List of RPM patients with critical readings during the last seven days.</small></p>

            <div className="row">
            {criticalPts && criticalPts.filter((a, i) => criticalPts.findIndex((s) => a?.assigned_patient_id?._id === s?.assigned_patient_id?._id) === i).map((pts, index) => (
                <div className="col-md-3 mb-4">
                <div className="card">
                    <div className={`ribbon ribbon-top-left`}> 
                    <span style={{backgroundColor: '#FC359B'}}>{index + 1}</span>
                    </div>

                    <div className="container text-center">
                     <div className="text-center">
                         <img src={patientProfileImg} className="img-responsive profile-card-img mt-4" alt="patientProfile" />
                     </div>    
                     <label className="profile-label mt-3">Patient Name : </label>
                        &nbsp;
                        {isDr === true ? <>
                            <Link to={{ pathname: "/doctor/patient/profile", state: {patientid: pts?.assigned_patient_id?._id}}} className="link">{pts?.assigned_patient_id?.firstname + " "+ pts?.assigned_patient_id?.lastname } </Link>   
                        </> : <>
                        <Link to={{ pathname: "/Nurse/Patient/Profile", state: {patientid: pts?.assigned_patient_id?._id}}} className="link">{pts?.assigned_patient_id?.firstname + " "+ pts?.assigned_patient_id?.lastname } </Link>   
                        </>}
                    <br/>
                
                     <label className="profile-label mt-3">Reading Details </label>

                     <div className="row-display">
                        <div>
                            <span><b>Sys</b></span>
                            <p style={{color: '#FC359B'}}>{pts?.telemetaryData?.sys}</p>
                        </div>

                        <div>
                            <i className='bx bxs-circle' style={{color: '#FC359B', fontSize: '36px'}}></i>    
                        </div>

                        <div>
                            <span><b>Dia</b></span>
                            <p>{pts?.telemetaryData?.dia}</p>
                        </div>

                        <div>
                            <span><b>Pulse</b></span>
                            <p>{pts?.telemetaryData?.pul}</p>
                        </div>
                     </div>

                    <label className="profile-label mt-3">Data Received Date : </label>
                    <p>{moment(pts?.dateAdded).tz("America/New_York").format("MM-DD-YYYY")}</p>

                    </div>
                </div>  
                </div>
            ))}
            </div>
        
        </div> */}
    </>
  )
}

export default PatientsWithCriticalDataDoctor