import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getRecentReadings } from '../../actions/adminActions';
import moment from 'moment';
import { Link } from 'react-router-dom';
import NotFound from '../../components/NotFound';

const RecentReadings = () => {

    const dispatch = useDispatch();
    const {recentReadings} = useSelector(state => state.recentReadings);


    useEffect(() => {
        dispatch(getRecentReadings());
    }, [dispatch]);

    let color

    function calcSysBpCategory(sys) {
        switch(true){
            case (sys > 210):
                color = '#FE0004'
                break;
            case (sys >= 180 && sys <= 210):
                color = '#FE504F'
                break;
            case (sys >= 160 && sys <= 179):
               color = '#FE504F'
            break;
            case (sys >= 140 && sys <= 159):
                color = '#FF32CB'
            break;
            case (sys >= 130 && sys <= 139):
                color = '#FFFF00'
            break;
            case (sys >= 121 && sys <= 129):
                color = '#CBFE33'
            break;
            case (sys >= 100 && sys <= 120):
                color = '#00FF99'
            break;
            case (sys >= 90 && sys <= 99):
                color = '#CDFFCC'
            break;
            case (sys >= 70 && sys <= 89):
                color = '#DEEAF6'
            break;
            case (sys >= 50 && sys <= 69):
                color = '#9CC3E4'
            break;
            case (sys < 50):
                color = '#2E73B4'
            break;
            default:
                color = '000'
            } 
        }


    return (
    <div>
            <div className="card m-1 p-3 recent-readings-card">
                <h5 className='title m-0 p-0'>Recent Readings </h5>
                <p className='m-0 p-0'><small style={{letterSpacing: '1px'}}>Total Reading Rcvd: <span style={{color: 'red'}}>{recentReadings && recentReadings?.length}</span></small></p>
                <hr className="blue-hr m-0 p-0"/>
                {recentReadings && recentReadings?.length > 0 ? <>
                    {recentReadings && recentReadings.map((pts, index) => (
                
                <div key={index} className='mt-2' >
                <p className="link m-0 p-0">
                    <Link to={{ pathname: "/patientProfile", state: {patientid: pts?.assigned_patient_id?._id}}} className="link">{pts?.assigned_patient_id?.lastname + " , "+ pts?.assigned_patient_id?.firstname } </Link> 
                </p>  

                {calcSysBpCategory(pts?.telemetaryData?.sys)} 

                <div className="row-display mt-2">

                <div>
                    <span><i className='bx bx-up-arrow-alt' ></i> Sys</span>
                    <p className='text-center' style={{color: color, fontWeight: 'bold'}}> {pts?.telemetaryData?.sys}</p>
                </div>

                {/* <i className='bx bxs-circle' ></i> */}

                <div>
                </div>

                <div className='row'>
                    <div>
                        <span> <i className='bx bx-down-arrow-alt'></i> Dia</span>
                        <p className='text-center'> {pts?.telemetaryData?.dia}</p>
                    </div>
                </div>

                <div>
                    <span> <i className='bx bx-pulse' ></i> Pulse</span>
                    <p className='text-center'> {pts?.telemetaryData?.pul}</p>
                </div>
                </div>

                <p style={{float: 'right'}}>
                    <small>{moment(pts?.telemetaryData?.ts * 1000).tz("America/New_York").format("MM/DD/YYYY hh:mm z A")}</small>
                </p>
                <br/>
                <hr className='white-hr'/>
                </div>
                
                ))}
                </> : <NotFound title = "No Recent Readings"/> }
            </div>
        </div>
  )
}

export default RecentReadings