export const Prod = "http://localhost:5000/v1";
export const Prod01 = "https://vitalsportal.com/v1";

// https://vitalsportal.com/v1
// 
// file local link
// window.open(`http://localhost:5000/v1/uploadFiles/${fileName}`);
// live link
// window.open(`https://vitalsportal.com/v1/uploadFiles/${fileName}`);
// Hammad's backend 

{/*  <option value="61f99c89323eaa15a61a7976">Clin. Pier Frank</option>
 <option value="640f5c5e2c774825162cba6e">Clin. Frank Boateng</option> 
 <option value="641230ef4ede893bec43d593">Ruth Lambe</option> */}