import { useEffect, useState } from 'react'
import { Link  } from 'react-router-dom';
import TextField from '../components/Form/TextField';
import Selectbox from '../components/Form/Selectbox';
import MetaData from '../layouts/MetaData';
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Spinner, Image } from 'react-bootstrap';
import TDW_logo from '../assets/Images/official_logo.png';
import { login, staffLogin, hrLogin, clearErrors } from '../actions/authActions';
import {HIDE_ALERT_MESSAGE} from '../constants/Common';




const Login = ({ history }) => {

	const alert = useAlert();
	const dispatch = useDispatch();

	const {error : loginError } = useSelector(state => state.common);
	const [show, setShow] = useState(false);
    
	const validate = Yup.object().shape({
		email: Yup.string().email('Invalid email').required('Email is Required'),
		password: Yup.string() 
		  .min(6, 'Too Short!')
		  .max(20, 'Too Long!')
		  .required('Password is Required'),
		role:Yup.string().required('Select Role')
	  });

	const { isAuthenticated, error, loading, user } = useSelector(state => state.auth);

	useEffect(() => {
		
		if(isAuthenticated === true && user.role === 'admin') {
			history.push("/adminDashboard");
		}

		if(isAuthenticated === true && user.role === 'HrMedical'){
			history.push("/Nurse/Dashboard");
		}

		if(isAuthenticated === true && user.role === 'doctor'){
			history.push("/doctor/dashboard");
		}

		if(error){
			alert.error(error);
			dispatch({
                type: HIDE_ALERT_MESSAGE
            })
		}

		if(loginError) {
			alert.error(loginError);
			dispatch({
                type: HIDE_ALERT_MESSAGE
            })
		}

	}, [dispatch, alert, isAuthenticated, error, loginError, history])
	
	
	const submitHandler = (values) => {
		if(values.role === 'admin'){
			dispatch(login(values));
		} else if (values.role === 'doctor'){
			dispatch(staffLogin(values));
		} else if(values.role === 'nurse'){
			dispatch(hrLogin(values));
		}	else {
			return
		}	
		// dispatch(login(values));
	}
	
    return (
    	<>
		<MetaData title="Sign In" />
		 <>
				<div className="login-section">
					<div className="container">
						<div className="content">
							<Image src={TDW_logo} style={{height:"80px"}} alt="logo"/>		
								
							<div className="col-md-12" >
								<br />
								<div>
									<h3 style={{color: '#004aad'}}>Welcome!</h3>
									<h5 style={{color: '#999'}}>Sign in to your Account</h5>
								</div>
								<Formik initialValues={{
									email: '',
									password: '', 
									role: 'admin'
								}}
								validationSchema={validate}
								onSubmit={values => {
									submitHandler(values)
								}}
								>
										<div>
											<Form>
												<TextField 
													label="Email Address" 
													name="email" 
													type="email" 
													placeholder="Enter Email"
												/>

												<TextField 
													label="Password" 
													name="password" 
													type={show === false ? "password" : "text"}	
													placeholder="Enter Password"
												/>

													<div style={{float: 'right'}}>
														<input type="checkbox" 
														 name="show" 
														 id="show"
														 value={show}
														 onChange={() => setShow(!show)}
														/>
														&nbsp;&nbsp;<label htmlFor="show" className='text-muted' style={{fontSize: '14px'}}>
															Show Password
														</label>
													</div>

												<Selectbox 
													label="Select Role"
													name="role"
													placeholder="Select Role"
												/>
												
												<Link to="/auth/forgot">
													<span className="forgot-password-link">Forgot Password ?</span>
												</Link>
												
												<br/>
												
													{/* <button className="reset-btn" type="reset"><i className='bx bx-reset' ></i></button> */}
													<button className="edit-pill" type="submit" style={{width: '100%'}}>
														{loading ? <Spinner animation="border" style={{height: '20px', width: '20px'}}/> : 
														'Login'}
													</button>
											</Form>

											<br/>
												<div className="text-center">
													<Link to="/" style={{textDecoration: 'none', 
													color: "#004aad", fontSize: '18px'}}><small>TheDoctorWeb.com</small></Link>
												</div>
										</div>
								</Formik>											
							</div>
						</div>
					</div>
				</div>
		</>
        
    </>
    )
}

export default Login
