import { useEffect } from 'react';
import { getAllTargets } from '../../actions/adminActions';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../layouts/Loader';
import patientIcon from '../../assets/Images/network.png';
import {Link } from 'react-router-dom';
import moment from 'moment';
import NotFound from '../../components/NotFound';

const AllTargetedMinutes = () => {
    
    const dispatch = useDispatch();
    const {loading, error, alltargets } = useSelector(state => state.adminTargets);

    useEffect(() => {
        if(error){
        return alert.error(error);
        }

        dispatch(getAllTargets());

    }, [dispatch, error])



  return (
    <>
        {loading ? <Loader /> : ( <> 
        <div className="shadow-lg p-3 mb-2 rounded targetedMinutesCard">
            <div className="home-content">
                    <h5 style={{color: '#004aad'}}>
                        <i className='bx bxs-devices'></i> <strong>&nbsp;Recent Minutes Added </strong>
                    </h5>
                    <p className='m-0 p-0'><small>Recently added minutes by nurses in RPM & CCM category will be listed below.</small></p>

                <hr />
            </div>

            <div className='container'>
            {alltargets && alltargets.length > 0 ? <>
                
                {alltargets.map((trgt, index) => (    
                <div key={index}>
                    <div className='row-display'>
                        <img src={patientIcon} width="60px" height="60px" className="roundedBorderImg" />
                        <div className='ml-3'>
                            <strong>{trgt?.assigned_hr_id?.lastname +" , "+ trgt?.assigned_hr_id?.firstname}
                                &nbsp;<span style={{color: 'gray', fontWeight: '200'}}>on patient</span> &nbsp; 
                                <Link to={{ pathname: "/patientProfile", state: {patientid: trgt?.assigned_patient_id?._id}}} className="link"> {trgt?.assigned_patient_id?.lastname +" , "+ trgt?.assigned_patient_id?.firstname} </Link>
                                <span style={{color: 'gray', fontWeight: '200'}}>added</span>
                                &nbsp;&nbsp; <span>{trgt?.timeSpentInMinutes} minute(s) in</span>
                                &nbsp;{trgt?.isCCM === true ? 'CCM' : 'RPM'} &nbsp; category.
                                
                            </strong>
                            <div style={{float: 'right'}}>
                                <small className='text-muted'>{moment(trgt?.createdAt).tz("America/New_York").format("MM/DD/YYYY hh:mm A")}</small>
                                {/* <p className="pills">{trgt?.timeSpentInMinutes} minute(s) added</p> */}
                            </div>
                            <br /><br />

                            <p style={{textAlign: "justify"}}>{trgt?.conclusion}</p>
                            <hr/>
                        </div> 
                    </div>
                </div>
            ))}

            </> : <NotFound title="No recently minutes added." />}
            </div>
            </div>
            </> )}
    </>
  )
}

export default AllTargetedMinutes;