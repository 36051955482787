import React, {useState, useEffect} from 'react';
import { ProgressBar } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getPatientRPMTargetedMinutes } from '../../actions/HRActions';
import { getRemainingReadings} from '../../actions/adminActions';
import { RESET_TIME_REPORT_DATA } from '../../constants/HRConstants';


const RPMMinutesProgress = ({patientId}) => {

    const dispatch = useDispatch();

    const { rpmTotalMinutes } = useSelector(state => state.targetedMinutes);
    const { count } = useSelector(state => state.readingsCount);

    
    const [month, setMonth] = useState('09');
    const [year, setYear] = useState('2023');

    let startDate = `${year}-${month}-01`;
    let endDate = `${year}-${month}-31`;

    let strtDate = `${year}/${month}/01`;
    let edDate = `${year}/${month}/31`;

    useEffect(() =>{
       
        dispatch({ type: RESET_TIME_REPORT_DATA });

        dispatch(getPatientRPMTargetedMinutes(patientId, month, year));
        dispatch(getRemainingReadings(patientId, strtDate, edDate));

    }, [])
    
    const searchHandler = () => {

        let strtDate = `${year}/${month}/01`;   
        let edDate = `${year}/${month}/31`;

        dispatch(getPatientRPMTargetedMinutes(patientId, month, year)); 
        dispatch(getRemainingReadings(patientId, strtDate, edDate));

    }    

  
    return (
    <>

    {/* Select box for selecting month  */}
    <div className="row-display">
        <select name="month" 
            className="form-control" 
            placeholder="Select Month"
            value={month}
            onChange={(e) => setMonth(e.target.value)}
            >
            <option value="01">Jan</option>
            <option value="02">Feb</option>
            <option value="03">March</option>
            <option value="04">April</option>
            <option value="05">May</option>
            <option value="06">June</option>
            <option value="07">July</option>
            <option value="08">Aug</option>
            <option value="09">Sept</option>
            <option value="10">Oct</option>
            <option value="11">Nov</option>
            <option value="12">Dec</option>
        </select>
        &nbsp;
        <select 
            name="month" 
            className="form-control" 
            placeholder="Select Year"
            value={year}
            onChange={(e) => setYear(e.target.value)}
            >
            <option value="2022">2022</option>
            <option value="2023">2023</option>
            <option value="2024">2024</option>
            <option value="2025">2025</option>
            <option value="2026">2026</option>
        </select>
        &nbsp;
        <button type="submit" name="submit" className="edit-pill" onClick={() => searchHandler()}>
            <i className='bx bx-search'></i>
        </button>
    </div>

    <br />
            <small className="row-display"> 
                <b className="text-white">9 9 4 5 4  </b>
                <span style={{color: 'white', letterSpacing: '2px'}}> {count} / 16 days </span></small>
                <ProgressBar min="0" max="16" variant='success' label={(count / 16) * 100 + "%"} now={count} />

                    <br />

                    {rpmTotalMinutes >=0 && rpmTotalMinutes <= 20 ? <>
                        <small className="row-display"> 
                            <b className="text-white">9 9 4 5 7 : </b> 
                            <span style={{color: 'white', letterSpacing: '2px'}}>{rpmTotalMinutes} / 20 mins</span>
                        </small>
                        
                        <ProgressBar min="0" max="20" variant='success' label={(rpmTotalMinutes / 20) * 100 + "%"} now={rpmTotalMinutes} />
                    </> : <>
                    <small className="row-display text-white">
                        <b>99457 : </b> 
                        <span style={{letterSpacing: '2px'}}>20 / 20 mins </span></small>
                        <ProgressBar min="0" max="20" variant='success' label="100%" now="20" />
                    </>}
                     

                    <br />
                    {rpmTotalMinutes >=21 ? <>
                        <small className="row-display">
                            <b className="text-white">9 9 4 5 8 : </b> 
                            <span style={{color: 'white', letterSpacing: '2px'}}>{rpmTotalMinutes > 40 ? "40" : rpmTotalMinutes} / 40 mins </span></small>    
                        <ProgressBar min="21" max="40" variant='success' label={rpmTotalMinutes > 40 ? "100%" : (rpmTotalMinutes / 40) * 100 + "%"} now={rpmTotalMinutes} />
                    </> : <>
                    <small className="row-display">
                        <b className="text-white">9 9 4 5 8 : </b> 
                        <span style={{color: 'white', letterSpacing: '2px'}}>0 / 40 mins </span></small>
                        <ProgressBar min="21" max="40" variant='dangar' now="21" />
                    </>}

                    {/* if extra 20 minutes added */}
                    <br />
                    {rpmTotalMinutes >=41 ? <>
                        <small className="row-display">
                            <b className="text-white">9 9 4 5 8 : </b> 
                            <span style={{color: 'white', letterSpacing: '2px'}}>{rpmTotalMinutes > 60 ? "60" : rpmTotalMinutes} / 60 mins </span></small>    
                        <ProgressBar min="41" max="60" variant='success' label={rpmTotalMinutes > 60 ? "100%" : (rpmTotalMinutes / 60) * 100 + "%"} now={rpmTotalMinutes} />
                    </> : null}

                    {rpmTotalMinutes >=61 ? <>
                        <br />
                        <small className="row-display">
                            <b className="text-white">9 9 4 5 8 : </b> 
                            <span style={{color: 'white', letterSpacing: '2px'}}>{rpmTotalMinutes > 80 ? "80" : rpmTotalMinutes} / 80 mins </span></small>    
                        <ProgressBar min="61" max="80" variant='success' label={rpmTotalMinutes > 80 ? "100%" : (rpmTotalMinutes / 80) * 100 + "%"} now={rpmTotalMinutes} />
                    </> : null}

                    {rpmTotalMinutes >=81 ? <>
                        <br />
                        <small className="row-display">
                            <b className="text-white">9 9 4 5 8 : </b> 
                            <span style={{color: 'white', letterSpacing: '2px'}}>{rpmTotalMinutes > 100 ? "80" : rpmTotalMinutes} / 100 mins </span></small>    
                        <ProgressBar min="81" max="100" variant='success' label={rpmTotalMinutes > 100 ? "100%" : (rpmTotalMinutes / 100) * 100 + "%"} now={rpmTotalMinutes} />
                    </> : null}

                    {rpmTotalMinutes >=100 ? <>
                        <br />
                        <small className="row-display">
                            <b className="text-white">9 9 4 5 8 : </b> 
                            <span style={{color: 'white', letterSpacing: '2px'}}>{rpmTotalMinutes > 120 ? "100" : rpmTotalMinutes} / 120 mins </span></small>    
                        <ProgressBar min="100" max="120" variant='success' label={rpmTotalMinutes > 120 ? "120%" : (rpmTotalMinutes / 100) * 100 + "%"} now={rpmTotalMinutes} />
                    </> : null}
             
                    <p style={{marginTop: "14px", fontSize:"14px", color: '#FFF'}}>Total {rpmTotalMinutes || 0} Mins - {new Date().toLocaleString('en-us',{month:'short', year:'numeric'})}</p>
    </>
  )
}

export default RPMMinutesProgress