import {useState, useEffect} from 'react';
import TopBar from '../../components/AdminDashboard/TopBar';
import MetaData from '../../layouts/MetaData';
import TextField from '../../components/Form/TextField';
import Sidebar from '../../components/AdminDashboard/Sidebar';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useAlert } from 'react-alert';
import { updateHR, AddProfileImgNurse } from '../../actions/adminActions';
import { Link, useHistory } from 'react-router-dom';
import profilePic from "../../assets/Images/defaultUser.png";
import { Modal } from 'react-bootstrap';
import {HIDE_ALERT_MESSAGE} from '../../constants/Common';


const UpdateHR = (props) => {

    const dispatch = useDispatch();
    const alert = useAlert();
    const history = useHistory();

    const handleImageModal = () => setShowImageModal(true);

    const { error, isUpdated} = useSelector(state => state.hrslist);
    const {message, error: hrUpdateError } = useSelector(state => state.common);

    let hrInfo = props?.location?.state?.hr;

    const { _id, firstname, lastname, email, gender, DOB, phone1, mobileNo, consentRole, profileImg } = hrInfo;

    const [hrId, setHRId] = useState(_id);
    const [hrfirstName, setHRFirstName] = useState(firstname);
    const [hrlastName, setHRLastName] = useState(lastname);
    const [hrEmail, setHREmail] = useState(email);
    const [hrgender, setHRGender] = useState(gender);
    const [hrDOB, setHRDOB] = useState(DOB);
    const [hrphone1, setHRPhone1] = useState(phone1);
    const [hrMobileNo, setHRMobileNo] = useState(mobileNo);
    const [hrConsentRole, setHrConsentRole] = useState(consentRole);

    const [imageError, setImageError] = useState(true);
    const [image, setImage] = useState({ preview: '', data: '' })
    const [showImageModal, setShowImageModal] = useState(false);

    const handleCloseImageModal = () => setShowImageModal(false);


    useEffect(() =>{
        if(error){
            alert.error(error);
            
            dispatch({
                type: HIDE_ALERT_MESSAGE
            })
        }

        if(hrUpdateError) {
            alert.error(hrUpdateError);

            dispatch({
                type: HIDE_ALERT_MESSAGE
            })
        }


        if(message){
            alert.success(message);
            props?.history?.push('/hrlist');

            dispatch({
                type: HIDE_ALERT_MESSAGE
            })
        }

    }, [dispatch, error, message, hrUpdateError]);

    const validate = Yup.object().shape({
		firstname: Yup.string()
        .required('First Name is required')
        .min(2, 'Should be atleast 2 characters')
        .max(20, 'Should be less than 20 characters'),
		lastname: Yup.string() 
		  .min(2, 'Should be atleast 2 characters')
		  .max(20, 'Should be less than 20 characters')
		  .required('Last Name is Required'),
        email: Yup.string().email('Invalid email').required('Email is required'),  
        DOB:Yup.string(),
        mobileNo: Yup.string(),
        phone1: Yup.string(),
        gender: Yup.string().required('Gender is Required')
	  });

      const initialValues = {
        firstname: firstname, 
        lastname: lastname, 
        email: email, 
        DOB: '',
        gender: gender,
        phone1: phone1,
        mobileNo: mobileNo
      }

      const updateHandler = () => {
        dispatch(updateHR(hrId, hrfirstName, hrlastName, hrEmail, hrgender, hrDOB, hrphone1, hrMobileNo, hrConsentRole));
    }

    const handleFileChange = e => {
        e.preventDefault();
        
        if(e.target.files[0].length === 0){
            alert.error('No file selected');
            handleCloseImageModal(true);
        }

        if(e.target.files[0].size > 2e6){
            alert.error('Please upload image of less than 2 MB');
            handleCloseImageModal(true);
        };

        setImage(e.target.files[0]);
        setImageError(false);
      }

      const handleSubmit = () => {
        if(imageError === true) {
            alert.error('Select an Image to upload');
        }
        else {
            dispatch(AddProfileImgNurse(image, hrInfo?._id));
            setShowImageModal(false);
        }
    }

  return <>
       <MetaData title="Update Nurse"/>
                <Sidebar />    

            <section className="home-section">
                {/* TopBar */}
                <TopBar />

                <div className="shadow-lg p-3 mb-5 mr-4 ml-4 rounded">
                    <div className="home-content">
                    <div className="row-display">
                        <h5 style={{color: '#004aad'}}>
                            <i className='bx bx-edit'></i> <strong>&nbsp;Update Nurse Details </strong>
                        </h5>

                        <div>    
                            <Link to="/adminDashboard" className="btn btn-secondary btn-sm pt-2">
                                <i className='bx bx-home' ></i></Link> &nbsp;
                            <button onClick={() => history.goBack()} className="btn btn-secondary btn-sm pt-2"><i className='bx bx-arrow-back' ></i></button> &nbsp;
                        </div>
                    </div>

                    <hr />

                    <div className="row">
                        <div className="col-md-3 text-center mt-4">
                            
                        {profileImg ? <>
                            <img src={`https://vitalsportal.com/v1/uploadFiles/${profileImg}`} 
                                className="profile-img" alt="profileimg" 
                            />
                            </> :  <img src={profilePic} className="profile-img" alt="profileimg" /> 
                        }    
                            
                        <button className="edit-pill rounded-btn" onClick={handleImageModal}>
                            <i className="fas fa-camera"></i>
                        </button>
                        </div>

                        <div className="col-md-9">
                            <Formik initialValues={initialValues}
                                enableReinitialize={true}
                                validationSchema={validate}
                                onSubmit={updateHandler}
                                >
                            {/* { formik  => ( */}
                                <div>
                                    <Form>
                                        <div className="row">
                                            
                                            {/* First Name */}
                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                <TextField
                                                    type="text"
                                                    label="First Name" 
                                                    name="firstname"
                                                    className='form-control shadow-none'
                                                    placeholder="First Name"
                                                    value={hrfirstName}
                                                    onChange={(e) => setHRFirstName(e.target.value)} 
                                                />
                                            </div>


                                            {/* Last Name */}
                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                <TextField
                                                    label="Last Name"
                                                    type="text" 
                                                    name="lastname"
                                                    className='form-control shadow-none'
                                                    placeholder="Last Name"
                                                    value={hrlastName}
                                                    onChange={(e) => setHRLastName(e.target.value)} 
                                                />
                                            </div>

                                            {/* Email */}
                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                <TextField
                                                    label="Email"
                                                    type="text" 
                                                    name="email"
                                                    className='form-control shadow-none'
                                                    placeholder="Email Address"
                                                    value={hrEmail}
                                                    onChange={(e) => setHREmail(e.target.value)} 
                                                />
                                            </div>

                                            {/* Gender */}
                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                            <label htmlFor="gender" className="form-label mt-3">Gender</label>
                                                <select
                                                    label="Gender"
                                                    name="gender"
                                                    className="form-control"
                                                    defaultValue={hrgender}
                                                    onChange={(e) => setHRGender(e.target.value)}
                                                    >
                                                    <option value="male">Male</option>
                                                    <option value="female">Female</option>
                                                </select>
                                            </div>  

                                        {/* DOB */}
                                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <label htmlFor="DOB" className="form-label mt-3">DOB</label>
                                            <input 
                                                    label="DOB" 
                                                    name="DOB"
                                                    className="form-control" 
                                                    type="date"
                                                    onChange={(e) => setHRDOB(e.target.value)} 
                                            />
                                        </div>

                                        {/* Phone 1 */}
                                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                            <TextField 
                                                    label="Phone 1" 
                                                    name="phone1" 
                                                    type="text"
                                                    value={hrphone1}
                                                    onChange={(e) => setHRPhone1(e.target.value)} 
                                            />
                                        </div>

                                        {/* Mobile Number */}
                                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                            <TextField 
                                                    label="Mobile Number" 
                                                    name="mobileNo" 
                                                    type="text"
                                                    value={hrMobileNo}
                                                    onChange={(e) => setHRMobileNo(e.target.value)} 
                                            />
                                        </div>

                                        {/* consent Status */}
                                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <label htmlFor="consentStatus" className="form-label mt-3">Consent Role</label>
                                            <select 
                                                name="consentStatus"
                                                className="form-control" 
                                                value={hrConsentRole}
                                                onChange={e => setHrConsentRole(e.target.value)}
                                            >
                                                <option value="RPM">RPM</option>
                                                <option value="CCM">CCM</option>
                                                <option value="Both">RPM & CCM Both</option>
                                            </select>
                                        </div>
                                        </div>


                                        {/* Buttons */}
                                        <div className="row mr-3" style={{ float: 'right'}}>
                                                <button className="edit-pill ml-3" type="submit" >Update</button>
                                            </div>

                                            <br/><br/><br/>
                                    </Form>
                                </div>
                            {/* )} */}
                            </Formik>
                        </div>
                    </div>                        
                    </div>
                </div>

                {/* Image Modal */}
                <Modal show={showImageModal}>
                    <Modal.Header>
                        <Modal.Title>Update Profile Image</Modal.Title>
                    </Modal.Header>
                    
                    <Modal.Body>
                    <label htmlFor="name">Choose your Image: </label>
                        <input 
                            type="file" 
                            name="image" 
                            onChange={handleFileChange} 
                            accept="image/jpeg, image/png, image/jpg" 
                        />
                        <br />

                        {/*...*/}
                    </Modal.Body>
                    
                    <Modal.Footer>
                        <button className="edit-pill" style={{backgroundColor: 'red'}} onClick={handleCloseImageModal}>Cancel</button>
                        <button className="edit-pill" onClick={handleSubmit}>Update</button>
                    </Modal.Footer>
                </Modal>
            </section>
  </>;
};

export default UpdateHR;
